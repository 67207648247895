<ng-template [ngIf]="showSideBarColumnFilterView ">
  <div class="faa-sidenav-container" style="width:90%" [baseZIndex]="10000">
   
    <mat-sidenav-container class="faa-sidenav-body-container table-responsive"
                           [style.marginTop.px]="10">
      <mat-sidenav #sidenav [mode]="'over'" [position]="'end'" fixedTopGap="0">
      </mat-sidenav>

      <mat-sidenav-content class="faa-sidenav-content">
        <div [(visible)]="showSideBarColumnFilterView">
          <ng-template [ngIf]="true">
            <div class="container">
              <div class="row row-cols-3">
                <div class="col h2">Select Columns for Excel</div>
                <div class="col text-center">
                  <mat-checkbox [(ngModel)]="checkedAll" (change)="onSelectAll()"
                                class="mat-checkbox-inline"></mat-checkbox>&nbsp;
                  <label for="binary">Select All</label>
                  &nbsp;&nbsp;   &nbsp;&nbsp;
                  <button mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px"
                          (click)="onDownload()">
                    Download

                  </button>
                </div>
                <div class="col text-end">
                  <button mat-mini-fab color="primary" (click)="onHide()" matTooltip="Close window" aria-label="Close">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>

              </div>
            </div>

          </ng-template>

          <ag-grid-angular style="width: auto; height: 00px;" [hidden]="true"
                           id="ag-Grid-{{reportType}}"
                           class="ag-theme-alpine"
                           [rowData]="rowData"
                           [columnDefs]="columnDefs"
                           [gridOptions]="gridOptions"
                           (gridReady)="onGridReady($event)"
                           [pagination]="false">
          </ag-grid-angular>

          <div class="card">

            <div class="card-body">

              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>

                  <mat-panel-title>
                    <b>Narrative</b>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-row row">
                  <div *ngFor="let item of columnDefsNarrative" class="col-md-4">
                    <div class="form-check-faa" *ngIf="item.headerName !== ''">
                      <mat-checkbox [id]="item.headerName" value="item.headerName" [value]="item.headerName"
                                    (change)="onCheckBoxChange($event, item)" [checked]="filterColumns.includes(item.headerName)"
                                    inputId="item.headerName"
                                    class="mat-checkbox-inline">{{item.headerName}} </mat-checkbox>
                     
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <hr />
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <b>Aircraft</b>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-row row">
                  <div *ngFor="let item of columnDefsAircraft" class="col-md-4">
                    <div class="form-check-faa" *ngIf="item.headerName !== ''">
                      <mat-checkbox [id]="item.headerName" value="item.headerName" [value]="item.headerName"
                                    (change)="onCheckBoxChange($event, item)" [checked]="filterColumns.includes(item.headerName)"
                                    inputId="item.headerName"
                                    class="mat-checkbox-inline">{{item.headerName}} </mat-checkbox>
                     
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <hr />
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <b>Incident Grid</b>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-row row">
                  <div *ngFor="let item of columns" class="col-md-4">
                    <div class="form-check-faa" *ngIf="item.headerName !== ''">
                      <mat-checkbox [id]="item.headerName" value="item.headerName" [value]="item.headerName"
                                    (change)="onCheckBoxChange($event, item)" [checked]="filterColumns.includes(item.headerName)"
                                    inputId="item.headerName"
                                    class="mat-checkbox-inline"> {{item.headerName}}</mat-checkbox>
                     
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

            </div>
          
          </div>



        </div>

      </mat-sidenav-content>
     
    </mat-sidenav-container>
  </div>
</ng-template>
