import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, inject } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MergeReportService } from '../../../services/mergeReport.service';
import { ReportService } from '../../../services/report.service';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
    selector: 'app-sidebar-merge-view',
    templateUrl: './merge.component.html',
    styleUrls: ['./merge.component.css'],
    standalone: false
})
export class MergeComponent implements OnInit {
  @Input() type = '';
  @Input() reportType = '';
  @Input() originalReportNo = '';
  @Input() duplicateReportNo = '';

  @Input() showMergedSideBarView = false;
  @Output() onCloseSideBarMergeView = new EventEmitter();
  private _snackBar = inject(MatSnackBar);

  title = 'Merge a Report';
  message = '';
  result = {};
  originalReport = {};
  hasNotValidReport = true;
  reportIdTo = '';
  reportIdFrom = '';
  duplicateReport = {};
  showIncidentView = false;
  hasDataLoadInProgess = true;
  alertType = 'danger';
  constructor(public mergeReportService: MergeReportService, private activatedRoute: ActivatedRoute, 
    public reportService: ReportService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.reportType = this.activatedRoute.snapshot.queryParams.type;
    if (this.reportType == 'MERGE_MANUAL')
      this.showMergedSideBarView = true;

    if (this.showMergedSideBarView) {
      this.loadData();
    }
  }
  ngOnChanges(changes: SimpleChanges) {

    if (this.showMergedSideBarView) {
      this.loadData();
    }
  }
 
  onMerge(hasMerge: boolean) {
    this.message = '';
    this.spinnerService.show();

    this.mergeReportService.MergeOrSetNotDuplicateReport(this.originalReportNo, this.duplicateReportNo, hasMerge)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;
          if (_result.success) {
            this.alertType = 'success';
            this.message = 'Report ' + (hasMerge ? 'Merged ' : ' Marked as Not Duplicate') + ' Successfully!';
            this.showStatusMessage(this.message, 'Merge Or Not Duplicate', 'success');
            this.onHide();
          }
          else {
            this.alertType = 'danger';
            this.message = 'Error while processing the request. ' + _result.errormessage;
            this.showStatusMessage(this.message, 'Merge Or Not Duplicate', 'error');
          }


        },

        error: (err: any) => {
          
          this.showIncidentView = false;
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while processing the request.' + _err.errormessage;
          this.showStatusMessage(this.message, 'Merge Or NotDuplicate', 'error');
          this.spinnerService.hide();
        },
        complete: () => { }
      }
      );


  }
  loadData() {
    this.message = '';
    this.reportIdTo = '';
    this.reportIdFrom = '';
    this.showIncidentView = false;
    this.hasNotValidReport = true;
    this.hasDataLoadInProgess = true;
    this.result = {};
    this.spinnerService.show();

    this.mergeReportService.GetReportDataForMergeById(this.originalReportNo, this.duplicateReportNo)
      .subscribe({
        next: (result) => {

          let _result: any = {};
          _result = result;
          this.result = _result;

          this.alertType = 'success';
          this.originalReport = _result.report1.result[0];
          this.duplicateReport = _result.report2.result[0];
          this.reportIdTo = _result.report1.result[0]?.id;
          this.reportIdFrom = _result.report2.result[0]?.id;

          this.message = _result.total > 0 ? '' : 'No data found.';
          setTimeout(() => {
            this.hasDataLoadInProgess = false;
            this.showIncidentView = true;
            if (this.originalReport && this.duplicateReport)
              this.hasNotValidReport = Object.entries(this.originalReport).length == 0 && Object.entries(this.duplicateReport).length == 0;
            this.spinnerService.hide();
          }, 150);

        },

        error: (err: any) => {
          this.showIncidentView = false;
          this.hasDataLoadInProgess = false;
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while loading data. ' + _err.errormessage;
          this.showStatusMessage(this.message, 'Get Report Data For Merge By Id', 'error');
          this.spinnerService.hide();
        },
        complete: () => { }
      }
      );

  }

  loadReportData(reportNumber, isToReport = true) {
    this.message = '';
   
    this.result = {};
    this.spinnerService.show();
    this.hasNotValidReport = true;
    this.reportService.getIncidentReportById(reportNumber)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          this.showIncidentView = true;
         
          this.alertType = 'success';
          if (isToReport) {
            this.originalReportNo = _result.result[0].merged_data_source_id;
            this.originalReport = _result.result[0];
          }
          else {
            this.duplicateReportNo = _result.result[0].merged_data_source_id;
            this.duplicateReport = _result.result[0];
          }
          if (this.originalReport && this.duplicateReport)
            this.hasNotValidReport = Object.entries(this.originalReport).length == 0 && Object.entries(this.duplicateReport).length == 0;

        },

        error: (err: any) => {
          if (isToReport)
            this.originalReport = [];
          else
            this.duplicateReport = [];
          this.showIncidentView = false;
          this.hasDataLoadInProgess = false;
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while loading data. ' + _err.errormessage;
          this.showStatusMessage(this.message, 'Get Report Data For Merge By Id', 'error');
          this.spinnerService.hide();
        },
        complete: () => { }
      }
      );


  }
  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity = severity == '' || severity == 'success' ? 'success' : 'error'
    this._snackBar.open(message, severity, {
      panelClass: 'app-notification-' + severity
    });
  }
  onHide() {
    this.showMergedSideBarView = false;
    this.reportIdTo = '';
    this.reportIdFrom = '';
    let vm = { status: 'CLOSE', data: null };
    this.onCloseSideBarMergeView.emit(false);


    this.originalReport = [];
    this.duplicateReport = [];
    this.hasNotValidReport = true;
    
  }

}
