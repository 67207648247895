<ng-template [ngIf]="showMergedSideBarView && reportType !== 'MERGE_MANUAL'" >
  <div class="faa-sidenav-container" [style]="{ width: (reportType !== 'MERGE_MANUAL') ? '90%' : '100%'}" [baseZIndex]="10000">

    <mat-sidenav-container class="faa-sidenav-body-container table-responsive"
                           [style.marginTop.px]="10">
      <mat-sidenav #sidenav [mode]="'over'" [position]="'end'" fixedTopGap="0">
      </mat-sidenav>

      <mat-sidenav-content class="faa-sidenav-content">
        <div *ngIf="reportType !== 'MERGE_MANUAL'" [(visible)]="showMergedSideBarView">
          <ng-template [ngIf]="true">
            <div class="container">
              <div class="row row-cols-2">
                <span class="col h2" *ngIf="showMergedSideBarView">Merge a Report </span>

                <div class="col text-right text-end">
                  <button mat-mini-fab color="primary" (click)="onHide()" matTooltip="Close window" aria-label="Close">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>

              </div>
            </div>

          </ng-template>
          <div class="row" [hidden]="hasDataLoadInProgess">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="fill" class="col-md-10">
                    <mat-label>
                      Incident ID(To)
                    </mat-label>
                    <input matInput [(ngModel)]="reportIdTo" name="reportIdTo" />
                  </mat-form-field>

                  <button mat-raised-button class="btn-primary" (click)="loadReportData(reportIdTo, true)">Go</button>&nbsp;&nbsp;

                </div>

              </div>
              <app-incident-view [reportId]="originalReportNo" [selectedRow]="originalReport" [showIncidentView]="showIncidentView"></app-incident-view>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="fill" class="col-md-10">
                    <mat-label>
                      Incident ID(From)
                    </mat-label>
                    <input matInput [(ngModel)]="reportIdFrom" name="reportIdFrom" />
                  </mat-form-field>

                  <button mat-raised-button class="btn-primary" (click)="loadReportData(reportIdFrom, false)">Go</button>&nbsp;&nbsp;

                </div>

              </div>
              <app-incident-view [reportId]="duplicateReportNo" [selectedRow]="duplicateReport" [showIncidentView]="showIncidentView"></app-incident-view>

            </div>
          </div>

          <div class="row" [hidden]="hasDataLoadInProgess || hasNotValidReport">
            <div class="col">
              <button class="w-100" mat-raised-button color="primary" (click)="onMerge(true)">Merge</button>&nbsp;
            </div>
            <div class="col">
              <button class="w-100" mat-raised-button color="warn" (click)="onMerge(false)">Not a Duplicate</button>&nbsp;
            </div>
          </div>

        </div>

      </mat-sidenav-content>

    </mat-sidenav-container>
  </div>
</ng-template>

<ng-template [ngIf]="reportType == 'MERGE_MANUAL'">
  <div class="container-fluid">

    <mat-card class="asd-card">

      <mat-card-header>

        <mat-card-title>
          <h1>{{title}}</h1>
        </mat-card-title>
        <hr>
      </mat-card-header>

      <mat-card-content>

        <div class="row" [hidden]="hasDataLoadInProgess">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field appearance="fill" class="col-md-10">
                  <mat-label>
                    Incident ID(To)
                  </mat-label>
                  <input matInput [(ngModel)]="reportIdTo" name="reportIdTo" />
                </mat-form-field>

                <button mat-raised-button class="btn-primary" (click)="loadReportData(reportIdTo, true)">Go</button>&nbsp;&nbsp;

              </div>

            </div>
            <app-incident-view [reportId]="originalReportNo" [selectedRow]="originalReport" [showIncidentView]="showIncidentView"></app-incident-view>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field appearance="fill" class="col-md-10">
                  <mat-label>
                    Incident ID(From)
                  </mat-label>
                  <input matInput [(ngModel)]="reportIdFrom" name="reportIdFrom" />
                </mat-form-field>

                <button mat-raised-button class="btn-primary" (click)="loadReportData(reportIdFrom, false)">Go</button>&nbsp;&nbsp;

              </div>

            </div>
            <app-incident-view [reportId]="duplicateReportNo" [selectedRow]="duplicateReport" [showIncidentView]="showIncidentView"></app-incident-view>

          </div>
        </div>

        <div class="row" [hidden]="hasDataLoadInProgess || hasNotValidReport">
          <div class="col">
            <button class="w-100" mat-raised-button color="primary" (click)="onMerge(true)">Merge</button>&nbsp;
          </div>
          <div class="col">
            <button class="w-100" mat-raised-button color="warn" (click)="onMerge(false)">Not a Duplicate</button>&nbsp;
          </div>
        </div>


      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
