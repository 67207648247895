import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QueryService } from '../services/query.service';
import { SpinnerService } from '../services/spinner.service';
import { inject } from '@angular/core';

@Component({
    selector: 'app-manage-query-dialog',
    templateUrl: './manage-query-dialog.component.html',
    styleUrls: ['./manage-query-dialog.component.css'],
    standalone: false
})
export class ManageQueryDialogComponent implements OnInit {
  private _snackBar = inject(MatSnackBar);
  queryForm: UntypedFormGroup;
  queries: any = {};
  alertType = 'success';
  message = '';
  action = 'Add ';
  constructor(public queryService: QueryService, public dialogRef: MatDialogRef<ManageQueryDialogComponent>,
    private spinnerService: SpinnerService, @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.action = this.data?.query?.id > 0 ? (this.data?.action === 'SAVE_AS' ? 'Save As' : (this.data?.action === 'RENAME' ? 'Rename' : 'Update')) : 'Add';
    if (this.action == 'Add' && this.data && this.data.query && this.data.query.id)
      this.data.query.id = 0;
    this.queryForm = this.fb.group({
      id: 0,
      name: ['', Validators.required],
      description: '',
      uiQuery: JSON.stringify(this.data?.uiquery),
      isPublic: false,
      type: this.data?.reportType,
      createdBy: this.data?.userName

    });

    if (this.data?.query?.id > 0) {
      //set form data
      this.queryForm = this.fb.group({
        id: this.data.query.id,
        currentName: [this.data?.query?.name],
        name: [this.data?.query?.name, Validators.required],
        description: this.data?.query?.description,
        uiQuery: this.data?.uiquery ? JSON.stringify(this.data?.uiquery) : JSON.stringify(this.data?.query?.uiQuery),
        oracleQuery: this.data?.query?.oracleQuery,
        sqlQuery: this.data?.query?.sqlQuery,
        isPublic: this.data?.query?.isPublic,
        type: this.data?.reportType,
        createdBy: this.data?.query?.createdBy
      });
      if (this.data?.action === 'SAVE_AS') {
        this.queryForm.controls.id.setValue(0);
        this.queryForm.controls.createdBy.setValue(this.data?.userName);
      }
     
    }
  }
  isFormValid(): boolean {
   
      return this.queryForm.valid && ((this.data?.action === 'SAVE_AS' || this.data?.action === 'RENAME') ? this.queryForm.get('name').value !==  this.queryForm.get('currentName').value : true) ;
  }
 
  onAddUpdateQuery() {
    this.queries = {};
    this.spinnerService.show();
    let queryResponeSubscribe;
    if (this.queryForm.controls.id.value > 0) {
      queryResponeSubscribe = this.queryService.update(this.queryForm.value);
    }
    else {
      queryResponeSubscribe = this.queryService.add(this.queryForm.value);
    }
    queryResponeSubscribe
      .subscribe(result => {
        this.spinnerService.hide();
        let _result: any = {};
        _result = result;

        if (_result.success) {
          let newID = _result.result;
         
          this.message = 'Query Updated successfully.';
          this.showStatusMessage(this.message, 'Update Query', 'success');
            setTimeout(() => {
              this.dialogRef.close(newID);
            }, 0);
        }
        else {

          this.alertType = 'danger';
         
          let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
          this.message = 'Error while processing request. ' + _errorInfo;
          this.showStatusMessage(this.message, 'Update Query', 'error');
        }

      },
        err => {

          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          if (_err && _err?.errorMessage)
            _errorInfo = _err?.errorMessage;
          this.message = 'Error while processing request. ' + _errorInfo;
          this.showStatusMessage(this.message, 'Update Query', 'error');
          this.spinnerService.hide();
        });



  }

  onDeleteQuery() {
    this.queries = {};
    this.spinnerService.show();
    let queryResponeSubscribe;
   
    if (this.queryForm.controls.id.value > 0) {
      queryResponeSubscribe = this.queryService.delete(this.queryForm.controls.id.value, this.data?.query?.createdBy, this.data?.reportType);

      queryResponeSubscribe
        .subscribe(result => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            let newID = _result.result;

            this.message = 'Query Deleted successfully.';
            this.showStatusMessage(this.message, 'Delete Query', 'success');
            setTimeout(() => {
              this.dialogRef.close(0);
            }, 0);
          }
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Error while processing request. ' + _errorInfo;
            this.showStatusMessage(this.message, 'Delete Query', 'error');
          }

        },
          err => {

            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            if (_err && _err?.errorMessage)
              _errorInfo = _err?.errorMessage;
            this.message = 'Error while processing request. ' + _errorInfo;
            this.showStatusMessage(this.message, 'Delete Query', 'error');
            this.spinnerService.hide();
          });
    }
  }

  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity = severity == '' || severity == 'success' ? 'success' : 'error'
    this._snackBar.open(message, severity, {
      panelClass: 'app-notification-' + severity
    });
  }
}
