import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
//import { Field, FieldMap } from 'angular2-query-builder';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { forEachChild } from 'typescript';
import { Field, FieldMap } from '../../../model/shared';
import { IncidentService } from '../../../services/incident.service';
import { ReportService } from '../../../services/report.service';
import { SharedService } from '../../../services/shared.service';
import { SpinnerService } from '../../../services/spinner.service';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-edit-view',
    templateUrl: './edit-view.component.html',
    styleUrls: ['./edit-view.component.css'],
    standalone: false
})
export class EditViewComponent implements OnInit {
  private _snackBar = inject(MatSnackBar);
  editableColumns = [];
  muliSelectColumn = [];
  @Input() showSideBarEditView = false;
  @Input() showSideBarDetailView = false;
  @Input() hasQueryGridData;
  @Input() rowDataDetailView;
  @Input() selectedRowIndex;
  @Input() columnDefs;
  @Input() defaultColDef;
  @Input() gridApiDetail;
  @Input() resultReportColumns;
  @Input() queryColumns;
  @Input() airports;
  @Input() userName;
  @Input() sqlQuery;
  @Input() gridApi
  @Input() reportId;
  @Input() selectedRow;
  @Input() type = '';
  @Input() totalRecords;
  @Output() onCloseSideBarDetailView = new EventEmitter();

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('scrollToTop') scrollToTop: ElementRef;
  incidentReportForm: UntypedFormGroup;
  message: string = '';
  alertType: string = 'success';
 
  isReadOnly: boolean = false;
  allExpanded: boolean = true;
  runways: any = {};
  filteredRunways: Observable<any[]>;
  hasLoadedInicidentSearchLookupDataForSideBar = false;
  timeOfDay: any = {};
  filteredTimeOfDay: any = {};
  localTimes: any = {};
  filteredLocalTimes: any = {};
  has_new_accident_or_incident_from_rws_fk: boolean = false;

 // airports: any = {};
  filteredAirports: any = {};
  hasLoadedAirports: boolean = false;

  regions: any = {};
  filteredRegions: any = {};
  hasLoadedRegions: boolean = false;

  airportUse: any = {};
  filteredAirportUse: any = {};
  airportOwnership: any = {};
  filteredAirportOwnership: any = {};

  gaAssetRoles: any = {};
  filteredGaAssetRoles: any = {};

  _14CFRPart139: any = {};
  filtered_14CFRPart139: any = {};
  toweredAirport: any = {};
  filteredToweredAirport: any = {};
  cORE30: any = {};
  filteredCORE30: any = {};
  oEP35: any = {};
  filteredOEP35: any = {};

  nPIASHubSize: any = {};
  filteredNPIASHubSize: any = {};
  nPIASServiceLevel: any = {};
  filteredNPIASServiceLevel: any = {};


  locations: any = {};
  filteredLocations: any = {};
  weathers: any = {};
  filteredWeathers: any = {};

  precipitations: any = {};
  filteredPrecipitations: any = {};
  surfaceConditions: any = {};
  filteredSurfaceConditions: any = {};


  accidentOrIncident: any = {};
  filteredAccidentOrIncident: any = {};
  hotSpots: any = {};
  filteredHotSpots: any = {};
  occurrenceItems: any = {};
  filteredOccurrenceItems: any = {};
  operationTypes: any = {};
  filteredOperationTypes: any = {};

  flightPhases: any = {};
  filteredFlightPhases: any = {};

  specificInjuries: any = {};
  filteredSpecificInjuries: any = {};

  yesOptionOnly: any = {};
  //anyInjuries = ['YES'];
  //anyAircraftDamages = ['YES'];
  //damageToProperty = ['YES'];
  //unspecifiedTypeAircraftDamage = ['YES'];
  nTSB_AIDSDamageToAircraft: any = {};
  filteredNTSB_AIDSDamageToAircraft: any = {};

  dataSources: any = {};
  filteredDataSources: any = {};
  runwayIncursionCategory: any = {};
  filteredRunwayIncursionCategory: any = {};
  nonStandardGeometry: any = {};
  filteredNonStandardGeometry: any = {};
  construction: any = {};
  signage: any = {};
  marking: any = {};
  lighting: any = {};

  proceduresOrOperations: any = {};
  communication: any = {};
  obstructionsNearRunway: any = {};
  mechanicalFailure: any = {};

  lossOfAircraftControl: any = {};
  weather: any = {};
  lackOfOverrunAre: any = {};
  failureToMaintainClearance: any = {};
  surfaceConditionsCF: any = {};
  other: any = {};

  incidentEditStage: any = {};
  filteredIncidentEditStage: any = {};
  expanded = true;
  currentTime = new Date().getTime();

  hasReset: boolean = false;
  hasRunwayInLocation: boolean = false;
  contributingFactors : any = {};
  incidentSearchQuery = {
    dateTime: [], airport: [],
    occurrences: [], location: [],
    outcome: [], opsFlightPhase: [],
    miscInfomration: [],
    total: 0

  };
  first: number = 0;
  subReportType: string = '';
  result: any = {};
  id: string = '';
  showSideBarSubReportView: boolean = false;
  hasSeparateTabForNarrative: boolean = false;
  analyst_editing_stage: string = '';
  airportDiagramDefaultDirectory: string = ''; //default dir for diagrams to get always latest year
  constructor(private http: HttpClient, private fb: UntypedFormBuilder, 
    public incidentLookupService: IncidentService,
    public sharedService: SharedService,
    private spinnerService: SpinnerService, private reportService: ReportService, private el: ElementRef) { }

  ngOnInit(): void {
    this.filteredRunways = this.incidentReportForm?.controls['incident_runway']?.valueChanges.pipe(
      startWith(''),
      map(value => {
        const runway = typeof value === 'string' ? value : value?.runway;
        return runway ? this._filter(runway as string) : this.runways.slice();
      }),
    );
   
   
    this.http.get('../../assets/appData.json?v=' + this.currentTime)
      .subscribe({
        next: (result) => {
        
            let _result: any = {};
          _result = result;
            if (_result.hasSeparateTabForNarrative)
              this.hasSeparateTabForNarrative = _result.hasSeparateTabForNarrative;
            if (_result.staticData.editableColumns)
              this.editableColumns = _result.staticData.editableColumns;
            if (_result.staticData.muliSelectColumn)
              this.muliSelectColumn = _result.staticData.muliSelectColumn;
            if (_result.staticData.timeOfDay) {
              this.timeOfDay = _result.staticData.timeOfDay;
              this.filteredTimeOfDay = _result.staticData.timeOfDay;
            }
            if (_result.staticData.localTimes) {
              this.localTimes = _result.staticData.localTimes;
              this.filteredLocalTimes = _result.staticData.localTimes;
            }
            if (_result.staticData.airportUse) {
              this.airportUse = _result.staticData.airportUse;
              this.filteredAirportUse = _result.staticData.airportUse;
            }
            if (_result.staticData.gaAssetRoles) {
              this.gaAssetRoles = _result.staticData.gaAssetRoles;
              this.filteredGaAssetRoles = _result.staticData.gaAssetRoles;
            }

            if (_result.staticData.airportOwnership) {
              this.airportOwnership = _result.staticData.airportOwnership;
              this.filteredAirportOwnership = _result.staticData.airportOwnership;
            }

            if (_result.staticData._14CFRPart139) {
              this._14CFRPart139 = _result.staticData._14CFRPart139;
              this.filtered_14CFRPart139 = _result.staticData._14CFRPart139;
            }

            if (_result.staticData.toweredAirport) {
              this.toweredAirport = _result.staticData.toweredAirport;
              this.filteredToweredAirport = _result.staticData.toweredAirport;
            }

            if (_result.staticData.cORE30) {
              this.cORE30 = _result.staticData.cORE30;
              this.filteredCORE30 = _result.staticData.cORE30;
            }
            if (_result.staticData.oEP35) {
              this.oEP35 = _result.staticData.oEP35;
              this.filteredOEP35 = _result.staticData.oEP35;
            }
            if (_result.staticData.nPIASHubSize) {
              this.nPIASHubSize = _result.staticData.nPIASHubSize;
              this.filteredNPIASHubSize = _result.staticData.nPIASHubSize;
            }
            if (_result.staticData.nPIASServiceLevel) {
              this.nPIASServiceLevel = _result.staticData.nPIASServiceLevel;
              this.filteredNPIASServiceLevel = _result.staticData.nPIASServiceLevel;
            }
            if (_result.staticData.weathers) {
              this.weathers = _result.staticData.weathers;
              this.filteredWeathers = _result.staticData.weathers;
            }
            if (_result.staticData.accidentOrIncident) {
              this.accidentOrIncident = _result.staticData.accidentOrIncident;
              this.filteredAccidentOrIncident = _result.staticData.accidentOrIncident;
            }
            if (_result.staticData.hotSpots) {
              this.hotSpots = _result.staticData.hotSpots;
              this.filteredHotSpots = _result.staticData.hotSpots;
            }

            if (_result.staticData.nTSB_AIDSDamageToAircraft) {
              this.nTSB_AIDSDamageToAircraft = _result.staticData.nTSB_AIDSDamageToAircraft;
              this.filteredNTSB_AIDSDamageToAircraft = _result.staticData.nTSB_AIDSDamageToAircraft;
            }

            if (_result.staticData.dataSources) {
              this.dataSources = _result.staticData.dataSources;
              this.filteredDataSources = _result.staticData.dataSources;
            }

            if (_result.staticData.nonStandardGeometry) {
              this.nonStandardGeometry = _result.staticData.nonStandardGeometry;
              this.filteredNonStandardGeometry = _result.staticData.nonStandardGeometry;
            }
            if (_result.staticData.runwayIncursionCategory) {
              this.runwayIncursionCategory = _result.staticData.runwayIncursionCategory;
              this.filteredRunwayIncursionCategory = _result.staticData.runwayIncursionCategory;
            }
            if (_result.staticData.yesOptionOnly) {
              this.yesOptionOnly = _result.staticData.yesOptionOnly;
            }

            if (_result.staticData.construction) {
              this.construction = _result.staticData.construction;
            }
            if (_result.staticData.signage) {
              this.signage = _result.staticData.signage;
            }
            if (_result.staticData.marking) {
              this.marking = _result.staticData.marking;
            }

            if (_result.staticData.lighting) {
              this.lighting = _result.staticData.lighting;
            }
            if (_result.staticData.proceduresOrOperations) {
              this.proceduresOrOperations = _result.staticData.proceduresOrOperations;
            }

            if (_result.staticData.communication) {
              this.communication = _result.staticData.communication;
            }
            if (_result.staticData.obstructionsNearRunway) {
              this.obstructionsNearRunway = _result.staticData.obstructionsNearRunway;
            }
            if (_result.staticData.mechanicalFailure) {
              this.mechanicalFailure = _result.staticData.mechanicalFailure;
            }
            if (_result.staticData.lossOfAircraftControl) {
              this.lossOfAircraftControl = _result.staticData.lossOfAircraftControl;
            }

            if (_result.staticData.weather) {
              this.weather = _result.staticData.weather;
            }
            if (_result.staticData.lackOfOverrunAre) {
              this.lackOfOverrunAre = _result.staticData.lackOfOverrunAre;
            }
            if (_result.staticData.failureToMaintainClearance) {
              this.failureToMaintainClearance = _result.staticData.failureToMaintainClearance;
            }
            if (_result.staticData.surfaceConditionsCF) {
              this.surfaceConditionsCF = _result.staticData.surfaceConditionsCF;
            }
            if (_result.staticData.other) {
              this.other = _result.staticData.other;
            }

            if (_result.staticData.incidentEditStage) {
              this.incidentEditStage = _result.staticData.incidentEditStage;
              this.filteredIncidentEditStage = _result.staticData.incidentEditStage;
            }
            if (_result.staticData.locations) {
              this.locations = _result.staticData.locations;
              this.filteredLocations = _result.staticData.locations;
            }
            if (_result.staticData.flightPhases) {
              this.flightPhases = _result.staticData.flightPhases;
              this.filteredFlightPhases = _result.staticData.flightPhases;
            }
            if (_result.staticData.specificInjuries) {
              this.specificInjuries = _result.staticData.specificInjuries;
              this.filteredSpecificInjuries = _result.staticData.specificInjuries;
            }
            if (_result.staticData.operationTypes) {
              this.operationTypes = _result.staticData.operationTypes;
              this.filteredOperationTypes = _result.staticData.operationTypes;
            }
            if (_result.staticData.occurrenceItems) {
              this.occurrenceItems = _result.staticData.occurrenceItems;
              this.filteredOccurrenceItems = _result.staticData.occurrenceItems;
            }

            if (_result.staticData.precipitations) {
              this.precipitations = _result.staticData.precipitations;
              this.filteredPrecipitations = _result.staticData.precipitations;
            }
            if (_result.staticData.surfaceConditions) {
              this.surfaceConditions = _result.staticData.surfaceConditions;
              this.filteredSurfaceConditions = _result.staticData.surfaceConditions;
            }
            if (_result.staticData.surfaceConditions)
              this.contributingFactors = _result.staticData.contributingFactors;
            if (_result.airportDiagramDefaultDirectory)
            this.airportDiagramDefaultDirectory = _result.airportDiagramDefaultDirectory;

            this.hasLoadedInicidentSearchLookupDataForSideBar = true;
            this.onInit();
          
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          console.log(err);
          this.onInit();
        },
        complete: () => { }
      }
      );

    
  }
  displayFn(result: any): string {
    return result && result.runway ? result.runway : '';
  }
  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.runways.filter(option => option.runway.toLowerCase().includes(filterValue));
  }
  onInit() {
    this.result= {};
    this.id = '';
    this.message = '';
    this.subReportType = '';
    this.showSideBarSubReportView = false;
    this.hasRunwayInLocation = true;
   
    this.hasReset = false;
    this.expanded = true;
   
    if (this.showSideBarEditView && this.selectedRowIndex > -1) {
      this.hasLoadedAirports = true;
      const rowNode = this.gridApi.getDisplayedRowAtIndex(this.selectedRowIndex);
      this.first = this.selectedRowIndex ;
      this.selectedRow = rowNode.data;
      this.setSearchQueryParameterColumns();
      this.setFormData();
      if (this.type === 'INCIDENT')
        this.loadData();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.onInit();
  }
  onReset() {
    this.hasReset = true;
    this.onInit();
  }
  onDetailViewGridReady(params) {
 
    this.gridApiDetail = params.api;
  }
  resetSummaryColumnCheckboxesData() {
    this.locations = this.locations.map(item => ({ ...item }));
    this.precipitations = this.precipitations.map(item => ({ ...item }));
    this.surfaceConditions = this.surfaceConditions.map(item => ({ ...item }));
    this.occurrenceItems = this.occurrenceItems.map(item => ({ ...item }));
    this.operationTypes = this.operationTypes.map(item => ({ ...item }));
    this.flightPhases = this.flightPhases.map(item => ({ ...item }));
    this.contributingFactors = this.contributingFactors.map(item => ({ ...item }));
  
  }
  loadData() {
    if (this.type && this.type.length > 0) {
       let type = '';
      let sqlQuery = '';
      switch (this.type) {
       
        case 'INCIDENT':
          type = 'INCIDENT_DETAIL';
          this.resetSummaryColumnCheckboxesData();
          this.id = this.selectedRow ? this.selectedRow['merged_data_source_id'] : '';
          sqlQuery = `( merged_data_source_id  = '${this.id}' )`;
          break;
      }



      this.rowDataDetailView = new Observable<any[]>();
      this.result = {};
      this.spinnerService.show();
      this.reportService.getReportData(type, sqlQuery, null,null, this.id)
        .subscribe({
          next: (result) => {
            let _result: any = {};
            _result = result;
            this.result = _result;
            this.rowDataDetailView = _result.result;
            this.spinnerService.hide();
            if (_result.success) {
              setTimeout(() => {
                this.selectedRow = _result.result[0];
                this.setSearchQueryParameterColumns();
                this.setFormData();
                this.loadRunways();
              }, 0);
            }
          },

          error: (err: any) => {
            this.spinnerService.hide();
            let _err: any = {};
            _err = err;

            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';

          },
          complete: () => { this.spinnerService.hide(); }
        }
        );

     
    }
  }
  onHide(hasSave = false) {
    this.showSideBarEditView = false;
    this.showSideBarDetailView = false;
    this.reportId = '';
    this.selectedRowIndex = -1;
    this.selectedRow = {};
    this.first = 0;
    this.onCloseSideBarDetailView.emit(hasSave);

    setTimeout(() => {
      if (this.accordion) {
        this.accordion.closeAll();
        setTimeout(() => {
          this.expanded = false;
        }, 0);
      }
    }, 0);
   
  }
  onPageChange(event: any) {
   
    if (this.gridApiDetail && this.rowDataDetailView && this.showSideBarEditView) {
      this.first = event.pageIndex;
      this.selectedRowIndex = (event.pageIndex);
      this.setPageData();
    }
  }
  setPageData() {
   
    if (this.gridApiDetail && this.rowDataDetailView && this.showSideBarEditView) {
      this.isReadOnly = false;
      const rowNode = this.gridApi.getDisplayedRowAtIndex(this.selectedRowIndex);
     /* console.log('getDisplayedRowAtIndex(0) => ' + rowNode.data.id);*/
      this.selectedRow = rowNode.data;
      this.setFormData();
      this.isReadOnly = true;
      if (this.type === 'INCIDENT')
        this.loadData();
     
    }
  }
  setSearchQueryParameterColumns() {
    //console.log('setSearchQueryParameterColumns');
    let objList: Field[] = [];
  
    const group: any = {};
    group['start'] = (new UntypedFormControl());
    group['end'] = (new UntypedFormControl());
    group['__incident_runway'] = (new UntypedFormControl());
    
    Object.entries(this.muliSelectColumn).forEach(([key, val]) => {
   //   console.log(val);
      group[val] = (new UntypedFormControl());
    });
    
    Object.entries(this.queryColumns).forEach(
      ([key, item]) => {

        let x = {
          name: item['db_column_name'],
          type: item['data_type'] === 'DATE' ? 'date' : (item['data_type'] === 'NUMBER' ? 'number' : 'string'),
          operators:
            item['data_type'] === 'DATE' ? ['=', '!=', '>=', '<=', '>', '<'] : (item['data_type'] === 'NUMBER' ? ['=', '!=', '>=', '<=', '>', '<'] : (item['control'] === 'SELECT' ? ['=', '!=', 'in'] : ['=', '!=', 'like'])),
          filter: true,
          sortable: true
        }
        if (objList.indexOf(x) === -1) {
            objList.push(x);
            group[item['db_column_name']] = (new UntypedFormControl());
         
        }


      }
    );

    const fieldMap: FieldMap = arrayToObject(objList)
   
    //set incident formgroup
    this.incidentReportForm = this.fb.group(group);
    //set from values
    this.setFormData();
  }
  setFormData() {
    this.has_new_accident_or_incident_from_rws_fk = false;
    //this.incidentReportForm.reset();
    this.analyst_editing_stage = '';
    if (this.selectedRow) {
      this.isReadOnly = false;
      this.incidentReportForm.enable();
      if (this.selectedRow['analyst_editing_stage'])
          this.analyst_editing_stage = this.selectedRow['analyst_editing_stage'];

      Object.entries(this.queryColumns).forEach(
        ([key, item]) => {
          let name = item['db_column_name'];

          let control = item['control'];

          if (name && this.incidentReportForm && this.incidentReportForm.controls[name]) {
            this.incidentReportForm.controls[name]?.enable();

            let val = this.selectedRow[name];

            if (control == 'DATE') {
              if (val)
                this.incidentReportForm.controls[name]?.setValue(val.substring(0, 10));
            }

            else {

              if (val) {
                if (name == 'incident_runway') {
                  let options = { runway: val, id: this.selectedRow['runway_id'] }
                
                  this.incidentReportForm.controls['__incident_runway']?.setValue(options);
                }
                if (name == 'hot_spot') {
                  val = val.toLowerCase();
                  const valInitCap = val.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                  this.incidentReportForm.controls[name]?.setValue(valInitCap);
                }
                else
                this.incidentReportForm.controls[name]?.setValue(val);
              }
              else {
                
                if (name == 'hot_spot')
                  this.incidentReportForm.controls[name]?.setValue('Insufficient Information');
                else
                  this.incidentReportForm.controls[name]?.setValue('');
              }
              let mSelectColumn = '_' + name;
              
              if (this.muliSelectColumn.indexOf(mSelectColumn) > -1) {
              
                var _val = String(val).split(",").map(function (item) {
                  return (name == 'occurrence_display' || name == '_occurrence_display') ? item.trim().replace(' - ', '-') : item.trim();
                }).filter((item: any) => item !== 'null');
              
                this.incidentReportForm.controls[mSelectColumn]?.setValue('');
                if (_val.length == 0 )
                  this.incidentReportForm.controls[mSelectColumn]?.setValue('');
                else if (_val.length > 0) {

                  this.incidentReportForm.controls[mSelectColumn]?.setValue(_val);
                }
                else
                  this.incidentReportForm.controls[mSelectColumn]?.setValue(_val);

                //apply rule by calling onFocusOutEvent
                if (name == 'location_display' || name == 'occurrence_display' && this.incidentReportForm.controls[mSelectColumn]) {
                  {
                    setTimeout(() => {
                      this.onFocusOutEvent(null, mSelectColumn, mSelectColumn, 'INITIAL');
                    }, 0)

                  }
                }

              }
             
            }
            
          }
        }
      );

      //#8252 --PP 12/23/2024 -- Default RWS reports to be classified as "Incident" when Ingested
    
      if (this.selectedRow['rws_fk']  && this.selectedRow['accident_or_incident'] !== 'INC') {
        this.incidentReportForm.controls['accident_or_incident']?.setValue('INC');
        this.has_new_accident_or_incident_from_rws_fk = true;
      }
      setTimeout(() => { this.setEditableColumns(); }, 10);

    }


  }
  setEditableColumns() {
    let hasDetail = this.showSideBarDetailView && this.showSideBarEditView;
    this.incidentReportForm.disable();
   
    //If not detail view then set make editable
    if (!hasDetail) {
      Object.entries(this.muliSelectColumn).forEach(([key, val]) => {
        if (this.editableColumns.indexOf(val) > -1)
          this.incidentReportForm.controls[val]?.enable();
      });
      Object.entries(this.editableColumns).forEach(([key, val]) => {
        if (this.editableColumns.indexOf(val) > -1)
          this.incidentReportForm.controls[val]?.enable();
      });
      //checkbox readonly
     
      this.isReadOnly = true;
    }
  }
  onSelectChangeEvent(event: any, type: any = '', control: any = '', key: any = '') {
    //show or hide related inputbox

    if (control !== '') {
      let val = event?.source?.value?.toString();
      //console.log('onSelectChangeEvent');
      //console.log(control);
      //console.log(val);
      //console.log(event.source.value);
    //  console.log(`onSelvalue);
    //  console.log(`onSelectChangeEvent => ${control}:${(val.substr(1, event.value.indexOf("-")))}  ${this.incidentReportForm.controls[control].value} `);
      if (control == '_cf_display') {
       
        let cf_nonstnd_geo = '';
        var _val = String(val).split(",").map(function (item) {
          return item.trim();
        });

          this.incidentReportForm.controls['cf_nonstnd_geo']?.setValue('');
         if (_val.length > 0) {
          // console.log(`multi select  =>  ${name}: ${_val}`);
          let strArray = [];
          Object.entries(_val).forEach(([key, val]) => {
            if (val.trim().indexOf("-") > -1) {
             // console.log(val.trim().indexOf("-"));
              strArray.push(val.trim());
            }
          });
          
           this.incidentReportForm.controls['cf_nonstnd_geo']?.setValue(strArray.toString());
        }
      }
    
      if (this.muliSelectColumn.indexOf(control) > -1) {

        let _summaryColumn = control.replace("_", "");
       
        let _val = key !== '' ? this.getUpdatedSummaryValues(control, key, event?.checked) : this.incidentReportForm.controls[control].value;

        _val = _val.toString().replace("/^\/", "");

        this.incidentReportForm.controls[control]?.enable();
        this.incidentReportForm.controls[_summaryColumn]?.enable();
        this.incidentReportForm.controls[_summaryColumn]?.setValue(_val);
      
        if (control == '_location_display') {
          if (_val.indexOf('Runway') > -1) {

            this.incidentReportForm.controls['runway_length']?.enable();
            this.incidentReportForm.controls['runway_width']?.enable();
            this.incidentReportForm.controls['surface_type_condition']?.enable();
            this.incidentReportForm.controls['surface_type_condition']?.disable();
          }
          else {
            this.incidentReportForm.controls['runway_length']?.setValue('');
            this.incidentReportForm.controls['runway_width']?.setValue('');
            this.incidentReportForm.controls['runway_length']?.disable();
            this.incidentReportForm.controls['runway_width']?.disable();
            this.incidentReportForm.controls['surface_type_condition_id']?.setValue('');
            this.incidentReportForm.controls['surface_type_condition']?.setValue('');
            this.incidentReportForm.controls['surface_type_condition']?.disable();
          }
        }
        if (control == '_occurrence_display') {
          if (_val.indexOf('Runway Excursion - Veer Off') > -1 || _val.indexOf('Runway Excursion-Veer Off') > -1)
            this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.enable();
          else {
            this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.setValue('');
            this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.disable();
          }
          if (_val.indexOf('Runway Excursion - Overrun') > -1 || _val.indexOf('Runway Excursion-Overrun') > -1)
            this.incidentReportForm.controls['runway_excursions_overrun_distance']?.enable();
          else {
            this.incidentReportForm.controls['runway_excursions_overrun_distance']?.setValue('');
            this.incidentReportForm.controls['runway_excursions_overrun_distance']?.disable();
          }
          if (_val.indexOf('Taxiway Excursion - Veer Off') > -1 || _val.indexOf('Taxiway Excursion-Veer Off') > -1)
            this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.enable();

          else {
            this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.setValue('');
            this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.disable();
          }
        }
        return;
      }
     // this.onFocusOutEvent(event, type, control, key);
    
    }
  }
  onFocusOutEvent(event: any, type: any = '', control: any = '', key: any = '') {
    if (control !== '') {
    
      //console.log(event?.checked);
      //console.log(event);

      setTimeout(() => {
       // console.log(`onFocusOutEvent => ${control}: ${this.incidentReportForm.controls[control].value}`);
       //set summary field value
        
        if (this.muliSelectColumn.indexOf(control) > -1) {
         
          let _val = key !== '' ? this.getUpdatedSummaryValues(control, key, event?.checked) : this.incidentReportForm.controls[control].value;
         
          _val = _val.toString().replace("/^\/", "");
         
          let _summaryColumn = control.replace("_", "");
          this.incidentReportForm.controls[control]?.enable();
          this.incidentReportForm.controls[_summaryColumn]?.enable();
          this.incidentReportForm.controls[_summaryColumn]?.setValue(_val);
         
          if (control == '_location_display') {
            if (_val.indexOf('Runway') > -1) {
              
              this.incidentReportForm.controls['runway_length']?.enable();
              this.incidentReportForm.controls['runway_width']?.enable();
              this.incidentReportForm.controls['surface_type_condition']?.enable();
              this.incidentReportForm.controls['surface_type_condition']?.disable();
            }
            else {
              this.incidentReportForm.controls['runway_length']?.setValue('');
              this.incidentReportForm.controls['runway_width']?.setValue('');
              this.incidentReportForm.controls['runway_length']?.disable();
              this.incidentReportForm.controls['runway_width']?.disable();
              this.incidentReportForm.controls['surface_type_condition_id']?.setValue('');
              this.incidentReportForm.controls['surface_type_condition']?.setValue('');
              this.incidentReportForm.controls['surface_type_condition']?.disable();
            
            }
          }
          if (control == '_occurrence_display') {
            if (_val.indexOf('Runway Excursion - Veer Off') > -1 || _val.indexOf('Runway Excursion-Veer Off') > -1)
              this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.enable();
            else {
              this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.setValue('');
              this.incidentReportForm.controls['runway_excursions_veeroff_distance']?.disable();
            }
            if (_val.indexOf('Runway Excursion - Overrun') > -1 || _val.indexOf('Runway Excursion-Overrun') > -1)
              this.incidentReportForm.controls['runway_excursions_overrun_distance']?.enable();
            else {
              this.incidentReportForm.controls['runway_excursions_overrun_distance']?.setValue('');
              this.incidentReportForm.controls['runway_excursions_overrun_distance']?.disable();
            }
            if (_val.indexOf('Taxiway Excursion - Veer Off') > -1 || _val.indexOf('Taxiway Excursion-Veer Off') > -1)
              this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.enable();
           
            else {
              this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.setValue('');
              this.incidentReportForm.controls['taxiway_excursions_veeroff_distance']?.disable();
            }
          }
          this.incidentReportForm.controls[control]?.disable();
          this.incidentReportForm.controls[_summaryColumn]?.disable();
          if (!this.showSideBarDetailView && this.editableColumns.indexOf(control) > -1)
            this.incidentReportForm.controls[control]?.enable();
          if (!this.showSideBarDetailView && this.editableColumns.indexOf(_summaryColumn) > -1)
            this.incidentReportForm.controls[_summaryColumn]?.enable();

        }

      }, 1)

    }
  //  console.log(`onFocusOutEvent => ${event}: ${type}  ${event.target.value} ${event.target.getAttribute('formControlName')}`);
    if (event?.target?.value)
      return;
    //  this.incidentSearchQuery[type].push(event.target.value);
    //this.incidentSearchQuery.total = (this.incidentSearchQuery?.dateTime.length +
    //  this.incidentSearchQuery?.airport?.length +
    //  this.incidentSearchQuery?.location?.length +
    //  this.incidentSearchQuery?.occurrences?.length +
    //  this.incidentSearchQuery?.opsFlightPhase?.length +
    //  this.incidentSearchQuery?.outcome?.length +
    //  this.incidentSearchQuery?.miscInfomration?.length);


  }
  getUpdatedSummaryValues(control = '', key = '', checked = false) {
  /* console.log('getUpdatedSummaryValues' + control);*/
    let _summaryColumn = control.replace("_", "");
    let val = this.incidentReportForm.controls[_summaryColumn].value;
   
    let strArray = [];
    let i = 0;
   
    var _val = String(val).split(",").map(function (item) {
      if (item.trim() !== '')
      strArray.push((control == '_occurrence_display' || control == 'occurrence_display') ? item.trim().replace(' - ', '-') :   item.trim());
    });
    if (strArray.indexOf(key) == -1 && checked && key !== 'INITIAL')
      strArray.push(key);
    if (strArray.indexOf(key) !== -1 && !checked) {
      var result = arrayRemove(strArray, key);
      //console.log('aaaa**');
      //console.log(result);
      return result;
    }
    //console.log('bbb***');
    //console.log(strArray);
    return strArray;

  }

  isChecked(type: any = '', control: any = '', item: any) {
  
    const allSelected = this.incidentReportForm.controls[control]?.value;
    //if (control == '_location_display') {
    //  console.log(item);
    //  console.log(this.incidentReportForm.controls['_location_display']?.value);
    //  console.log(allSelected.includes(item));
    //}
    return allSelected.includes(item) || false;
  }
  
  onSubmit(hasNext = false) {
    this.message = '';
    let submitForm :  UntypedFormGroup;
    this.incidentReportForm.enable();
    submitForm = this.incidentReportForm;
   
    submitForm.controls['modify_user']?.setValue(this.userName);
   
    if (this.incidentReportForm.controls['__incident_runway']?.value?.runway) 
      submitForm.controls['incident_runway']?.setValue(this.incidentReportForm.controls['__incident_runway']?.value?.runway);
    else
      submitForm.controls['incident_runway']?.setValue(this.incidentReportForm.controls['__incident_runway']?.value);
    if (this.incidentReportForm.controls['__incident_runway']?.value?.id)
      submitForm.controls['runway_id']?.setValue(this.incidentReportForm.controls['__incident_runway']?.value?.id);
    else
      submitForm.controls['runway_id']?.setValue('0');

    Object.entries(this.muliSelectColumn).forEach(([key, val]) => {
     
      let _summaryColumn = val.replace("_", "");
      submitForm.controls[_summaryColumn]?.setValue(this.incidentReportForm.controls[_summaryColumn]?.value ? this.incidentReportForm.controls[_summaryColumn]?.value.toString() : '');
      
    });
   
    this.spinnerService.show();
    this.reportService.UpdateIncidentReportById(submitForm.value)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            this.message = 'Report Updated successfully!';
            this.showStatusMessage(this.message, 'Update Report', 'success');
            if (!hasNext)
              this.onHide(true);
            else {
              this.message = "";
              if (this.gridApiDetail && this.rowDataDetailView && this.showSideBarEditView) {
                this.first = this.first + 1;
                this.selectedRowIndex = (this.first);
                this.setPageData();
                this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth' });
              }
            }
          }
          else {

            // this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Error while update, Please try it again! ' + _errorInfo;

            this.showStatusMessage(this.message, 'Update Report', 'error');
            this.setEditableColumns();
          }

        },

        error: (err: any) => {
          this.spinnerService.hide();
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';

          let _errorInfo = '';

          this.message = 'Unable to update report, Please try it again! ' + _err.message;

          this.showStatusMessage(this.message, 'Update Report', 'error');
          this.setEditableColumns();
        },
        complete: () => { }
      }
      );

  }

  onSaveNext() {
    this.onSubmit(true);
  }
  onSubReportView(type) {
    this.subReportType = type;
    this.showSideBarSubReportView = true;
  }
  onCloseSideBarSubReportView(event: any) {
    this.showSideBarSubReportView = event;
  }
  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity = severity == '' || severity == 'success' ? 'success' : 'error'
    this._snackBar.open(message, severity, {
      panelClass: 'app-notification-' + severity
    });
  }

  openSubReportTab(type: any, id: any, airport = '') {
    let incidentDate = '';
    if (airport !== '') {
      incidentDate = this.incidentReportForm.controls['date_of_incident']?.value;
    }
    
    let url = `/subReport?type=${type}&id=${id}&airport=${airport}&incidentDate=${incidentDate}`;
    window.open(url, this.hasSeparateTabForNarrative ? type : type == 'AIRPORT_DIAGRAM' ? type : 'SubReport');
    if (type == 'AIRPORT_DIAGRAM')
      this.onGridDownloadMap();
   
  }
  loadRunways() {
    let loc_id = this.incidentReportForm.controls['airport_code']?.value;
    if (loc_id && loc_id != '') {
      this.runways = {};
      this.result = {};
    
      this.incidentLookupService.getRunwayByLocID(loc_id)
        .subscribe({
          next: (result) => {
            let _result: any = {};
            _result = result;
            this.result = _result;

            if (_result.success && _result.total > 0) {
              this.alertType = 'success';
              this.runways = _result.result;
              this.filteredRunways = _result.result;
              // this.message = _result.total > 0 ? '' : 'No data found.';
            }
            else if (_result.success && _result.total == 0) {
              this.alertType = 'success';
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result.errorMessage;
              this.message = 'Error while loading runways. ' + _result.errorMessage;
              // this.openSnackBar(this.message, 'DISMISS');
              this.showStatusMessage(this.message, 'Load Runways', 'error');
            }

          },

          error: (err: any) => {
            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';

            this.message = 'Error while loading data. ' + _err.message;
            this.showStatusMessage(this.message, 'Load Runways', 'error');

          },
          complete: () => { }
        }
        );

    }
    
    }
  onRunwayChange($event: any) {
    
    this.incidentReportForm.controls['runway_id'].setValue('0');
    this.incidentReportForm.controls['surface_type_condition_id'].setValue('0');
    this.incidentReportForm.controls['surface_type_condition'].setValue('');
    if ($event?.option?.value) {
      if ($event?.option?.value?.id)
        this.incidentReportForm.controls['runway_id'].setValue($event?.option?.value?.id);
      if ($event?.option?.value?.length)
        this.incidentReportForm.controls['runway_length'].setValue($event?.option?.value?.length);
      if ($event?.option?.value?.width)
        this.incidentReportForm.controls['runway_width'].setValue($event?.option?.value?.width);
     
      if ($event?.option?.value?.surface_type_condition_id) 
        this.incidentReportForm.controls['surface_type_condition_id'].setValue($event?.option?.value?.surface_type_condition_id);
      if ($event?.option?.value?.surface_type_condition)
        this.incidentReportForm.controls['surface_type_condition'].setValue($event?.option?.value?.surface_type_condition);
    }
  }
  getBsClass(field: string) {
    let defaultBsClass = 3;
    Object.entries(this.resultReportColumns).forEach(([key, val]) => {

      if (val['column_name_json'] == field) {

        defaultBsClass = val['bS_class'];
        if (defaultBsClass && defaultBsClass > 0)
          return 'col-md-' + defaultBsClass;
      }
    });
    return 'col-md-' + defaultBsClass;
  }

  onGridDownloadMap() {

    let hasAirportDiagramDefaultDir = this.airportDiagramDefaultDirectory && this.airportDiagramDefaultDirectory !== '';
   
    let fileName = this.selectedRow.diagram_pdf_file;
    let earliestDateYear = hasAirportDiagramDefaultDir ? this.airportDiagramDefaultDirectory : this.selectedRow.diagram_from_date;
    let currentTime = new Date().getTime();
    let url = `/assets/maps/${earliestDateYear}/${fileName}?v=${currentTime}`;//'22/00285AD.pdf';

    let fileExists = false;
    this.sharedService.fileExists(url)
      .subscribe({
        next: (result) => {
          fileExists = result;
          this.downloadMapFile(url, fileExists);
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          fileExists = (err?.status == 200);
          this.downloadMapFile(url, fileExists);
        },
        complete: () => { }
      }
      );

  }

  downloadMapFile(url, fileExists) {
    if (fileExists)
      window.open(url, "Diagram");
    else {
      this.message = 'File does not exist.';
      this.showStatusMessage(this.message, 'Download Diagram', 'error');
      setTimeout(() => {
        this.message = '';
      }, 150);
    }
  }

  toggleExpandedAll() {
    this.allExpanded = !this.allExpanded;
  }
}

function arrayRemove(arr, value) {

  return arr.filter(function (ele) {
    return ele != value;
  });
}
const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.name] = item
    return obj
  }, {})
