
<ng-template [ngIf]="showSideBarDetailView">
    <div class="faa-sidenav-container" style="width:70%" [baseZIndex]="10000">
        <mat-sidenav-container class="faa-sidenav-body-container table-responsive"
                               [style.marginTop.px]="10">
          <mat-sidenav #sidenav mode="over" postion="end" fixedTopGap="0">
          </mat-sidenav>

          <mat-sidenav-content class="faa-sidenav-content">
            <div class="side-bar-content">
              <ng-template [ngIf]="showSideBarDetailView">
                <div class="container">
                  <div class="row row-cols-3">
                    <div class="col h2">Detail View</div>
                    <div class="col text-center">
                      <span class="table-responsive1" [hidden]="!hasQueryGridData">

                        <mat-paginator [length]="totalRecords" (page)="onPageChange($event)"
                                       [pageSize]="1"
                                       [hidePageSize]="true"
                                       [showFirstLastButtons]="true"
                                       [pageIndex]="first"
                                       aria-label="Select page">
                        </mat-paginator>

                      </span>
                    </div>
                    <div class="col text-end">
                      <button mat-mini-fab color="primary" (click)="onHide()" matTooltip="Close window" aria-label="Close">
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>

                  </div>
                </div>

              </ng-template>
              <div class="card">
                <div class="card-body">

                  <div class="card-body card-body-usda-table">

                    <div class="table-responsive " [hidden]="!hasQueryGridData">

                      <div class="form detail-form">

                        <form>
                          <div class="form-row row" *ngIf="columnDefs?.length > 0 && showSideBarDetailView && !hasOtherDataSourceType">

                            <ng-template ngFor let-item [ngForOf]="columnDefs">

                              <mat-form-field [ngClass]="getBsClass(item.field)" *ngIf="item.headerName?.length > 0">
                                <mat-label style="font-weight: bold">{{item.headerName}}:</mat-label>
                                <input matInput value="{{selectedRow[item.field]}}" [readonly]="isReadOnly">

                              </mat-form-field>

                            </ng-template>

                          </div>

                          <div class="form-row row" *ngIf="resultReportColumns?.length > 0 && hasOtherDataSourceType">

                            <ng-template ngFor let-item [ngForOf]="resultReportColumns">

                              <mat-form-field [ngClass]="getBsClass(item.db_column_name)" *ngIf="item.display_name?.length > 0 && rowData && rowData[0]">
                                <mat-label style="font-weight: bold">{{item.display_name}}:</mat-label>
                                <ng-template [ngIf]="item.control == 'TEXTAREA'">
                                  <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly"></textarea>
                                </ng-template>
                                <ng-template [ngIf]="item.control !== 'TEXTAREA'">
                                  <input matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly">
                                </ng-template>

                              </mat-form-field>

                            </ng-template>

                          </div>

                        </form>
                      </div>

                    </div>

                    <div class="" [hidden]="hasQueryGridData">
                      No data found.
                    </div>
                  </div>


                </div>
              </div>
            </div>

          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>

</ng-template>
