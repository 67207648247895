import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { customColDef } from '../report/report.component';
import * as _moment from 'moment';
import { ReportService } from '../services/report.service';

import { HttpClient } from '@angular/common/http';
import { FAALocalStorageService } from '../services/localStorage.service';
import { AnalystDeliverableReportService } from '../services/analystDeliverableReport.service';
import { SpinnerService } from '../services/spinner.service';


@Component({
    selector: 'app-report-analyst-deliverable',
    templateUrl: './report-analyst-deliverable.component.html',
    styleUrls: ['./report-analyst-deliverable.component.css'],
    standalone: false
})
export class ReportAnalystDeliverableComponent implements OnInit {
  //public chartThemeOverrides: AgChartThemeOverrides = {
  //  cartesian: {
  //    axes: {
  //      category: {
  //        label: {
  //          rotation: 0,
  //        },
  //      },
  //    },
  //  },
  //};
  reportType = '';
  subReportType = '';
  title = '';
  currentTime = new Date().getTime();
 
  query = {
    condition: 'and',
    rules: [
    ]
  };
  fieldsWithDateTypes = [];
  hasGridData = false;
  hasChartView = false
  hasQueryGridData = false;
  hasQueryParamter = false;
  @ViewChild('agGrid') agGrid: AgGridAngular;
  private _snackBar = inject(MatSnackBar);
  defaultColDef;
  columnDefs;
  sideBar;
  gridApi;
  autoGroupColumnDef
  currentChartRef = null;

  dateRangeReport = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  userName: any = '';
  role: any = '';

  result = {};

  alertType = 'success';

  message = '';

  totalRecords = 0;
  rowData: Observable<any[]>;
  rowDataDetailView: [];
  queryRowData: Observable<any[]>;
  reportColumns = [];
  sqlQuery = '';
  merged_data_source_id = '';
  hasNonDataSourceReport = true; // true for all non data source reports

  hasLoadedLookupData = false;
  locations: any = {};
  filteredLocations: any = {};
  occurrenceItems: any = {};
  filteredOccurrenceItems: any = {};
  operationTypes: any = {};
  filteredOperationTypes: any = {};

  operation_type_display: any = {};
  occurrence_display: any = {};
  location_display: any = {};

  hasFirstLoad = true;
  constructor(private activatedRoute: ActivatedRoute, private authorizeService: AuthorizeService,
    public reportService: ReportService, public analystDeliverableReportService: AnalystDeliverableReportService,
    private localStorageService: FAALocalStorageService,
    private http: HttpClient,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {

    this.dateRangeReport = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.reportType = params.type;
      });
    this.userName = this.authorizeService.getUserName();
    this.role = this.authorizeService.getUserRole();
    this.getReportColumns();

    //set static values from appData Json file
    this.http.get('../../assets/appData.json?v=' + this.currentTime)
      .subscribe({
        next: (result) => {

            let _result: any = {};
          _result = result;

            if (_result.staticData.locations) {
              this.locations = _result.staticData.locations;
              this.filteredLocations = _result.staticData.locations;
            }
            if (_result.staticData.operationTypes) {
              this.operationTypes = _result.staticData.operationTypes;
              this.filteredOperationTypes = _result.staticData.operationTypes;
            }
            if (_result.staticData.occurrenceItems) {
              this.occurrenceItems = _result.staticData.occurrenceItems;
              this.filteredOccurrenceItems = _result.staticData.occurrenceItems;
            }
            this.hasLoadedLookupData = true;
          },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          console.log(err);
        },
        complete: () => { }
      }
      );


    this.setInitalData();


  }
  setInitalData() {

    this.getReportColumns();
    this.dateRangeReport = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
    this.hasFirstLoad = true;

    switch (this.reportType) {

      case 'STATISTICS_COUNT': { this.title = 'Incident Statistics Report'; break; }
      case 'INCIDENT_HISTORY': { this.title = 'Incident History Report'; break; }
      case 'OCCURRENCE_EVENTS_REPORT': { this.title = 'Distribution of Occurrence Types with Events Report '; break; }
      case 'PART139_AIRPORT_REPORT': { this.title = 'Distribution of Events at Part 139 and non-Part 139 Airports'; break; }
      case 'OPERATION_TYPE_EVENTS_REPORT': { this.title = 'Distribution of Events by Operation Type Report'; break; }
      case 'NON_STD_GEOMETRY_CF_EVENTS_REPORT': { this.title = 'Non-standard Geometry Contributing Factors Events Report'; break; }
      case 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT': { this.title = 'Non-geometry Airport-Related Contributing Factors Events Report'; break; }
      case 'CONTRIBUTING_FACTORS_REPORT': { this.title = 'Contributing Factors Report'; break; }
      case 'SEVERITY_DISTRIBUTION_RUNWAY_INCURSIONS_PD_REPORT': { this.title = 'Severity Distribution of Runway Incursions – PD Report'; break; }
    }
    setTimeout(() => {
      this.setColumnsAndGridInfo();
    }, 1)

  }

  setColumnsAndGridInfo() {

    let objList: customColDef[] = [];

    Object.entries(this.reportColumns).forEach(
      ([key, item]) => {
        let x = {
          field: item['column_name_json'],
          headerName: item['display_name'],
          sort: null,
          sortOrder: item['display_order'],
          type: item['data_type'],
          pinned: item['display_order'] == 1,
          width: item['display_order'] == 1 ? 150 : 0,
          filter: true,
          sortable: true,
          minWidth: 150,
          cellRenderer:
            function (params) {
              if (item['data_type'] == 'DATE') {
                return params.value ? params.value.substring(0, 10) : '';
              }
              else if (item['column_name_json'] == 'analyst_editing_stage') {
                return params.value ?
                  (params.value == 'IN PROGRESS' ? '<span class="badge-fontSize col-md-8 badge badge-primary">' + params.value + '</span>' :
                    (params.value == 'DONE' ? '<span class="col-md-8 badge badge-success badge-fontSize">' + params.value + '</span>' :
                      (params.value == 'REVIEW' ? '<span class="h4 col-md-8 badge badge-warning">' + params.value + '</span>' :
                        (params.value == 'UNREVIEWED' ? '<span class="h4 col-md-8 badge badge-danger">' + params.value + '</span>' : params.value))
                    ))
                  : '';
              }
              else
                return params.value;
            }
        }
        if (objList.indexOf(x) === -1 && item['include_in_grid']) {
          x.sort = this.reportType == 'INCIDENT' && item['column_name_json'] == 'airport_code' ? 'asc' : this.reportType !== 'INCIDENT' && item['display_order'] == 1 ? 'asc' : null,
            objList.push(x);
        }
      }
    );
    objList.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);
    //  this.columnDefs = objList;
    if (this.reportType == 'STATISTICS_COUNT') {
      this.columnDefs = [{ field: 'status', sortable: true, filter: true, width: 220 },
      { field: 'count', sortable: true, filter: true }];

      this.loadReportData();
    }
    else if (this.reportType == 'OCCURRENCE_EVENTS_REPORT' || this.reportType == 'SEVERITY_DISTRIBUTION_RUNWAY_INCURSIONS_PD_REPORT') {
      this.columnDefs = [{ field: 'name', sortable: true, filter: true, width: 220 },
      { field: 'count', sortable: true, filter: true },
      { field: 'percent', sortable: true, filter: true }
      ];

      if (!this.hasFirstLoad)
        this.loadReportData();
      else
        this.hasFirstLoad = false;

    }
    else if (this.reportType == 'PART139_AIRPORT_REPORT' || this.reportType == 'OPERATION_TYPE_EVENTS_REPORT'
      || this.reportType == 'NON_STD_GEOMETRY_CF_EVENTS_REPORT' || this.reportType == 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT'
      || this.reportType == 'CONTRIBUTING_FACTORS_REPORT' ) {
      this.columnDefs =
        [

          { field: 'name', sortable: true, filter: true, width: 220 },
          { field: 'count', sortable: true, filter: true },
        ];

      if (!this.hasFirstLoad)
        this.loadReportData();
      else
        this.hasFirstLoad = false;
    }

    else //report columns from object ()
      this.columnDefs = objList;

    this.defaultColDef = {
      flex: 1,
      enableRowGroup: true,
      suppressColumnVirtualisation: true

    };
    this.sideBar = "columns";
    this.autoGroupColumnDef = { minWidth: 200 };

  }
  onGridReady(params) {

    this.gridApi = params.api;
   
    this.gridApi.closeToolPanel();
    // this.loadReportData();
    if (this.reportType === 'INCIDENT_HISTORY') {
      const sortModel = [
        { colId: 'rec_create_date', sort: 'asc' }
      ];
      //this.gridApi.setSortModel(sortModel);
    }
    this.sizeToFit();
    this.onFirstDataRendered(params);
  }
  onFirstDataRendered(params) {

  }

  loadReportData() {
    this.hasQueryParamter = this.query && this.query.rules ? this.query.rules.length > 0 : false;
    if ((this.reportType == 'PART139_AIRPORT_REPORT' || this.reportType == 'OPERATION_TYPE_EVENTS_REPORT')
      && (this.dateRangeReport.value.start == null && this.dateRangeReport.value.end == null))
      return;
    if ((this.reportType == 'STATISTICS_COUNT' || this.reportType == 'OCCURRENCE_EVENTS_REPORT' || this.reportType == 'PART139_AIRPORT_REPORT' || this.reportType == 'OPERATION_TYPE_EVENTS_REPORT'
      || this.reportType == 'NON_STD_GEOMETRY_CF_EVENTS_REPORT' || this.reportType == 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT'
      || this.reportType == 'CONTRIBUTING_FACTORS_REPORT' || this.reportType == 'SEVERITY_DISTRIBUTION_RUNWAY_INCURSIONS_PD_REPORT')
      && (this.dateRangeReport.value.start == null || this.dateRangeReport.value.end == null)) {
      //start or end date only then set both date same
      if (this.dateRangeReport.value.start == null && this.dateRangeReport.value.end !== null)
        this.dateRangeReport.value.start = this.dateRangeReport.value.end;
      if (this.dateRangeReport.value.start !== null && this.dateRangeReport.value.end == null)
        this.dateRangeReport.value.end = this.dateRangeReport.value.start;
    }
   if (this.reportType == 'OCCURRENCE_EVENTS_REPORT' || this.reportType == 'NON_STD_GEOMETRY_CF_EVENTS_REPORT' || this.reportType == 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT'
      || this.reportType == 'CONTRIBUTING_FACTORS_REPORT')
       this.sqlQuery = this.basicRulesetToSQL(this.query);
    else
      this.sqlQuery = '';

    if (this.hasNonDataSourceReport) {
      this.message = 'Loading....';
      this.hasGridData = false;
      this.totalRecords = 0;
      
      this.rowDataDetailView = [];
      this.result = {};
      this.spinnerService.show();

      let sDate = this.dateRangeReport?.value?.start ? _moment(new Date(_moment(this.dateRangeReport.value.start).toDate())).format("YYYY-MM-DD") : '';
      let eDate = this.dateRangeReport?.value?.end ? _moment(new Date(_moment(this.dateRangeReport.value.end).toDate())).format("YYYY-MM-DD") : '';
      
      
      this.analystDeliverableReportService.getAnalystDeliverableReport(this.reportType, this.sqlQuery, '', null,
        this.hasNonDataSourceReport ? this.merged_data_source_id : '',
        sDate,
        eDate

      )
        .subscribe({
          next: (result) => {
            this.spinnerService.hide();
            let _result: any = {};
            _result = result;
            this.result = _result;
            this.hasGridData = _result.total > 0;
            if (_result.success) {
              this.alertType = 'success';
             // this.rowData = _result.result;
               this.rowDataDetailView = _result.result;
              this.gridApi.setGridOption("rowData", _result.result);
              this.message = _result.total > 0 ? '' : 'No data found.';
              this.totalRecords = _result.total;
              this.sizeToFit();
              //this.showStatusMessage('Data Loaded Successfully', 'Get Report Data', 'success');
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
              this.message = 'Error while loading data. ' + _errorInfo;
              this.showStatusMessage(this.message, 'Get Report Data', 'error');

            }
          },

          error: (err: any) => {
            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            this.message = 'Error while loading data. ' + _err.message;
            this.showStatusMessage(this.message, 'Get Report Data', 'error');
            this.spinnerService.hide();
          },
          complete: () => { }
        }
        );

    }
  }
  onFocusOutEvent(event: any, type: any = '', control: any = '') {
    if (control !== '') {
      setTimeout(() => {
        //console.log(`onFocusOutEvent => ${control}: ${this[control]}`);
        //console.log(this[control]);

        this.createSearchDynamicQuery(control, this[control]);
      }, 1)

    }

  }
  createSearchDynamicQuery(control: any, value: any, type: any = '') {

    //existing control then remove the entry - 12/16 PP #6384

    //console.log(`0 control = ${control} val: ${value} index: ${(this.query.rules.findIndex(f => (f.field == control)))}`);
    if (this.query.rules.findIndex(f => (f.field == control)) !== -1) {
      let _index = this.query.rules.findIndex(f => (f.field == control));
      // console.log(`0.0 control = ${control} val: ${value} index: ${(this.query.rules.findIndex(f => (f.field == control)))}`);
      this.query.rules.splice(_index, 1);

      // console.log(`0.0 control = ${control} val: ${value} index: ${(this.query.rules.findIndex(f => (f.field == control)))}`);
    }
    switch (control) {

      default: {
        // console.log(`${control} ${value} ${this.query.rules.findIndex(f => (f.field == control))}`)
        let operator = "in";

        if (this.query.rules.findIndex(f => (f.field == control)) == -1) {

          //  console.log(`1 control = ${control} val: ${value} len: ${Object.keys(value).length}`);
          if (value && (value !== null || value !== '' || Object.keys(value).length != 0)) {
            if (operator === "in" && Object.keys(value).length > 0) {
              // console.log(`1.0 control = ${control} op: ${operator} val: ${value} len: ${Object.keys(value).length}`);
              this.query.rules.push({ "field": control, "operator": operator, "value": value });
            }
            else if (operator !== "in") {
              //  console.log(`1.1 control = ${control} op: ${operator} val: ${value} len: ${Object.keys(value).length}`);
              this.query.rules.push({ "field": control, "operator": operator, "value": value });
            }
          }

        }
        else {

          //console.log(`2 control = ${control} val: ${value}`);
          let currentIndex = this.query.rules.findIndex(f => (f.field == control));
          if (value && (value !== null || value !== '' || Object.keys(value).length != 0))
            this.query.rules[currentIndex].value = value;
        }
        break;
      }


    }

  }


  valueToSQL(value, column) {
    switch (typeof value) {
      case 'string':
        return "'" + value + "'";
      case 'boolean':
        return value ? '1' : '0';
      case 'number':
        if (isFinite(value)) return value;
    }
  }

  isDefined(value) {
    return value !== undefined;
  }
  basicRulesetToSQL(ruleset) {
    return ruleset.rules.map((rule) => {
      if (rule.rules) {
        return "(" + this.basicRulesetToSQL(rule) + ")";
      }
      var column = rule.field,
        operator, value;

      switch (rule.operator) {

        case "is null":
        case "is not null":
          operator = rule.operator;
          value = "";
          break;
        case "in":
        case "not in":
          operator = rule.operator;

          if (column == 'occurrence_display' || column == 'operation_type_display' || column == 'flight_phase_display'
            || column == 'location_display' || column == 'weather_display' || column == 'surface_condition_display') {

            let inStrText = rule.value.toString();

            if (inStrText.indexOf(",") !== -1) {

              value = inStrText.replaceAll(",", " = 'YES' OR ");
              value += " = 'YES'";
            }
            else

              value = "(" + inStrText.toUpperCase() + " = 'YES' )";

            return " ( " + value + " ) ";
          }
          if (Array.isArray(rule.value) && rule.value.length)

            value = "(" + rule.value.map(this.valueToSQL, column).filter(this.isDefined).join(", ") + ")";
          //return value;

          break;

        case "like":
          operator = rule.operator;
          if (rule.value.length)
            value = "'%" + rule.value + "%'"
          break;

        default:
          operator = rule.operator;

          value = this.valueToSQL(rule.value, column);
          break;
      }

      if (this.isDefined(column) && this.isDefined(value) && this.isDefined(operator)) {

        return "(" + (column + " " + operator + " " + value).trim() + ")";
      }
    }).filter(this.isDefined).join(" " + ruleset.condition + " ");
  }
  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity = severity == '' || severity == 'success' ? 'success' : 'error'
    this._snackBar.open(message, severity, {
      panelClass: 'app-notification-' + severity
    });
  }
  getReportColumns() {

    let _last_update_localStorage = this.localStorageService.get('asd-lst-update-day') || '';
    let keyid = 'asd-reportColumns-' + this.reportType;
    let _item = this.localStorageService.get(keyid) || [];

    if (Object.keys(_item).length > 0 && _last_update_localStorage === new Date().getDate()) {
      this.reportColumns = [];
      this.reportColumns = _item;

    }
    else {

      this.spinnerService.show();
      this.reportService.getReportColumns(this.reportType)
        .subscribe({
          next: (result) => {
            this.spinnerService.hide();
            let _result: any = {};
            _result = result;

            if (_result.success) {
              this.reportColumns = [];
              this.reportColumns = _result.result;

              if (Object.keys(_result.result).length > 0) {
                this.localStorageService.set(keyid, _result.result);
                this.localStorageService.set('asd-lst-update-day', new Date().getDate());

              }
              // this.showStatusMessage('Data loaded succcessfully', 'Get Report Columns', 'success');
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
              this.message = 'Error while loading data. ' + _errorInfo;

              this.showStatusMessage(this.message, 'Get Report Columns', 'error');
            }

          },

          error: (err: any) => {

            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            this.message = 'Error while loading data. ' + _err.errorMessage;
            this.showStatusMessage(this.message, 'Get Report Columns', 'error');
            this.spinnerService.hide();
          },
          complete: () => { }
        }
        );

    }

  }

  autoSizeAll(skipHeader = false) {
    let allColumnIds = [];
    if (this.gridApi && this.hasGridData) {
      this.gridApi.getColumns().forEach(function (column) {
        allColumnIds.push(column.getColId());
      });
      this.gridApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  }
  sizeToFit() {
    if (this.hasGridData && this.gridApi) {
      setTimeout(() => {
    
          this.gridApi.sizeColumnsToFit();
          this.setAutoHeight();
          this.autoSizeAll();
      

      }, 1);

    }
  }
  setAutoHeight() {
    this.gridApi.setGridOption("domLayout", "autoHeight"); 
    // auto height will get the grid to fill the height of the contents,
    // so the grid div should have no height set, the height is dynamic.
    (document.querySelector<HTMLElement>('#myGrid')! as any)!.style.height = '';
 
  }
  onClearInput() {
    this.query.rules = [];
    this.merged_data_source_id = '';
    this.message = '';
    this.hasGridData = false;
    this.hasChartView = false;
    this.operation_type_display = [];
    this.occurrence_display = [];
    this.location_display = [];
    this.dateRangeReport = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
  }
  generateColumnsForExcel(): string[] {

    let objList: customColDef[] = [];

    Object.entries(this.reportColumns).forEach(
      ([key, item]) => {

        let x = {
          field: item['column_name_json'],
          headerName: item['display_name'],
          sortOrder: item['display_order'],
          type: item['data_type']
        }

        if (objList.indexOf(x) === -1 && item['include_in_export']) {
          objList.push(x);
        }
      }
    );
    objList.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);

    return objList.map(column => column.field);
  }
  onDownload() {
    let d = new Date();
    this.gridApi.exportDataAsCsv({
      fileName: this.reportType + '_Data_' + (d.getMonth() + 1) + d.getDate() + d.getFullYear(),
      columnKeys: this.generateColumnsForExcel()
    })

  }
  onModelUpdated(params) {
    this.hasChartView = false;
    if (this.reportType == 'PART139_AIRPORT_REPORT' || this.reportType == 'OPERATION_TYPE_EVENTS_REPORT' || this.reportType == 'NON_STD_GEOMETRY_CF_EVENTS_REPORT' || this.reportType == 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT'
      || this.reportType == 'CONTRIBUTING_FACTORS_REPORT' || this.reportType == 'SEVERITY_DISTRIBUTION_RUNWAY_INCURSIONS_PD_REPORT') {
     if (this.currentChartRef != null)
        this.currentChartRef.destroyChart();
      var createRangeChartParams = {
        cellRange: {
          columns: ['name', 'count'],
        },

         suppressChartRanges: true,
        chartType: (this.reportType == 'NON_STD_GEOMETRY_CF_EVENTS_REPORT' || this.reportType == 'NON_GEOMETRY_AIRPORT_RELATED_CF_EVENTS_REPORT' || this.reportType == 'CONTRIBUTING_FACTORS_REPORT') ? 'bar' : 'pie',
        
        chartContainer: document.querySelector('#myChart'),
        aggFunc: 'sum',
      };
      this.currentChartRef = params.api.createRangeChart(createRangeChartParams);

      this.hasChartView = true;
    }
  }

  onClearDateRange() { 
    this.dateRangeReport.controls['start'].setValue(null);
    this.dateRangeReport.controls['end'].setValue(null);
    this.loadReportData();
  }
}
