
import { TemplateRef, ViewChild, inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ChartRef, ChartType, ColDef, Column, GridApi, GridReadyEvent } from 'ag-grid-community';
//import { Field, FieldMap, QueryBuilderClassNames, QueryBuilderConfig } from 'angular2-query-builder';


import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { MatBtnCellRendererComponent } from '../agGrid/mat-btn-cell-renderer/mat-btn-cell-renderer.component';
import { QueryService } from '../services/query.service';
import { ReportService } from '../services/report.service';

import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ManageQueryDialogComponent } from '../manage-query-dialog/manage-query-dialog.component';
import { QueryViewModel } from '../model/query';
import { MatSideToggleComponent } from '../agGrid/mat-side-toggle/mat-side-toggle.component';

import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';

import { HttpClient } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { IncidentService } from '../services/incident.service';
import { FAALocalStorageService } from '../services/localStorage.service';
import { Field, FieldMap, QueryBuilderConfig } from '../model/shared';
import { SpinnerService } from '../services/spinner.service';


@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css'],
    standalone: false
})
export class ReportComponent implements OnInit {
  private _snackBar = inject(MatSnackBar);
  reportType = '';
  subReportType = '';
  incidentByStatus = '';
  title = '';
  currentTime = new Date().getTime();
  userName = '';
  role = '';
  message = '';
  sqlQuery = '';
  keyword = '';
  pageSize = 10;
  jumpToPageNo = '';
  lastPageNo = '';
  totalRecords = 0;
  alertType = '';
  queryColumns: any = {};
  queries = [];
  showSideBarQuery: boolean = false;
  showSideBarDetailView: boolean = false;
  showSideBarEditView: boolean = false;
  showSideBarSubReportView: boolean = false;
  showSideBarManageQueriesView: boolean = false;
  showSideBarColumnFilterView: boolean = false;
  isRequestInProgress = false;
  selectedRowIndex = -1;
  detailViewRowIndex = 0;
  queryName = '';
  newSavedQueryId = 0;
  currentSavedQueryId = 0;

  query = {
    condition: 'and',
    rules: [
    ]
  };
  fieldsWithDateTypes = [];
  hasGridData = false;
  hasQueryGridData = false;
  hasQueryParamter = false;
  @ViewChild('agGrid') agGrid: AgGridAngular;
  @ViewChild('savedQuerySelect') savedQuerySelect: MatSelect;
  result = {};
  selectedRow = {};
  resultReportColumns = {};
  incidentReportColumns = {};
  public rowData: any[] | null;
  rowDataDetailView: [];
  queryRowData: any[] | null;

  sideBar;
  columnDefs!: ColDef[];
  gridApi!: GridApi;

  defaultColDef;
 
  incidentReportColumnDefs;
  queryColumnDefs;
  autoGroupColumnDef;
  isQueryOwner = false;
  currentChartRef: ChartRef = null;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  incidentSearchQuery = {
    dateTime: [], airport: [],
    occurrences: [], location: [],
    outcome: [], opsFlightPhase: [],
    miscInfomration: [],
    total: 0

  };
  checked = false;
  hasLoadedInicidentSearchLookupData = false;
  timeOfDay: any = {};
  filteredTimeOfDay: any = {};
  localTimes: any = {};
  filteredLocalTimes: any = {};

  airports: any = {};
  selectedAirports = [];
  hasLoadedAirports = false;

  regions: any = {};
  filteredRegions: any = {};
  hasLoadedRegions = false;

  
  surfaceTypeConditions: any = {};
  filteredSurfaceTypeConditions: any = {};
  hasLoadedSurfaceTypeConditions = false;
  

  airportUse: any = {};
  filteredAirportUse: any = {};
  airportOwnership: any = {};
  filteredAirportOwnership: any = {};

  gaAssetRoles: any = {};
  filteredGaAssetRoles: any = {};

  _14CFRPart139: any = {};
  filtered_14CFRPart139: any = {};
  toweredAirport: any = {};
  filteredToweredAirport: any = {};
  cORE30: any = {};
  filteredCORE30: any = {};
  oEP35: any = {};
  filteredOEP35: any = {};

  nPIASHubSize: any = {};
  filteredNPIASHubSize: any = {};
  nPIASServiceLevel: any = {};
  filteredNPIASServiceLevel: any = {};


  locations: any = {};
  filteredLocations: any = {};
  weathers: any = {};
  filteredWeathers: any = {};

  precipitations: any = {};
  filteredPrecipitations: any = {};
  surfaceConditions: any = {};
  filteredSurfaceConditions: any = {};


  accidentOrIncident: any = {};
  filteredAccidentOrIncident: any = {};
  hotSpots: any = {};
  filteredHotSpots: any = {};
  occurrenceItems: any = {};
  filteredOccurrenceItems: any = {};
  operationTypes: any = {};
  filteredOperationTypes: any = {};

  flightPhases: any = {};
  filteredFlightPhases: any = {};

  specificInjuries: any = {};
  filteredSpecificInjuries: any = {};

  yesOptionOnly: any = {};
  //anyInjuries = ['YES'];
  //anyAircraftDamages = ['YES'];
  //damageToProperty = ['YES'];
  //unspecifiedTypeAircraftDamage = ['YES'];
  nTSB_AIDSDamageToAircraft: any = {};
  filteredNTSB_AIDSDamageToAircraft: any = {};

  dataSources: any = {};
  filteredDataSources: any = {};
  runwayIncursionCategory: any = {};
  filteredRunwayIncursionCategory: any = {};
  nonStandardGeometry: any = {};
  filteredNonStandardGeometry: any = {};
  construction: any = {};
  signage: any = {};
  marking: any = {};
  lighting: any = {};

  proceduresOrOperations: any = {};
  communication: any = {};
  obstructionsNearRunway: any = {};
  mechanicalFailure: any = {};

  lossOfAircraftControl: any = {};
  weather: any = {};
  lackOfOverrunAre: any = {};
  failureToMaintainClearance: any = {};
  surfaceConditionsCF: any = {};
  other: any = {};

  incidentEditStage: any = {};
  filteredIncidentEditStage: any = {};
  selectedReportId = '';
  incidentQuery: UntypedFormGroup;
  hasNonDataSourceReport = false; // means seperate reports like INCIDENT_HISTORY/STATISTICS_COUNT etc.
  merged_data_source_id = '';
  year = 0;
  dateRangeReport = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
 
  public config: QueryBuilderConfig = {

    fields: {
    }
  };

  gridOptions = {
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    paginationNumberFormatter: function (params) {
      return '' + params.value.toLocaleString() + '';
    },
    getRowStyle: params => {
      if (params && params.data && params.data.current == 1) {
        return { background: '#c6ecd7' };
      }
    }

  }
  
  
  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private localStorageService: FAALocalStorageService, private authorizeService: AuthorizeService,
    public reportService: ReportService, public queryService: QueryService, public incidentLookupService: IncidentService,
    private spinnerService: SpinnerService, private http: HttpClient,
    private fb: UntypedFormBuilder, public dialog: MatDialog) { }

 
  ngOnInit(): void {
    this.hasNonDataSourceReport = false;
    this.merged_data_source_id = '';
    this.incidentByStatus = '';
    this.year = 0;
    this.dateRangeReport = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.reportType = params.type;
        this.incidentByStatus = params.status;
      });
    this.userName = this.authorizeService.getUserName();
    this.role = this.authorizeService.getUserRole();
    if (this.reportType == 'INCIDENT_BY_STATUS')
      this.getReportColumns(true);
    this.getQueries();
    this.setInitalData();
    this.setAppDataValues();
   
  }
  ngAfterViewInit() {
    this.setIncidentReportAirportInitialValue();
  }
  setIncidentReportAirportInitialValue() {

  }
  setAppDataValues() {
    if (this.reportType == 'INCIDENT') {
      //read dot net core appsettings.json file
      this.http.get('../../assets/appData.json?v=' + this.currentTime)
        .subscribe({
          next: (result) => {
            let _result: any = {};
            _result = result;

            if (_result.staticData.timeOfDay) {
              this.timeOfDay = _result.staticData.timeOfDay;
              this.filteredTimeOfDay = _result.staticData.timeOfDay;
            }
            if (_result.staticData.localTimes) {
              this.localTimes = _result.staticData.localTimes;
              this.filteredLocalTimes = _result.staticData.localTimes;
            }
            if (_result.staticData.airportUse) {
              this.airportUse = _result.staticData.airportUse;
              this.filteredAirportUse = _result.staticData.airportUse;
            }

            if (_result.staticData.airportOwnership) {
              this.airportOwnership = _result.staticData.airportOwnership;
              this.filteredAirportOwnership = _result.staticData.airportOwnership;
            }

            if (_result.staticData._14CFRPart139) {
              this._14CFRPart139 = _result.staticData._14CFRPart139;
              this.filtered_14CFRPart139 = _result.staticData._14CFRPart139;
            }

            if (_result.staticData.toweredAirport) {
              this.toweredAirport = _result.staticData.toweredAirport;
              this.filteredToweredAirport = _result.staticData.toweredAirport;
            }

            if (_result.staticData.cORE30) {
              this.cORE30 = _result.staticData.cORE30;
              this.filteredCORE30 = _result.staticData.cORE30;
            }
            if (_result.staticData.oEP35) {
              this.oEP35 = _result.staticData.oEP35;
              this.filteredOEP35 = _result.staticData.oEP35;
            }
            if (_result.staticData.nPIASHubSize) {
              this.nPIASHubSize = _result.staticData.nPIASHubSize;
              this.filteredNPIASHubSize = _result.staticData.nPIASHubSize;
            }
            if (_result.staticData.nPIASServiceLevel) {
              this.nPIASServiceLevel = _result.staticData.nPIASServiceLevel;
              this.filteredNPIASServiceLevel = _result.staticData.nPIASServiceLevel;
            }
            if (_result.staticData.gaAssetRoles) {
              this.gaAssetRoles = _result.staticData.gaAssetRoles;
              this.filteredGaAssetRoles = _result.staticData.gaAssetRoles;
            }

            if (_result.staticData.weathers) {
              this.weathers = _result.staticData.weathers;
              this.filteredWeathers = _result.staticData.weathers;
            }
            if (_result.staticData.accidentOrIncident) {
              this.accidentOrIncident = _result.staticData.accidentOrIncident;
              this.filteredAccidentOrIncident = _result.staticData.accidentOrIncident;
            }
            if (_result.staticData.hotSpots) {
              this.hotSpots = _result.staticData.hotSpots;
              this.filteredHotSpots = _result.staticData.hotSpots;
            }

            if (_result.staticData.nTSB_AIDSDamageToAircraft) {
              this.nTSB_AIDSDamageToAircraft = _result.staticData.nTSB_AIDSDamageToAircraft;
              this.filteredNTSB_AIDSDamageToAircraft = _result.staticData.nTSB_AIDSDamageToAircraft;
            }

            if (_result.staticData.dataSources) {
              this.dataSources = _result.staticData.dataSources;
              this.filteredDataSources = _result.staticData.dataSources;
            }

            if (_result.staticData.nonStandardGeometry) {
              this.nonStandardGeometry = _result.staticData.nonStandardGeometry;
              this.filteredNonStandardGeometry = _result.staticData.nonStandardGeometry;
            }
            if (_result.staticData.runwayIncursionCategory) {
              this.runwayIncursionCategory = _result.staticData.runwayIncursionCategory;
              this.filteredRunwayIncursionCategory = _result.staticData.runwayIncursionCategory;
            }
            if (_result.staticData.yesOptionOnly) {
              this.yesOptionOnly = _result.staticData.yesOptionOnly;
            }

            if (_result.staticData.construction) {
              this.construction = _result.staticData.construction;
            }
            if (_result.staticData.signage) {
              this.signage = _result.staticData.signage;
            }
            if (_result.staticData.marking) {
              this.marking = _result.staticData.marking;
            }

            if (_result.staticData.lighting) {
              this.lighting = _result.staticData.lighting;
            }
            if (_result.staticData.proceduresOrOperations) {
              this.proceduresOrOperations = _result.staticData.proceduresOrOperations;
            }

            if (_result.staticData.communication) {
              this.communication = _result.staticData.communication;
            }
            if (_result.staticData.obstructionsNearRunway) {
              this.obstructionsNearRunway = _result.staticData.obstructionsNearRunway;
            }
            if (_result.staticData.mechanicalFailure) {
              this.mechanicalFailure = _result.staticData.mechanicalFailure;
            }
            if (_result.staticData.lossOfAircraftControl) {
              this.lossOfAircraftControl = _result.staticData.lossOfAircraftControl;
            }

            if (_result.staticData.weather) {
              this.weather = _result.staticData.weather;
            }
            if (_result.staticData.lackOfOverrunAre) {
              this.lackOfOverrunAre = _result.staticData.lackOfOverrunAre;
            }
            if (_result.staticData.failureToMaintainClearance) {
              this.failureToMaintainClearance = _result.staticData.failureToMaintainClearance;
            }
            if (_result.staticData.surfaceConditionsCF) {
              this.surfaceConditionsCF = _result.staticData.surfaceConditionsCF;
            }
            if (_result.staticData.other) {
              this.other = _result.staticData.other;
            }

            if (_result.staticData.incidentEditStage) {
              this.incidentEditStage = _result.staticData.incidentEditStage;
              this.filteredIncidentEditStage = _result.staticData.incidentEditStage;
            }
            if (_result.staticData.locations) {
              this.locations = _result.staticData.locations;
              this.filteredLocations = _result.staticData.locations;
            }
            if (_result.staticData.flightPhases) {
              this.flightPhases = _result.staticData.flightPhases;
              this.filteredFlightPhases = _result.staticData.flightPhases;
            }
            if (_result.staticData.specificInjuries) {
              this.specificInjuries = _result.staticData.specificInjuries;
              this.filteredSpecificInjuries = _result.staticData.specificInjuries;
            }
            if (_result.staticData.operationTypes) {
              this.operationTypes = _result.staticData.operationTypes;
              this.filteredOperationTypes = _result.staticData.operationTypes;
            }
            if (_result.staticData.occurrenceItems) {
              this.occurrenceItems = _result.staticData.occurrenceItems;
              this.filteredOccurrenceItems = _result.staticData.occurrenceItems;
            }

            if (_result.staticData.precipitations) {
              this.precipitations = _result.staticData.precipitations;
              this.filteredPrecipitations = _result.staticData.precipitations;
            }
            if (_result.staticData.surfaceConditions) {
              this.surfaceConditions = _result.staticData.surfaceConditions;
              this.filteredSurfaceConditions = _result.staticData.surfaceConditions;
            }

            this.hasLoadedInicidentSearchLookupData = true;
          },

          error: (err: any) => {
            let _err: any = {};
            _err = err;
            console.log(err);
          },
          complete: () => { }
        }
        );

    }
  }
  setInitalData() {
    this.merged_data_source_id = '';
    this.year = 0;
    this.dateRangeReport = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
    this.jumpToPageNo = '';
    this.lastPageNo = '';
    this.selectedReportId = '';
    this.getReportColumns();
    switch (this.reportType) {
      case 'AIRPORT': {
        this.title = 'Airport Report';
        this.query = {
          condition: 'and',
          rules: [
          ]
        };
        //let qPart139Status =
        //  { "condition": "and", "rules": [{ "field": "PART139STATUS", "operator": "=", "value": 'NULL' }] };
        //this.query = qPart139Status;
        break
      }
      case 'INCIDENT':
        {
          this.title = 'Incident Report';
          this.loadAirportNameWithCode();
          this.loadRegions();
          this.loadSurfaceTypeConditions();
          break;
        }
      case 'NTSB': this.title = 'NTSB Aviation Accident and Incident Data System - NTSB Report'; break;
      case 'RWS': this.title = 'Runway Safety Office Runway Incursion - RWS Report '; break;
      case 'VPDS': this.title = 'Vehicle / Pedestrian Deviations System - VPDS Report'; break;
      case 'PDS': this.title = 'Pilot Deviation System - PDS Report'; break;
      case 'AIDS': this.title = 'FAA Aviation Accident and Incident Data System - AIDS Report'; break;
      case 'ASRS': this.title = 'NASA Aviation Safety Reporting System - ASRS Report'; break;
    }
    // Title for Incident By Status
    if (this.reportType == 'INCIDENT_BY_STATUS' && this.incidentByStatus) {

      switch (this.incidentByStatus) {
        case 'REVIEW': { this.title = 'Incidents By Status: Review'; break };
        case 'IN_PROGRESS': { this.title = 'Incidents By Status: In Progress'; break };
        case 'UNREVIEWED': { this.title = 'Incidents By Status: UnReviewed'; break };
      }

      this.query = {
        condition: 'and',
        rules: [
        ]
      };
      let st = `${this.incidentByStatus}`;
      let qStatus =
        { "condition": "and", "rules": [{ "field": "analyst_editing_stage", "operator": "=", "value": st }] };
      this.query = qStatus;

    }
  }
  setGridInfo(hasDefaultIncidentReportType = false) {

    let objList: customColDef[] = [];
    switch (this.reportType) {
      case 'AIRPORT':
      case 'NTSB':
      case 'RWS':
      case 'VPDS':
      case 'PDS':
      case 'AIDS':
      case 'ASRS':
        {
          let btnObjPageView = {
            headerName: '',  width: 55,
            pinned: true, sortable: false, filter: false, sortOrder: 0.0, type: 'number',
            cellRenderer: MatBtnCellRendererComponent,
            cellRendererParams: {
              clicked: this.onGridDetailView.bind(this),
              label: 'PAGE_VIEW',
              tooltip: 'Detail View'
            }, cellStyle: { 'overflow': 'unset', 'width': 'auto', 'padding-right': '5px' }
          }
          objList.push(btnObjPageView);
          if (this.reportType == 'AIRPORT') {
            let btnObjDiagramView = {
              headerName: '', width: 55,
              pinned: true, sortable: false, filter: false, sortOrder: 0.1, type: 'number',
              cellRenderer: MatBtnCellRendererComponent,
              cellRendererParams: {
                clicked: this.onSubReportView.bind(this),
                label: 'DIAGRAM',
                tooltip: 'Aiport Diagram View'
              }, cellStyle: { 'overflow': 'unset', 'width': 'auto', 'padding-right': '5px' }
            }

            objList.push(btnObjDiagramView);
          }
          break;
        }

      case 'INCIDENT_BY_STATUS':
      case 'INCIDENT': {

        let btnObj = {
          headerName: '', width: 85,
          pinned: true, sortable: false, filter: false, sortOrder: 0.0, type: 'number',
          cellRenderer: MatBtnCellRendererComponent,
          cellRendererParams: {
            clicked: this.onIncidentGridButtonsClick.bind(this),
            label: 'INCIDENT',
            tooltip: 'Edit View',
            role: this.role
          }, cellStyle: { 'overflow': 'unset', 'width': 'auto', 'padding-right': '5px' }
        }
        objList.push(btnObj);
        break;
      }

    }

    Object.entries(this.queryColumns).forEach(
      ([key, item]) => {
        let x = {
          field: item['column_name_json'],
          headerName: item['display_name'],
          sort: null,
          sortOrder: item['display_order'],
          type: item['data_type'],
          pinned: item['display_order'] == 1,
          width: item['display_order'] == 1 ? 150 : 0,
          filter: true,
          sortable: true,
          minWidth: 150,
          cellRenderer:
            function (params) {
              if (item['data_type'] == 'DATE') {
                return params.value ? params.value.substring(0, 10) : '';
              }
              else if (item['column_name_json'] == 'analyst_editing_stage') {
                return params.value ?
                  (params.value == 'IN PROGRESS' ? '<span class="badge-fontSize col-md-8 badge text-bg-primary">' + params.value + '</span>' :
                    (params.value == 'DONE' ? '<span class="col-md-8 badge text-bg-success badge-fontSize">' + params.value + '</span>' :
                      (params.value == 'REVIEW' ? '<span class="h4 col-md-8 badge text-bg-warning">' + params.value + '</span>' :
                        (params.value == 'UNREVIEWED' ? '<span class="h4 col-md-8 badge text-bg-danger">' + params.value + '</span>' : params.value))
                    ))
                  : '';
              }
              else
                return params.value;
            }
        }
        if (objList.indexOf(x) === -1 && item['include_in_grid']) {
          x.sort = this.reportType == 'INCIDENT' && item['column_name_json'] == 'airport_code' ? 'asc' : this.reportType !== 'INCIDENT' && item['display_order'] == 1 ? 'asc' : null,
            objList.push(x);
        }
      }
    );
    objList.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);

    this.columnDefs = objList;

    this.defaultColDef = {
      flex: 1,
      enableRowGroup: true,
      suppressColumnVirtualisation: true

    };
    this.sideBar = "columns";
    this.autoGroupColumnDef = { minWidth: 200 };

    if (hasDefaultIncidentReportType) {
      this.incidentReportColumnDefs = this.columnDefs;
      return;
    }

    if (this.reportType === 'INCIDENT_BY_STATUS')
      this.loadReportData();
  }

  headerHeightSetter() {
    var padding = 20;
    var height = this.headerHeightGetter() + padding;
    this.gridApi!.setGridOption("headerHeight", height);
    this.gridApi.resetRowHeights();
  }
  headerHeightGetter() {
    var columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

    var columnHeaderTextsArray = [];

    columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

    var clientHeights = columnHeaderTextsArray.map(
      headerText => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  }
  autoSizeAll(skipHeader = false) {
    var allColumnIds = [];
    if (this.gridApi?.getColumns()) {
      const columns = this.gridApi.getColumns();
      allColumnIds = columns.map(column => column.getColId());
      this.gridApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  }
  sizeToFit() {
    if (this.hasGridData && this.gridApi) {
      setTimeout(() => {
      
          if (this.reportType === 'INCIDENT_BY_STATUS') {
            this.gridApi.applyColumnState({
              state: [{ colId: 'date_of_incident', sort: 'desc' }],
              defaultState: { sort: null },
            });
          }
          this.gridApi.sizeColumnsToFit();
          this.autoSizeAll();
          this.setAutoHeight();
          if (this.lastPageNo !== '') {
            this.jumpToPageNo = (Number(this.lastPageNo) + 1).toString();
            this.onJumpToPageNumber();
          }
        

      }, 1);

    }
  }
  setAutoHeight() {
    //this.gridApi.setDomLayout('autoHeight'); //*** to do 7/15/2024*/
     this.gridApi.setGridOption("domLayout", "autoHeight"); 
    // auto height will get the grid to fill the height of the contents,
    // so the grid div should have no height set, the height is dynamic.
    let id = '#ag-Grid-' + this.reportType;
  
    (document.querySelector<HTMLElement>(id)! as any)!.style.height = '';
  }
  onPageSizeChanged(pageSize) {
    // this.gridApi.paginationPageSize(Number(pageSize));
   
    this.pageSize = Number(pageSize);
    this.gridApi.paginationGoToPage(this.pageSize);
  }
  onPaginationChanged() {
    //  console.log(`onPaginationPageLoaded | current page : ${Number(this.gridApi.paginationGetCurrentPage())}`);
    // Workaround for bug in events order
    if (this.gridApi!) {
      //setText('#lbLastPageFound', this.gridApi.paginationIsLastPageFound());
      //setText('#lbPageSize', this.gridApi.paginationGetPageSize());
      // we +1 to current page, as pages are zero based
      //setText('#ag-46-start-page-number', Number(this.gridApi.paginationGetCurrentPage()) );
      //setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());

    }
  }
  onJumpToPageNumber() {

    if (Number(this.jumpToPageNo) > 0) {

      this.gridApi.paginationGoToPage(Number(this.jumpToPageNo) - 1);

    }
  }

  onGridReady(params: GridReadyEvent) {

    this.gridApi = params.api;
   
    this.gridApi.closeToolPanel();
     
    this.sizeToFit();
    this.onFirstDataRendered(params);
  }

  onFirstDataRendered(params) {
  
  }
  loadReportData(hasDownLoadAll: boolean = false) {

    this.hasQueryParamter = this.query && this.query.rules ? this.query.rules.length > 0 : false;
    if (this.reportType === 'INCIDENT_BY_STATUS' && this.incidentByStatus)
      this.hasQueryParamter = true;

    if (this.hasQueryParamter || this.hasNonDataSourceReport || hasDownLoadAll) {
      this.selectedReportId = '';
      this.message = 'Loading....';
      this.hasGridData = false;
      this.totalRecords = 0;
      this.rowData = null;
      this.rowDataDetailView = [];
      this.result = {};
      this.spinnerService.show();

      let sDate = _moment(new Date(_moment(this.dateRangeReport.value.start).toDate())).format("YYYY-MM-DD");
      let eDate = _moment(new Date(_moment(this.dateRangeReport.value.end).toDate())).format("YYYY-MM-DD");

      this.reportService.getReportData(
        this.reportType === 'INCIDENT_BY_STATUS' ? this.incidentByStatus : this.reportType,
        this.sqlQuery, this.incidentQuery?.controls['keywords']?.value,
        this.incidentQuery?.controls['maxRow']?.value,
        this.merged_data_source_id,
        '',
        ''
      )
        .subscribe({
          next: (result) => {
            this.spinnerService.hide();
            let _result: any = {};
            _result = result;
            this.result = _result;
            this.hasGridData = _result.total > 0;
            if (_result.success) {
              this.alertType = 'success';
              this.rowData = _result.result;
              this.rowDataDetailView = _result.result;
              this.message = _result.total > 0 ? '' : 'No data found.';
              this.totalRecords = _result.total;

              this.sizeToFit();
              //this.showStatusMessage('Data Loaded Successfully', 'Get Report Data', 'success');
              if (hasDownLoadAll) {
                setTimeout(() => {
                  this.onDownload();
                }, 1)
              }
             
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
              this.message = 'Error while loading data. ' + _errorInfo;
              this.showStatusMessage(this.message, 'Get Report Data', 'error');

            }
          },

          error: (err: any) => {
            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            this.message = 'Error while loading data. ' + _err.message;
            this.showStatusMessage(this.message, 'Get Report Data', 'error');
            this.spinnerService.hide();
          },
          complete: () => { }
        }
        );
      

    }
  }


  getReportColumns(hasDefaultIncidentReportType = false) {
    this.resultReportColumns = {};
    let reportType = this.reportType;
    if (hasDefaultIncidentReportType)
      reportType = 'INCIDENT';// WILL USED FOR INCIDENT BY STATUS

    let _last_update_localStorage = this.localStorageService.get('asd-lst-update-day') || '';
    let keyid = 'asd-reportColumns-' + reportType;
    let _item = this.localStorageService.get(keyid) || [];

    if (Object.keys(_item).length > 0 && _last_update_localStorage === new Date().getDate()) {
      this.queryColumns = [];
      this.queryColumns = _item;
      this.resultReportColumns = _item;
      if (hasDefaultIncidentReportType) {
        this.incidentReportColumns = _item; // WILL USED FOR INCIDENT BY STATUS
        this.setGridInfo(hasDefaultIncidentReportType);
      }
      else {
        this.setSearchQueryParameterColumns();
        this.setGridInfo();
      }
    }
    else {

      this.spinnerService.show();
      this.reportService.getReportColumns(reportType)
        .subscribe({
          next: (result) => {
            this.spinnerService.hide();
            let _result: any = {};
            _result = result;

            if (_result.success) {
              this.queryColumns = [];
              this.queryColumns = _result.result;
              this.resultReportColumns = _result.result;
              if (hasDefaultIncidentReportType) {
                this.incidentReportColumns = _item; // WILL USED FOR INCIDENT BY STATUS
                this.setGridInfo(hasDefaultIncidentReportType);
              }
              else {
                this.setSearchQueryParameterColumns();
                this.setGridInfo();
                if (Object.keys(_result.result).length > 0) {
                  this.localStorageService.set(keyid, _result.result);
                  this.localStorageService.set('asd-lst-update-day', new Date().getDate());
                }
              }
              // this.showStatusMessage('Data loaded succcessfully', 'Get Report Columns', 'success');
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
              this.message = 'Error while loading data. ' + _errorInfo;

              this.showStatusMessage(this.message, 'Get Report Columns', 'error');
            }

          },

          error: (err: any) => {
            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            this.message = 'Error while loading data. ' + _err.message;
            this.showStatusMessage(this.message, 'Get Report Columns', 'error');
            this.spinnerService.hide();
          },
          complete: () => { }
        }
        );
       
    }
  }

  setSearchQueryParameterColumns() {
    let objList: Field[] = [];
    this.fieldsWithDateTypes = [];
    const group: any = {};
    group['start'] = (new UntypedFormControl());
    group['end'] = (new UntypedFormControl());
    group['reg_no'] = (new UntypedFormControl());
    Object.entries(this.queryColumns).forEach(
      ([key, item]) => {

        let x = {
          name: item['db_column_name'],
          type: item['data_type'] === 'DATE' ? 'date' : (item['data_type'] === 'NUMBER' ? 'number' : 'string'),
          operators:
            item['data_type'] === 'DATE' ? ['=', '!=', '>=', '<=', '>', '<'] : (item['data_type'] === 'NUMBER' ? ['=', '!=', '>=', '<=', '>', '<'] : (item['control'] === 'SELECT' || item['control'] === 'MULTI-SELECT' ? ['=', '!=', 'in'] : ['=', '!=', 'like'])),
          filter: true,
          sortable: true
        }
        if (objList.indexOf(x) === -1) {
          if (item['include_in_search']) {
            objList.push(x);
            group[item['db_column_name']] = (new UntypedFormControl());

          }
          if (item['data_type'] === 'DATE')
            this.fieldsWithDateTypes.push(item['db_column_name']);
        }


      }
    );

    if (this.reportType === 'INCIDENT_BY_STATUS') {
      let x = {
        name: 'analyst_editing_stage',
        type: 'string',
        operators:
          ['=', '!=', 'like'],
        filter: true,
        sortable: true
      }
      objList.push(x);
    }
    if (this.reportType === 'INCIDENT') {
      let x = {
        name: 'reg_no',
        type: 'string',
        operators:
          ['in'],
        filter: true,
        sortable: true
      }
      if (objList.indexOf(x) === -1)
        objList.push(x);

    }

    if (this.reportType === 'AIRPORT') {
      let part139status = objList.find(i => i.name == 'PART139STATUS');
      if (part139status) {
        part139status.type = 'category';
        part139status.options = [
          { name: 'Yes', value: '!= NULL' },
          { name: 'No', value: 'NULL' }
        ]
        part139status.defaultValue = 'NULL';
      }
    }
 
    const fieldMap: FieldMap = arrayToObject(objList)
    this.config = {
      fields: fieldMap
    };
   
    this.incidentQuery = this.fb.group(group);
   
  }

  onQueryChange(event: any) {

  }
  onRunQuery() {
    //this.onClearData();
    this.sqlQuery = this.basicRulesetToSQL(this.query);
    this.keyword = this.incidentQuery?.controls['keywords']?.value;
    this.gridApi.resetColumnState();
    this.lastPageNo = '';
    this.jumpToPageNo = '';
    this.loadReportData();
  }
  generateColumnsForExcel(): string[] {

    let objList: customColDef[] = [];

    Object.entries(this.queryColumns).forEach(
      ([key, item]) => {

        let x = {
          field: item['column_name_json'],
          headerName: item['display_name'],
          sortOrder: item['display_order'],
          type: item['data_type']
        }

        if (objList.indexOf(x) === -1 && item['include_in_export']) {
          objList.push(x);
        }
      }
    );
    objList.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);

    return objList.map(column => column.field);
  }
  onDownloadAll() {
    if (this.reportType == 'AIRPORT') {
      this.sqlQuery = '(1 = 1)';
      this.gridApi.resetColumnState();
      this.loadReportData(true);
    }
  }
  onDownload() {
    let d = new Date();
    this.gridApi.exportDataAsCsv({
      fileName: this.reportType + '_Data_' + (d.getMonth() + 1) + d.getDate() + d.getFullYear(),
      columnKeys: this.generateColumnsForExcel()
    })

  }
  onClearQuery() {
    this.keyword = '';
    this.result = {};
    this.hasGridData = false;
    this.hasQueryParamter = false;
    this.showSideBarQuery = false;
    this.showSideBarDetailView = false;
    this.showSideBarEditView = false;
    this.showSideBarSubReportView = false;
    this.showSideBarColumnFilterView = false;
    this.showSideBarManageQueriesView = false;
    this.currentSavedQueryId = 0;
    this.selectedReportId = '';
    this.subReportType = '';
    this.query = {
      condition: 'and',
      rules: [
      ]
    };
    this.selectedAirports = [];
    this.incidentQuery.reset();
    this.queryName = '';
    this.message = '';
    this.getQueries();


  }


  valueToSQL(value, column) {
    switch (typeof value) {
      case 'string':
        return "'" + value + "'";
      case 'boolean':
        return value ? '1' : '0';
      case 'number':
        if (isFinite(value)) return value;
    }
  }

  isDefined(value) {
    return value !== undefined;
  }
  basicRulesetToSQL(ruleset) {
    return ruleset.rules.map((rule) => {
      if (rule.rules) {
        return "(" + this.basicRulesetToSQL(rule) + ")";
      }
      var column = rule.field,
        operator, value;

      switch (rule.operator) {

        case "is null":
        case "is not null":
          operator = rule.operator;
          value = "";
          break;
        case "in":
        case "not in":
          operator = rule.operator;
          if (column == 'reg_no') {

            let inStrText = rule.value.toString();

            value = "( ( aircraft_1_registration_no = '" + inStrText + "' ) OR ( aircraft_2_registration_no = '" + inStrText + "'  ) )";

            return value;
          }
          if (column == 'aircraft_damage') {

            let inStrText = rule.value.toString();

            value = "(( CHARINDEX (UPPER('" + inStrText.toUpperCase() + "'), UPPER(LTRIM(AIRCRAFT_1_DAMAGE)))> 0 )";
            value += " OR ( CHARINDEX (UPPER('" + inStrText.toUpperCase() + "'), UPPER(LTRIM(AIRCRAFT_2_DAMAGE)))> 0 ) )";

            return value;
          }
        
          else if (column == 'contributing_factors') {
            column = 'CF_DISPLAY';
            let inStrText = rule.value.toString();


            rule.value.forEach(function (val, index) {
              if (index == 0)
                value = "( " + column + " LIKE '%" + val + "%' ) ";
              else
                value += " OR ( " + column + " LIKE '%" + val + "%' ) ";


            });
            return " ( " + value + " ) "

          }
          else if (column == 'inj_specific') {
            let inStrText = rule.value.toString();
            value = "(('ISNULL(" + inStrText.toUpperCase() + ",0)' != '0'))";
            return value;
          }
          else if (column == 'occurrence_display' || column == 'operation_type_display' || column == 'flight_phase_display'
            || column == 'location_display' || column == 'weather_display' || column == 'surface_condition_display') {

            let inStrText = rule.value.toString();

            if (inStrText.indexOf(",") !== -1) {

              value = inStrText.replaceAll(",", " = 'YES' OR ");
              value += " = 'YES'";
            }
            else

              value = "(" + inStrText.toUpperCase() + " = 'YES' )";

            return " ( " + value + " ) ";
          }
          if (Array.isArray(rule.value) && rule.value.length)

            value = "(" + rule.value.map(this.valueToSQL, column).filter(this.isDefined).join(", ") + ")";
          //return value;

          break;

        case "like":
          operator = rule.operator;
          if (rule.value.length)
            value = "'%" + rule.value + "%'"
          break;

        default:
          operator = rule.operator;
          if (column == 'PART139STATUS') {

            let inStrText = rule.value.toString();
            console.log(inStrText);
            operator = inStrText == "NULL" ? "is null": "is not null";
            value = "";
            break;
          }
          value = this.valueToSQL(rule.value, column);
          break;
      }

      if (this.isDefined(column) && this.isDefined(value) && this.isDefined(operator)) {

        return "(" + (column + " " + operator + " " + value).trim() + ")";
      }
    }).filter(this.isDefined).join(" " + ruleset.condition + " ");
  }

  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity = severity == '' || severity == 'success' ? 'success' : 'error'
    this._snackBar.open(message, severity, {
      panelClass: 'app-notification-' + severity
    });
  }
  onCloseSideBarManageQueriesView(event: any) {
    this.selectedRowIndex = -1;
    this.selectedReportId = '';
    if (!this.isRequestInProgress) {
      this.showSideBarManageQueriesView = event;
      this.getQueries(true);
    }
  }
  onCloseSideBarDetailView(event: any) {
    this.selectedRowIndex = -1;
    this.selectedReportId = '';
    if (this.showSideBarEditView && !this.showSideBarDetailView && event) {
      this.loadReportData();
    }

    this.showSideBarDetailView = false;
    this.showSideBarEditView = false;
  }
  onCloseSideBarSubReportView(event: any) {
    this.selectedRowIndex = -1;
    this.selectedReportId = '';
    this.showSideBarSubReportView = false;
  }
  onCloseSideBarColumnFilterView(event: any) {
    this.showSideBarColumnFilterView = false;
  }
  //query section

  onSubReportView(params: any) {

    this.sqlQuery = this.basicRulesetToSQL(this.query);
    this.subReportType = params.label == 'DIAGRAM' ? 'AIRPORT_DIAGRAM' : (params.label == 'LINK' ? 'NARRATIVE_INFORMATION' : params.label);
    this.showSideBarSubReportView = true;
    this.selectedRowIndex = params.rowIndex;
    this.selectedRow = params.data;

  }
  onExport(type: any) {
    this.sqlQuery = this.basicRulesetToSQL(this.query);
    this.subReportType = type;
    this.showSideBarSubReportView = true;
    this.selectedRowIndex = 0;
    this.selectedRow = [];
  }
  onCustomizeDownload() {
    this.sqlQuery = this.basicRulesetToSQL(this.query);

    this.showSideBarColumnFilterView = true;
  }
  onIncidentGridButtonsClick(params: any) {
    //this.showSideBarSubReportView = false;
    //this.showSideBarDetailView = false;
    //this.showSideBarEditView = false;
    this.sqlQuery = this.basicRulesetToSQL(this.query);
    this.lastPageNo = this.gridApi.paginationGetCurrentPage()?.toString();

    if (params.params.label == 'INCIDENT' || params.params.label == 'INCIDENT_BY_STATUS') {
     
      if (params.type == 'PAGE_EDIT' || params.type == 'PAGE_VIEW') {
      
        this.selectedRowIndex = params.params.node.rowIndex;
        this.selectedRow = params.params.data;
        this.selectedReportId = params.params.data.id;
        if (params.type == 'PAGE_VIEW') {
          this.showSideBarDetailView = true;
          this.showSideBarEditView = true;
        }
        if (params.type == 'PAGE_EDIT') {
          this.showSideBarDetailView = false;
          this.showSideBarEditView = true;

        }

      }
      else {
        this.subReportType = params.type == 'DIAGRAM' ? 'AIRPORT_DIAGRAM' : (params.type == 'LINK' ? 'NARRATIVE_INFORMATION' : params.type);

        this.showSideBarSubReportView = true;
        this.selectedRowIndex = params.params.rowIndex;
        this.selectedRow = params.params.data;

      }

    }
  }

  onGridDetailView(params: any) {
    //detail view to show side bar
    this.showSideBarDetailView = true;
    this.showSideBarEditView = false;
    this.selectedRowIndex = params.node.rowIndex;

  }
  onGridEditView(params: any) {
    this.showSideBarDetailView = false;
    this.selectedRowIndex = params.rowIndex;
    this.selectedRow = params.data;
    this.showSideBarEditView = true;
    if (params.label == 'PAGE_VIEW') {
      this.showSideBarDetailView = true;
    }

  }
  
  onShowSideBarQuery(event: Event) {
    event.stopPropagation();
    this.savedQuerySelect.close();
    this.showSideBarManageQueriesView = true;
  }

  onOpenManageQueryDialog(event: Event, id: number = 0, action: string = '') {
    event.stopPropagation();
    this.newSavedQueryId = 0;
    const query: QueryViewModel = ({} as any) as QueryViewModel;

    query.id = id;
    if (id > 0) {
      Object.entries(this.queries).forEach(([key, val]) => {
        if (val['id'] == id) {

          query.uiQuery = JSON.parse(val['uiquery']);
          query.name = val['name'];
          query.description = val['description'];
          query.isPublic = val['ispublic'];
          query.sqlQuery = val['sqlquery'];
          query.createdBy = val['createdby'];
          return;
        }
      });
    }
    let _position = this.showSideBarQuery ? '30%' : '';

    const dialogRef = this.dialog.open(ManageQueryDialogComponent,
      {
        width: '400px', 
        disableClose: true, position: { right: _position },
        data: { query: query, reportType: this.reportType, userName: this.userName, action: action, uiquery: this.query }
      });
    
    dialogRef.afterClosed().subscribe(result => {
      this.getQueries(true);
      if (result > 0)
        this.currentSavedQueryId = result;
    });

  }

  getQueries(isCalledAfterModalClose = false) {

    this.isQueryOwner = false;
    this.hasQueryGridData = false;
    this.isRequestInProgress = true;
    this.queries = [];
    this.queryRowData =null;
    // this.spinnerService.show();
    this.queryService.getAll(this.userName, this.reportType)
      .subscribe({
        next: (result) => {
          // this.spinnerService.hide();
          let _result: any = {};
          _result = result;
          this.isRequestInProgress = false;
          if (_result.success) {
            this.queries = _result.result;
            this.queryRowData = _result.result;
            this.hasQueryGridData = _result.total > 0;

            setTimeout(() => {

              if (isCalledAfterModalClose && this.currentSavedQueryId > 0) {
                this.onSavedQueryChange(this.currentSavedQueryId);
              }
               
            }, 1)


          }
          else {
            this.isRequestInProgress = false;
            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Error while loading data. ' + _errorInfo;
            this.showStatusMessage(this.message, 'Get Queries', 'error');

          }
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while loading data. ' + _err.message;
          this.showStatusMessage(this.message, 'Get Queries', 'error');
          //this.spinnerService.hide();
        },
        complete: () => { }
      }
      );
   
  }

  onSavedQueryChange($event) {
    this.queryName = '';
    let _id = $event;

    if (_id > 0) {
      Object.entries(this.queries).forEach(([key, val]) => {
        if (val['id'] == _id) {
          this.query = JSON.parse(val['uiquery']);
          this.queryName = val['name'];
          this.isQueryOwner = val['createdby'] == this.userName;
          if (this.reportType !== 'INCIDENT')
            this.onRunQuery();
          else
            this.setIncidentUiSearchParameters();
          return;
        }
      });

    }
    else {
      this.onClearQuery();
    }
  }
  // end query section
  //incident report start


  loadAirportNameWithCode() {
    //this.spinnerService.show();
    let _last_update_airports = this.localStorageService.get('asd-lst-update-day') || '';
    let _item = this.localStorageService.get('asd-airports') || [];

    if (Object.keys(_item).length > 0 && _last_update_airports === new Date().getDate()) {

      this.airports = _item;
      //this.selectedAirports = _item;
      this.hasLoadedAirports = true;
    }
    else {

      this.incidentLookupService.getAirportNameWithCodeList()
        .subscribe({
          next: (result) => {
            //  this.spinnerService.hide();
            let _result: any = {};
            _result = result;

            if (_result.success) {
              this.hasLoadedAirports = true;
              this.airports = _result.result;
             // this.selectedAirports = _result.result;
              if (Object.keys(_result.result).length > 0) {
                this.localStorageService.set('asd-airports', _result.result);
                this.localStorageService.set('asd-lst-update-day', new Date().getDate());
              }
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
              this.message = 'Error while loading data. ' + _errorInfo;

              this.showStatusMessage(this.message, 'Get Airport Name with Code ', 'error');
            }

          },

          error: (err: any) => {
            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            this.message = 'Error while loading data. ' + _err.message;
            this.showStatusMessage(this.message, 'Get Airport Name with Code ', 'error');
            //this.spinnerService.hide();
          },
          complete: () => { }
        }
        );
       
    }
  }

  loadRegions() {
    let _last_update_airports = this.localStorageService.get('asd-lst-update-day') || '';
    let _item = this.localStorageService.get('asd-regions') || [];

    if (Object.keys(_item).length > 0 && _last_update_airports === new Date().getDate()) {

      this.regions = _item;
      this.filteredRegions = _item;
      this.hasLoadedRegions = true;
    }
    else {

      this.hasLoadedRegions = false;
      //this.spinnerService.show();
      this.incidentLookupService.getRegions()
        .subscribe({
          next: (result) => {
            //  this.spinnerService.hide();
            let _result: any = {};
            _result = result;

            if (_result.success) {
              this.regions = _result.result;
              this.filteredRegions = _result.result;
              this.hasLoadedRegions = true;
              if (Object.keys(_result.result).length > 0) {
                this.localStorageService.set('asd-regions', _result.result);
                this.localStorageService.set('asd-lst-update-day', new Date().getDate());
              }
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
              this.message = 'Error while loading data. ' + _errorInfo;

              this.showStatusMessage(this.message, 'Get Regions ', 'error');
            }
          },

          error: (err: any) => {
            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            this.message = 'Error while loading data. ' + _err.message;
            this.showStatusMessage(this.message, 'Get Regions ', 'error');
            //this.spinnerService.hide();
          },
          complete: () => { }
        }
        );
      
    }
  }

  loadSurfaceTypeConditions() {
    let _last_update_data = this.localStorageService.get('asd-lst-update-day') || '';
    let _item = this.localStorageService.get('asd-surfaceTypeConditions') || [];

    if (Object.keys(_item).length > 0 && _last_update_data === new Date().getDate()) {

      this.surfaceTypeConditions = _item;
      this.filteredSurfaceTypeConditions = _item;
      this.hasLoadedSurfaceTypeConditions = true;
    }
    else {

      this.hasLoadedSurfaceTypeConditions = false;
      //this.spinnerService.show();
      this.incidentLookupService.GetSurfaceTypeConditions()
        .subscribe({
          next: (result) => {
            //  this.spinnerService.hide();
            let _result: any = {};
            _result = result;

            if (_result.success) {
              this.surfaceTypeConditions = _result.result;
              this.filteredSurfaceTypeConditions = _result.result;
              this.hasLoadedSurfaceTypeConditions = true;
              if (Object.keys(_result.result).length > 0) {
                this.localStorageService.set('asd-surfaceTypeConditions', _result.result);
                this.localStorageService.set('asd-lst-update-day', new Date().getDate());
              }
            }
            else {

              this.alertType = 'danger';
              let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
              this.message = 'Error while loading data. ' + _errorInfo;

              this.showStatusMessage(this.message, 'Get SurfaceTypeConditions ', 'error');
            }
          },

          error: (err: any) => {
            let _err: any = {};
            _err = err;
            this.alertType = 'danger';
            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
            this.message = 'Error while loading data. ' + _err.message;
            this.showStatusMessage(this.message, 'Get SurfaceTypeConditions ', 'error');
            //this.spinnerService.hide();
          },
          complete: () => { }
        }
        );

    }
  }
  dateRangeEvent(control: string, event: MatDatepickerInputEvent<Date>) {
    // console.log(`dateRangeEvent control = ${control} event: ${event.value}`);
    if (event.value) {
      let dtd = _moment(new Date(_moment(event.value).toDate())).format("YYYY-MM-DD");
      this.createIncidentSearchDynamicQuery(control, dtd);
    }
    else
      this.createIncidentSearchDynamicQuery(control, null);

  }
  selectChangeEvent(control: string, event: any) {
    if (event.value) {
      let dtd = _moment(new Date(_moment(event.value).toDate())).format("YYYY-MM-DD");
      this.createIncidentSearchDynamicQuery(control, dtd);
    }
  }
  createIncidentSearchDynamicQuery(control: any, value: any, type: any = '') {

    //existing control then remove the entry - 12/16 PP #6384

    //console.log(`0 control = ${control} val: ${value} index: ${(this.query.rules.findIndex(f => (f.field == control))) }`);
    if (this.query.rules.findIndex(f => (f.field == control)) !== -1) {
      let _index = this.query.rules.findIndex(f => (f.field == control));
      this.query.rules.splice(_index, 1);

      // console.log(`0.0 control = ${control} val: ${value} index: ${(this.query.rules.findIndex(f => (f.field == control)))}`);
    }
    switch (control) {
      case 'start':
      case 'end': {
        let _control = 'date_of_incident';
        let _operator = (control === 'end') ? "<=" : ">=";
        //console.log(`date 0 control = ${control} val: ${value} index: ${(this.query.rules.findIndex(f => (f.field == _control && f.operator == _operator )))}`);
        if (this.query.rules.findIndex(f => (f.field == _control && f.operator == _operator)) !== -1) {
          let _index = this.query.rules.findIndex(f => (f.field == _control && f.operator == _operator));
          this.query.rules.splice(_index, 1);

          // console.log(`0.0 control = ${control} val: ${value} index: ${(this.query.rules.findIndex(f => (f.field == control)))}`);
        }

        let startDateIndex = control === 'start' && this.query && this.query.rules && this.query.rules.findIndex(f => (f.field == "date_of_incident" && f.operator == ">=")) || null;
        let endDateIndex = control == 'end' && this.query && this.query.rules && this.query.rules.findIndex(f => (f.field == "date_of_incident" && f.operator == "<=")) || null;
        //console.log(`createIncidentSearchDynamicQuery Index => ${startDateIndex}: ${endDateIndex}`);

        if (control === 'end') {
          // console.log(`end date ${this.query.rules.findIndex(f => (f.field == "date_of_incident" && f.operator == ">="))}`);
          if (startDateIndex == null && endDateIndex == -1 && value) {//add new end date

            this.query.rules.push({ "field": "date_of_incident", "operator": "<=", "value": value });
          }
          else if (startDateIndex == null) {
            //update start based on index
            if (endDateIndex > -1)
              this.query.rules[endDateIndex].value = value;
          }
        }

        if (control === 'start') {
          //  console.log(`start date ${this.query.rules.findIndex(f => (f.field == "date_of_incident" && f.operator == ">="))}`);
          if (startDateIndex == null)
            startDateIndex = this.query.rules.findIndex(f => (f.field == "date_of_incident" && f.operator == ">="));
          if (startDateIndex == -1 && endDateIndex == null && value) {//add new start date

            this.query.rules.push({ "field": "date_of_incident", "operator": ">=", "value": value });

            this.onFocusOutEvent(event, type)
          }
          else if (endDateIndex == null) {
            //update start based on index
            if (startDateIndex > -1)
              this.query.rules[startDateIndex].value = value;
          }
        }
        break;
      }

      default: {
        //  console.log(`${control} ${value} ${this.query.rules.findIndex(f => (f.field == control))}`)
        let operator = "in";
        if (control == 'modify_user' || control == 'merged_data_source_id')
          operator = "=";
        if (this.query.rules.findIndex(f => (f.field == control)) == -1) {

          //  console.log(`1 control = ${control} val: ${value} len: ${Object.keys(value).length}`);
          if (value && (value !== null || value !== '' || Object.keys(value).length != 0)) {
            if (operator === "in" && Object.keys(value).length > 0) {
              // console.log(`1.0 control = ${control} op: ${operator} val: ${value} len: ${Object.keys(value).length}`);
              this.query.rules.push({ "field": control, "operator": operator, "value": value });
            }
            else if (operator !== "in") {
              // console.log(`1.1 control = ${control} op: ${operator} val: ${value} len: ${Object.keys(value).length}`);
              this.query.rules.push({ "field": control, "operator": operator, "value": value });
            }
          }

        }
        else {

          // console.log(`2 control = ${control} val: ${value}`);
          let currentIndex = this.query.rules.findIndex(f => (f.field == control));
          if (value && (value !== null || value !== '' || Object.keys(value).length != 0))
            this.query.rules[currentIndex].value = value;
        }
        break;
      }


    }
    //console.log(this.query.rules.filter(f => (f.field == 'date_of_incident' && f.operator == (control == 'start' ? '>=' : '<='))));

  }

  setIncidentUiSearchParameters() {
    this.incidentQuery.reset();
    if (this.query.rules) {
      let rules = this.query.rules;

      let startDate = null, endDate = null;
      Object.entries(rules).forEach(
        ([key, item]) => {
         // console.log(`${key} ${item.field} ${item.operator} ${item.value}`);
          switch (item.field) {
            case "date_of_incident": {
              const dt = new Date(_moment(item.value).toDate());

              if (item.operator == '>=')
                this.incidentQuery.controls.start?.setValue(dt);
              else
                this.incidentQuery.controls.end?.setValue(dt);
              break;
            }

            default: {
            
              this.incidentQuery.controls[item.field]?.patchValue(item.value);

              //#7667 -- PP 6/6/2024
              if (item.field == 'airport_code' ) {
                const currentAirports = this.incidentQuery.controls[item.field]?.value;
                
                if (currentAirports) {
                  const selectedItem = this.airports.filter(item => currentAirports.includes(item.code));
                  this.selectedAirports = selectedItem;
                }
              }
              break;
            }

          }



        }
      );

    }
    this.onRunQuery();
  }
  onFocusOutEvent(event: any, type: any = '', control: any = '') {
    if (control !== '') {
      setTimeout(() => {
        // console.log(`onFocusOutEvent => ${control}: ${this.incidentQuery.controls[control].value}`);
        this.createIncidentSearchDynamicQuery(control, this.incidentQuery.controls[control].value);
      }, 1)

    }
    //console.log(`onFocusOutEvent => ${event}: ${type}  ${event.target.value} ${event.target.getAttribute('formControlName')}`);
    
    ////if (event.target.value)
    ////this.incidentSearchQuery[type].push(event.target.value);
    ////this.incidentSearchQuery.total = (this.incidentSearchQuery?.dateTime.length +
    ////  this.incidentSearchQuery?.airport?.length +
    ////  this.incidentSearchQuery?.location?.length +
    ////  this.incidentSearchQuery?.occurrences?.length +
    ////  this.incidentSearchQuery?.opsFlightPhase?.length +
    ////  this.incidentSearchQuery?.outcome?.length +
    ////  this.incidentSearchQuery?.miscInfomration?.length);


  }
  onSelectChange(event: any) {
    //#76677 -- PP - 6/6/2024
    if (event.control) {
      const selectedItem = event.selectedItems.map(item => item.code);
      this.incidentQuery.controls[event.control]?.patchValue(selectedItem);
      this.selectedAirports = event.selectedItems;
      setTimeout(() => {
        this.createIncidentSearchDynamicQuery(event.control, this.incidentQuery.controls[event.control].value);
      }, 1)

    }
  }
  
  onClearHistoryInput() {
    this.merged_data_source_id = '';
    this.message = '';
  }
  //incident report end
  getYears() {
    let c = new Date();

    let years = Array.from(new Array(c.getFullYear() + 1), (x, i) => i);
    return Array.from(years).reverse().filter(i => i > 1900);
  }

}
function setText(selector: string, text: any) {
  (document.querySelector(selector) as any).innerHTML = text;
}
const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.name] = item
    return obj
  }, {})
export interface customColDef extends ColDef {
  sortOrder: any;
  type: any


}
