import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { MatBtnCellRendererComponent } from '../agGrid/mat-btn-cell-renderer/mat-btn-cell-renderer.component';
import { customColDef } from '../report/report.component';
import { FAALocalStorageService } from '../services/localStorage.service';
import { MergeReportService } from '../services/mergeReport.service';
import { ReportService } from '../services/report.service';
import * as _moment from 'moment';
import { CofimrationDialogComponent } from '../shared/cofimration-dialog/cofimration-dialog.component';
import { GridReadyEvent } from 'ag-grid-community';
import { FormControl, FormGroup } from '@angular/forms';
import { SpinnerService } from '../services/spinner.service';

@Component({
    selector: 'app-merge-report',
    templateUrl: './merge-report.component.html',
    styleUrls: ['./merge-report.component.css'],
    standalone: false
})
export class MergeReportComponent implements OnInit {
  title = '';
  reportType = 'POT_DUP';
  hasGridData = false;
  showMergedSideBarView = false;
  @ViewChild('agGrid') agGrid: AgGridAngular;
  
  defaultColDef;
  columnDefs;
  sideBar;
  gridApi;
  autoGroupColumnDef;
  userName: any = '';
  role: any = '';

  result = {};
  alertType = 'success';
  message = '';

  totalRecords = 0;
  rowData: any[] | null;
  rowDataDetailView: [];
  queryRowData: Observable<any[]>;
  reportColumns = [];
  sqlQuery = '';
  merged_data_source_id = '';
  dateRangeReport = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  mergeParams = {};
  originalReportNo = '';
  duplicateReportNo = '';
  selectedMergedHistoryId = 0;
  private _snackBar = inject(MatSnackBar);
  constructor(private activatedRoute: ActivatedRoute, private authorizeService: AuthorizeService,
    public mergeReportService: MergeReportService, private localStorageService: FAALocalStorageService,
    private http: HttpClient, public dialog: MatDialog,
    private spinnerService: SpinnerService) {
    this.userName = this.authorizeService.getUserName();
    this.role = this.authorizeService.getUserRole();
  }

  ngOnInit(): void {
    this.reportType = this.activatedRoute.snapshot.queryParams.type;
    this.setInitalData();
  }

  setInitalData() {
    switch (this.reportType) {
      case 'MERGED_HISTORY':
        this.title = 'Merged History Report';
        this.columnDefs = [
          {
            headerName: '', width: 1,
            pinned: true, sortable: false, filter: false, sortOrder: 0.0, type: 'number',
            cellRenderer: MatBtnCellRendererComponent,
            cellRendererParams: {
              clicked: this.onUnMergedGridButtonsClick.bind(this),
              label: 'UNMERGE',
              tooltip: 'Un-Merge a Report',
              role: this.role
            }, cellStyle: { 'overflow': 'unset', 'width': '2px', 'padding-right': '1px' }
          },
          { field: 'report_id_merged_to', headerName: 'Merged To', sortable: true, filter: true, width: 70, pinned: true },
          { field: 'report_id_merged_from', headerName: 'Merged From', sortable: true, filter: true, width: 70, pinned: true },
          { field: 'created_date', headerName: 'Create Date', sortable: false, filter: true, width: 25 },
          { field: 'created_by', headerName: 'Created By', sortable: false, filter: true, width: 70 },
          {
            field: 'unmerged', headerName: 'Unmerged', sortable: false, filter: true, width: 15
          },
          { field: 'unmerged_date', headerName: 'Unmerged Date', sortable: false, filter: true, width: 15 },
          { field: 'unmerged_by', headerName: 'Unmerged By', sortable: false, filter: true, width: 50 },
          { field: 'unmerged_comment', headerName: 'Unmerged Comment', sortable: false, filter: true, width: 50 }
        ];
        break;
      default:
        this.title = 'Potential Duplicates Report';
        this.columnDefs = [
          {
            headerName: '', width: 1,
            pinned: true, sortable: false, filter: false, sortOrder: 0.0, type: 'number',
            cellRenderer: MatBtnCellRendererComponent,
            cellRendererParams: {
              clicked: this.onMergeGridButtonsClick.bind(this),
              label: 'MERGE',
              tooltip: 'Merge a Report View',
              role: this.role
            }, cellStyle: { 'overflow': 'unset', 'width': '2px', 'padding-right': '1px' }
          },
          { field: 'originalreportno', headerName: 'Orig. Incident Id', sortable: true, filter: true, width: 30, pinned: true },
          { field: 'duplicatereportno', headerName: 'Dup. Incident Id', sortable: true, filter: true, width: 30, pinned: true },
          { field: 'originalincidentdate', headerName: 'Orig. Date', sortable: true, filter: true, width: 5 },
          { field: 'duplicateincidentdate', headerName: 'Dup. Date', sortable: true, filter: true, width: 5 },
          { field: 'originalincidenttime', headerName: 'Orig. Time', sortable: true, filter: true, width: 3 },
          { field: 'duplicateincidenttime', headerName: 'Dup. Time', sortable: true, filter: true, width: 3 },
          { field: 'originalregistration', headerName: 'Orig. Reg.', sortable: true, filter: true, width: 5 },
          { field: 'duplicateregistration', headerName: 'Dup. Reg.', sortable: true, filter: true, width: 5 },
          { field: 'originalstatus', headerName: 'Orig. Status', sortable: true, filter: true, width: 8 },
          { field: 'duplicatestatus', headerName: 'Dup. Status', sortable: true, filter: true, width: 8 }
        ];
        break;
    }
    this.loadReportData();
  }

  onMergeGridButtonsClick({ data: { originalreportno, duplicatereportno } }: any) {
    this.mergeParams = { data: { originalreportno, duplicatereportno } };
    this.originalReportNo = originalreportno;
    this.duplicateReportNo = duplicatereportno;
    this.showMergedSideBarView = true;
  }
  onUnMergedGridButtonsClick({ data: { originalreportno, duplicatereportno, merge_history_id } }: any) {
    this.selectedMergedHistoryId = merge_history_id;
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CofimrationDialogComponent, {
      width: '400px',
      disableClose: true,
      data: { action: 'Un-Merge' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.status == 'YES')
        this.unMergeReport(result.comment);
    });
  }
  onCloseSideBarMergeView(event: any) {
    this.showMergedSideBarView = false;
    this.loadReportData();
  }

  onGridReady(params: GridReadyEvent) {

    this.gridApi = params.api;
    
    this.gridApi.closeToolPanel();

    this.sizeToFit();
    this.onFirstDataRendered(params);
  }
  onFirstDataRendered(params) {

  }
  loadReportData() {
    this.message = 'Loading....';
    this.hasGridData = false;
    this.totalRecords = 0;
    this.selectedMergedHistoryId = 0;
    this.rowData =null;
    this.rowDataDetailView = [];
    this.result = {};
    this.spinnerService.show();

    let service;
    let serviceName;
    if (this.reportType === 'MERGED_HISTORY') {
      service = this.mergeReportService.getMergedHistory(this.merged_data_source_id);
      serviceName = 'Get Merged History Data';
    } else {
   
      let startDate = this.dateRangeReport.value.start ? _moment(new Date(_moment(this.dateRangeReport.value.start).toDate())).format("YYYY-MM-DD") : '';
      let endDate = this.dateRangeReport.value.end ? _moment(new Date(_moment(this.dateRangeReport.value.end).toDate())).format("YYYY-MM-DD") : '';
     
      service = this.mergeReportService.getPotentialDuplicates(this.merged_data_source_id, startDate, endDate);
      serviceName = 'Get Potential Duplicates Data';
    }

    service.subscribe(
      result => {
        this.spinnerService.hide();
        let _result: any = {};
        _result = result;
        this.result = _result;
        this.hasGridData = _result.total > 0;
        if (_result.success) {
          this.alertType = 'success';
          this.rowData = _result.result;
          this.rowDataDetailView = _result.result;
          this.message = _result.total > 0 ? '' : 'No data found.';
          this.totalRecords = _result.total;
          this.sizeToFit();
          //this.showStatusMessage('Data Loaded Successfully', 'Get Report Data', 'success');
        } else {
          this.alertType = 'danger';
          let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
          this.message = 'Error while loading data. ' + _errorInfo;
          this.showStatusMessage(this.message, serviceName, 'error');
        }
      },
      err => {
        let _err: any = {};
        _err = err;
        this.alertType = 'danger';
        let _errorInfo = '';
        err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? ' ' + _err.error.ModelState[''][0] : '';
        this.message = 'Error while loading data. ' + _err.errormessage;
        this.showStatusMessage(this.message, serviceName, 'error');
        this.spinnerService.hide();
      }
    );
  }

  unMergeReport(comment: any = '') {
    this.message = '';
    this.spinnerService.show();

    this.mergeReportService.UnmergeReport(this.selectedMergedHistoryId, comment)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;
          if (_result.success) {
            this.alertType = 'success';
            this.message = 'Report is un-mergedSuccessfully!';
            this.showStatusMessage(this.message, 'Merge Or Not Duplicate', 'success');
            this.loadReportData();
          }
          else {
            this.alertType = 'danger';
            this.message = 'Error while processing the request. ' + _result.errormessage;
            this.showStatusMessage(this.message, 'Merge Or Not Duplicate', 'error');
          }


        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while processing the request.' + _err.errormessage;
          this.showStatusMessage(this.message, 'Merge Or NotDuplicate', 'error');
          this.spinnerService.hide();
        },
        complete: () => { }
      }
      );
     

  }
  onClearInput() {
    this.message = this.merged_data_source_id = this.originalReportNo = this.duplicateReportNo = '';
    this.dateRangeReport = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
    this.showMergedSideBarView = false;
    this.loadReportData();
  }
  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity = severity == '' || severity == 'success' ? 'success' : 'error'
    this._snackBar.open(message, severity, {
      panelClass: 'app-notification-' + severity
    });
  }

  generateColumnsForExcel(): string[] {

    let objList: customColDef[] = [];

    Object.entries(this.reportColumns).forEach(
      ([key, item]) => {
        if (item['include_in_export'] && item['display_name'] !== '') {
          let x = {
            field: item['column_name_json'],
            headerName: item['display_name'],
            sortOrder: item['display_order'],
            type: item['data_type']
          }
          if (objList.indexOf(x) === -1)
            objList.push(x);
        }
      }
    );
    objList.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);

    return objList.map(column => column.field);
  }


  onDownload() {
    let d = new Date();
    let fileName = this.reportType + '_Data_' + (d.getMonth() + 1) + d.getDate() + d.getFullYear();
    let columnKeys = this.generateColumnsForExcel();
    this.gridApi.exportDataAsCsv({ fileName, columnKeys });
  }

  setAutoHeight() {
    this.gridApi.setGridOption("domLayout", "autoHeight"); 
    // auto height will get the grid to fill the height of the contents,
    // so the grid div should have no height set, the height is dynamic.
    (document.querySelector<HTMLElement>('#myGrid')! as any).style.height = '';
  }

  autoSizeAll(skipHeader = false) {
    var allColumnIds = [];
    if (this.gridApi?.getColumns()) {
      const columns = this.gridApi.getColumns();
      allColumnIds = columns.map(column => column.getColId());
      this.gridApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  }
  sizeToFit() {
    if (this.hasGridData) {
      setTimeout(() => {
        if (this.gridApi) {
          this.gridApi.sizeColumnsToFit();
          this.setAutoHeight();
          this.autoSizeAll();
        }

      }, 1);

    }
  }
 
}
