
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedService } from './services/shared.service';

import { AgGridAngular } from 'ag-grid-angular';
/*import { AgGridAngular } from 'ag-grid-angular'; // AG Grid Component*/
import { UserComponent } from './admin/user/user.component';
import { MatCheckboxComponent } from './agGrid/mat-checkbox/mat-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule, MatSnackBarConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSideToggleComponent } from './agGrid/mat-side-toggle/mat-side-toggle.component';
import { MatBtnCellRendererComponent } from './agGrid/mat-btn-cell-renderer/mat-btn-cell-renderer.component';
import { UserDialog } from './admin/user/user-dialog.component';
import { LoginDialog } from '../api-authorization/login-menu/login-dialog.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';


/*import { QueryBuilderModule } from 'angular2-query-builder';*/
import { ReportComponent } from './report/report.component';
import { ReportService } from './services/report.service';
import { AuthorizedAccessDialog } from './home/authorizeAccess-dialog.component';
import { QueryService } from './services/query.service';

import { ManageQueryDialogComponent } from './manage-query-dialog/manage-query-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DetailViewComponent } from './report/sidebar/detail-view/detail-view.component';
import { QueryComponent } from './report/sidebar/query/query.component';

import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { EditViewComponent } from './report/sidebar/edit-view/edit-view.component';
import { SubReportComponent } from './report/sidebar/subReport/sub-report.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ColumnFilterViewComponent } from './report/sidebar/column-filter-view/column-filter-view.component';
import { ReportAnalystDeliverableComponent } from './report-analyst-deliverable/report-analyst-deliverable.component';
import { IncidentService } from './services/incident.service';
import { MergeReportComponent } from './merge-report/merge-report.component';
import { MergeReportService } from './services/mergeReport.service';
import { MergeComponent } from './merge-report/sidebar/merge/merge.component';
import { IncidentViewComponent } from './merge-report/sidebar/incident-view/incident-view.component';
import { NarrativeInformationComponent } from './report/narrative-information/narrative-information.component';
import { CofimrationDialogComponent } from './shared/cofimration-dialog/cofimration-dialog.component';
import { ReportStatisticsComponent } from './report-statistics/report-statistics.component';
import { ReportVeerComponent } from './report-veer/report-veer.component';
import { ReportPvNTotalComponent } from './report-pvn-total/report-pvn-total.component';
import { ReportConseqOccurComponent } from './report-conseq-occur/report-conseq-occur.component';
import { ReportLoCComponent } from './report-LoC/report-LoC.component';
import { FAALocalStorageService } from './services/localStorage.service';

import { QueryBuilderModule } from './shared/query-builder/query-builder.module';
import { AnalystDeliverableReportService } from './services/analystDeliverableReport.service';
import { ReportIngestStatisticsComponent } from './report-ingest-statistics/report-ingest-statistics.component';
import { AgCharts } from 'ag-charts-angular';

import { SpinnerService } from './services/spinner.service';
import { ReportConseqHighestRankedOccurrComponent } from './report-conseq-highest-ranked-occurr/report-conseq-highest-ranked-occurr.component';
import { FaaMultiselectAutocompleteComponent } from './shared/faa-multiselect-autocomplete/faa-multiselect-autocomplete.component';
import { BiReportComponent } from './report/bi-report/bi-report.component';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent, FooterComponent, HeaderComponent,
    CounterComponent,
    FetchDataComponent,
    LoginDialog, UserDialog, AuthorizedAccessDialog,
    UserComponent,
    MatCheckboxComponent,
    MatSideToggleComponent,
    MatBtnCellRendererComponent,
    ReportComponent,
    ManageQueryDialogComponent,
    DetailViewComponent,
    QueryComponent,
    SubReportComponent,
    EditViewComponent,
    ColumnFilterViewComponent,
    ReportAnalystDeliverableComponent,
    MergeReportComponent,
    MergeComponent,
    IncidentViewComponent,
    NarrativeInformationComponent,
    CofimrationDialogComponent,
    ReportStatisticsComponent,
    ReportVeerComponent,
    ReportPvNTotalComponent,
    ReportConseqOccurComponent,
    ReportLoCComponent,
    ReportIngestStatisticsComponent,
    ReportConseqHighestRankedOccurrComponent,
    FaaMultiselectAutocompleteComponent,
    BiReportComponent
  ],
  imports: [
    BrowserModule,
    AgGridAngular, AgCharts,
    QueryBuilderModule,
    HttpClientModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,
    ApiAuthorizationModule,

    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule, MatRadioModule,
    MatTooltipModule, MatIconModule, MatSlideToggleModule, MatCheckboxModule, MatSnackBarModule,
    MatCardModule, MatMenuModule, MatSidenavModule,
    MatExpansionModule, MatBadgeModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatChipsModule, MatPaginatorModule,
 
    RouterModule.forRoot([

      { path: 'home', component: HomeComponent },
      { path: 'report', component: ReportComponent, canActivate: [AuthorizeGuard] },
      { path: 'subReport', component: SubReportComponent, canActivate: [AuthorizeGuard] },
      { path: 'potentialDup', component: MergeReportComponent, canActivate: [AuthorizeGuard] },
      { path: 'mergeReport', component: MergeComponent, canActivate: [AuthorizeGuard] },
      { path: 'analystReport', component: ReportAnalystDeliverableComponent, canActivate: [AuthorizeGuard] },
      { path: 'highestRankedOccurrences', component: ReportConseqHighestRankedOccurrComponent, canActivate: [AuthorizeGuard] },
      { path: 'manageUser', component: UserComponent, canActivate: [AuthorizeGuard] },
      { path: 'reportStatistics', component: ReportStatisticsComponent, canActivate: [AuthorizeGuard] },
      { path: 'reportVeer', component: ReportVeerComponent, canActivate: [AuthorizeGuard] },
      { path: 'reportPvNTotal', component: ReportPvNTotalComponent, canActivate: [AuthorizeGuard] },
      { path: 'reportConseqOccur', component: ReportConseqOccurComponent, canActivate: [AuthorizeGuard] },
      { path: 'reportLoC', component: ReportLoCComponent, canActivate: [AuthorizeGuard] },
      { path: 'ingestStatisticsReport', component: ReportIngestStatisticsComponent, canActivate: [AuthorizeGuard] },
      { path: 'bi-report', component: BiReportComponent, canActivate: [AuthorizeGuard] },
      
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },

      { path: '404', component: NotFoundComponent },
      { path: '**', redirectTo: '/404' }

    ], { onSameUrlNavigation: 'reload' }),
    BrowserAnimationsModule
    
  ],
  providers: [SharedService, ReportService, QueryService, IncidentService, MergeReportService, AnalystDeliverableReportService,
    FAALocalStorageService, SpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 5000
      }
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
