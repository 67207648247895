<body>
  <app-header></app-header>
  <!--<app-nav-menu></app-nav-menu>-->
  <div class="container-fluid">
    <app-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true"><p style="color: white"> Processing ... </p></app-spinner>
    <router-outlet></router-outlet>
  </div>
  <app-footer class="faa-w-footer"></app-footer>

</body>
