import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { AgCharts } from 'ag-charts-angular';
import { AgChartOptions, AgLineSeriesOptions } from 'ag-charts-community';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from 'ag-grid-community';

import { MatSnackBar } from '@angular/material/snack-bar';

import { IngestChartModel } from '../model/shared';
import { AnalystDeliverableReportService } from '../services/analystDeliverableReport.service';
import { SpinnerService } from '../services/spinner.service';


@Component({
    selector: 'app-report-ingest-statistics',
    templateUrl: './report-ingest-statistics.component.html',
    styleUrls: ['./report-ingest-statistics.component.css'],
    standalone: false
})

export class ReportIngestStatisticsComponent implements OnInit {
  title: string = 'Ingest Cycle Statistics';
  private _snackBar = inject(MatSnackBar);
  incidentStatisticResult: any = {};
  ingestStatisticResult: any = {};
  ingestTypes: any = [];
  hasIncidentStatisticResult: boolean = false;
  hasIngestStatisticResult: boolean = false;
  alertType: string = 'success';
  message: string = '';
  selectedType: string = 'MDS';
  @ViewChild(AgCharts)
  public agChart!: AgCharts;
  public optionsIncidentStatistics: AgChartOptions;
  public optionsIngestStatistics: AgChartOptions;
  private gridApi!: GridApi<any>;
  public rowData!: any[];
  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'type' },
    { field: 'count' },
    { field: 'ingest_date' },

  ];
  constructor(public analystDeliverableReportService: AnalystDeliverableReportService,
    private spinnerService: SpinnerService) {
    this.optionsIngestStatistics = {
      axes: [
        {
          type: "category",
          position: "bottom",
          title: {
                text: 'INGEST_DATE',//this.selectedType,
              },
        },
        {
          type: "number",
          position: "left",
          title: {
            text: 'COUNT',
          },
         
        },
      ],
   
      data: this.getIngestStatisticData(),
      series: [
       
        {
          type: "line", xKey: "ingest_date", xName: "INGEST_DATE",
          yKey: "count", yName: "COUNT",
        } as AgLineSeriesOptions,
      ],
     
    };

    this.optionsIncidentStatistics = {
      data: this.getIncidentStatisticData(),
      series: [
        {
          type: "donut",
          calloutLabelKey: "status",
          angleKey: "count",
          innerRadiusRatio: 0.2,
       
          calloutLabel: {
            formatter: ({ datum, calloutLabelKey, angleKey }) => {
              const ct = this.incidentStatisticResult.result.find(s => s.status == 'TOTAL');
              if (ct) {
                const total = ct.count;
                return `${datum[calloutLabelKey]}: ${datum[angleKey]} (${((datum[angleKey] / total) * 100).toFixed()}%) `;
              }
              else {
                return `${datum[calloutLabelKey]}: ${datum[angleKey]}   `;
              }
            },
          }
        },
      ],
    };


  }


  ngOnInit(): void {
    this.loadIngestStatisticsData();
    this.loadAnalystDeliveralbeReport();
  }
  setOptionsIncidentStatistics() {

  }
  loadIngestStatisticsData() {

    this.analystDeliverableReportService.getIngestStatistics()
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;
          this.ingestStatisticResult = _result;

          if (_result.success) {
            this.hasIngestStatisticResult = true;
            this.rowData = this.ingestStatisticResult.result;
            this.getIngestStatisticData();
            this.generateTypeSelect();
          }
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Error while loading data. ' + _errorInfo;
            this.showStatusMessage(this.message, 'Get Report Data', 'error');

          }
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while loading data. ' + _err.message;
          this.showStatusMessage(this.message, 'Get Report Data', 'error');
          this.spinnerService.hide();
        },
        complete: () => { }
      }
      );

  }
  loadAnalystDeliveralbeReport() {

    let reportType = 'STATISTICS_COUNT';

    this.analystDeliverableReportService.getAnalystDeliverableReport(reportType, '', '', null, '', '', '')
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;
          this.incidentStatisticResult = _result;
          const filterdTotaData = _result.result.filter(d => d.status !== 'TOTAL'); //#7583 PP 4/19/20204
          this.incidentStatisticResult.result = filterdTotaData;
          if (_result.success) {
            this.hasIncidentStatisticResult = true;
            const options = { ...this.optionsIncidentStatistics };
            options.data = this.getIncidentStatisticData();
            this.optionsIncidentStatistics = options;

          }
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Error while loading data. ' + _errorInfo;
            this.showStatusMessage(this.message, 'Get Report Data', 'error');

          }
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while loading data. ' + _err.message;
          this.showStatusMessage(this.message, 'Get Report Data', 'error');
          this.spinnerService.hide();
        },
        complete: () => { }
      }
      );

  }
  setIncidentStatistic(type: string = '') {
    if (type && this.hasIncidentStatisticResult) {
      let ct = this.incidentStatisticResult.result.find(s => s.status == type);
      return ct ? ct.count : '';
    }
  }
  setIngestStatistic(type: string = '') {
    if (type && this.hasIngestStatisticResult) {
      let ct = this.ingestStatisticResult.result.find(s => s.type == type);
      return ct ? ct.count : '';
    }
  }
  getIncidentStatisticData() {
    let data = this.hasIncidentStatisticResult ? this.incidentStatisticResult.result : [];
    return data;
  }
  getIngestStatisticData() {

    let data = this.hasIngestStatisticResult ? this.ingestStatisticResult.result.filter(t => t.type == this.selectedType) : [];
    const options = { ...this.optionsIngestStatistics };
    options.data = data;
    this.optionsIngestStatistics = options;
   
    return data;
    
  }
  generateTypeSelect() {
    let key = 'type';
    const arrayUniqueByKey = [...new Map(this.ingestStatisticResult.result.map(item =>
      [item[key], item])).values()];
    this.ingestTypes = arrayUniqueByKey;
  }
  
  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }
  onDownload() {
    
    let d = new Date();
    this.gridApi.exportDataAsCsv({
      fileName:  'Ingest_Cycle_Data_' + (d.getMonth() + 1) + d.getDate() + d.getFullYear(),
     
    })
  }
  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity = severity == '' || severity == 'success' ? 'success' : 'error'
    this._snackBar.open(message, severity, {
      panelClass: 'app-notification-' + severity
    });
  }

}
