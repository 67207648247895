import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';

import { Observable } from 'rxjs';
import { ReportService } from '../../../services/report.service';
import { DomSanitizer } from '@angular/platform-browser';

import { MatSnackBar } from '@angular/material/snack-bar';

import { MatAccordion } from '@angular/material/expansion';
import { MatBtnCellRendererComponent } from '../../../agGrid/mat-btn-cell-renderer/mat-btn-cell-renderer.component';
import { SharedService } from '../../../services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
    selector: 'app-sidebar-sub-report-view',
    templateUrl: './sub-report.component.html',
    styleUrls: ['./sub-report.component.css'],
    standalone: false
})
export class SubReportComponent implements OnInit {
  airportDiagramDefaultDirectory = ''; //default dir for diagrams to get always latest year
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() type = '';
  @Input() showSideBarSubReportView;
  @Input() selectedRow;
  @Input() selectedRowIndex;
  @Input() sqlQuery;
  @Input() keyword = '';
  @Input() baseZIndex = "10000";
  @Input() hasNewTabView = false;
  private _snackBar = inject(MatSnackBar);
  originalSqlQuery = '';
  @Output() onCloseSideBarSubReportView = new EventEmitter();
  public columnDefs!: ColDef[] ;
  public gridApi!: GridApi;
 
  public rowData: any[] | null 
  isReadOnly = false;
  hasGridData = false;
  result: any = {};
  alertType = 'success';
  message = '';
  airportCode = '';
  airportName = '';
  selectedFileBLOB;
  title = '';
  showDownload = false;
  hasOtherDataSourceType = false;
  reportColumns = [];
  hotSpots = {};
  hasHotSpots = false;
  
  id = '';
  subReportSqlQuery = '';
  incidentDate = '';
  currentTime = new Date().getTime();
  constructor(public reportService: ReportService, 
    public sharedService: SharedService, private actRoute: ActivatedRoute,
    public elementRef: ElementRef,
    private spinnerService: SpinnerService, public sanitizer: DomSanitizer, private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get('../../assets/appData.json?v=' + this.currentTime).subscribe(
      success => {
        let _result: any = {};
        _result = success;
        if (_result.airportDiagramDefaultDirectory)
          this.airportDiagramDefaultDirectory = _result.airportDiagramDefaultDirectory;

      });
    let id =(this.actRoute.snapshot.queryParamMap.get('id'))
    
    this.hasNewTabView = this.actRoute.snapshot.queryParamMap.get('type') ? this.actRoute.snapshot.queryParamMap.get('type').toString().length > 0 : false;
    if (this.hasNewTabView) {
      if (this.actRoute.snapshot.queryParamMap.get('id')) {
        this.id = this.actRoute.snapshot.queryParamMap.get('id').toString();
        this.type = this.actRoute.snapshot.queryParamMap.get('type') ? this.actRoute.snapshot.queryParamMap.get('type').toString() : '';
        if (this.actRoute.snapshot.queryParamMap.get('airport'))
          this.airportName = this.actRoute.snapshot.queryParamMap.get('airport').toString();
        if (this.actRoute.snapshot.queryParamMap.get('incidentDate'))
          this.incidentDate =  this.actRoute.snapshot.queryParamMap.get('incidentDate').toString();
      }
      else
      this.hasNewTabView = false;
     
    }

    if (this.showSideBarSubReportView || this.hasNewTabView)
      this.onInit();
  }
  ngOnChanges(changes: SimpleChanges) {
    
    if (this.showSideBarSubReportView || this.hasNewTabView)
    this.onInit();
  }
  onInit() {
    this.subReportSqlQuery = this.sqlQuery;
    this.hasGridData = false;
    this.showDownload = false;
    this.hasOtherDataSourceType = false;
    this.hotSpots = {};
    this.hasHotSpots = false;
    this.isReadOnly = false;
    if (this.showSideBarSubReportView || this.hasNewTabView) {
      this.getReportColumns();
    }
    if (this.type == 'AIRPORT_DIAGRAM') {
      this.title = 'Airport Diagram List';
      this.columnDefs = [
        {
          headerName: '', width: 55,
          pinned: true, sortable: false, filter: false, type: 'number',
          cellRenderer: MatBtnCellRendererComponent,
          cellRendererParams: {
            clicked: this.onGridDownloadMap.bind(this),
            label: 'MAP',
            tooltip: 'Download Map Diagram'
          }, cellStyle: { 'overflow': 'unset', 'width': 'auto', 'padding-right': '5px' }
        },
        { field: 'from_date', sort: 'asc', headerName: 'Earliest Date', sortable: true, filter: true },
        { field: 'to_date', headerName: 'Latest Date', sortable: true, filter: true },
        { field: 'table_id', hide: true },
        { field: 'file_name', hide: true }
      ];
     
      let airportCode = this.hasNewTabView ? this.id : this.selectedRow['locid'];
      let airportName = this.hasNewTabView ? this.airportName : this.selectedRow['facilityname'];
      let incidentDate = this.hasNewTabView ? this.incidentDate : '';
      
      if ((airportCode && airportCode !== '') || this.hasNewTabView) {
        this.airportCode = airportCode;
        this.airportName = airportName ? airportName : '';
        if (this.hasNewTabView && incidentDate !== '') {
         
          this.loadAirportDiagramByInicdentDate();
          return;
        }
         // this.subReportSqlQuery = `(apt_ident = '${airportCode}' AND from_date <= TO_DATE( ${incidentDate} , ''YYYY-MM-DD'' ) and  to_date >= TO_DATE( ${incidentDate} , ''YYYY-MM-DD'' ) )`;
          else 
        this.subReportSqlQuery = `(apt_ident = '${airportCode}' )`;
        let sqlQueryHotSport = `(airport_code = '${airportCode}' )`;
        this.loadHotSpotData(sqlQueryHotSport);
      }

    }
    else if (this.type == 'EXPORT_NARRATIVE') {
      this.title = 'Incident Report Narrative';
      this.columnDefs = [

        { field: 'merged_data_source_id', headerName: 'Incident ID', sortable: true, filter: true },
        { field: 'asrs_rpt1_narrative', headerName: 'ASRS Report 1 Narrative', sortable: true, filter: true },
        { field: 'asrs_rpt2_narrative', headerName: 'ASRS Report 2 Narrative', sortable: true, filter: true },
        { field: 'pds_corr_rmk_text', headerName: 'PDS Corrected Narrative', sortable: true, filter: true },
        { field: 'rws_rwy_sfty_narrative', headerName: 'RWS Runway Safety Narrative', sortable: true, filter: true },
        { field: 'vpd_rmk_text_inves', headerName: 'VPD Investigation', sortable: true, filter: true },
        { field: 'ntsb_rprt_narr_cause_text', headerName: 'NTSB Cause', sortable: true, filter: true },
        { field: 'ntsb_rprt_narr_final_text', headerName: 'NTSB Final', sortable: true, filter: true },
        { field: 'aids_remarks_text', headerName: 'AIDS Remarks Text', sortable: true, filter: true }
      ];
    }
    else if (this.type == 'AIRCRAFT_INFORMATION') {
      this.title = 'Aircraft Information';
      this.columnDefs = [
        { field: 'merged_data_source_id', headerName: 'Incident ID', sortable: true, filter: true },
        { field: 'runway_length', headerName: 'Runway Length', sortable: true, filter: true },
        { field: 'runway_width', headerName: 'Runway Width', sortable: true, filter: true },
        { field: 'runway_excursions_veeroff_distance', headerName: 'Runway Excursions Veeroff Distance', sortable: true, filter: true },
        { field: 'runway_excursions_overrun_distance', headerName: 'Runway Excursions Overrun Distance', sortable: true, filter: true },
        { field: 'taxiway_excursions_veeroff_distance', headerName: 'Taxiway Excursions Veeroff Distance', sortable: true, filter: true },
        { field: 'aircraft_1_make', headerName: 'Aircraft 1 Make', sortable: true, filter: true },
        { field: 'aircraft_1_model', headerName: 'Aircraft 1 Model', sortable: true, filter: true },
        { field: 'aircraft_1_series', headerName: 'Aircraft 1 Series', sortable: true, filter: true },
        { field: 'aircraft_1_registration_no', headerName: 'Aircraft 1 Registration No', sortable: true, filter: true },
        { field: 'aircraft_2_make', headerName: 'Aircraft 2 Make', sortable: true, filter: true },
        { field: 'aircraft_2_model', headerName: 'Aircraft 2 Model', sortable: true, filter: true },
        { field: 'aircraft_2_series', headerName: 'Aircraft 2 Series', sortable: true, filter: true },
        { field: 'aircraft_2_registration_no', headerName: 'Aircraft 2 Registration', sortable: true, filter: true },
        { field: 'notes', headerName: 'Notes', sortable: true, filter: true }
      ];
    }
    else if (this.type == 'NARRATIVE_INFORMATION')// Narrative Link
    {
      this.title = 'Narrative Information';
      let merged_data_source_id = this.selectedRow['merged_data_source_id'];
      this.subReportSqlQuery = `(merged_data_source_id = '${merged_data_source_id}' collate binary_ci )`;
    }
    else if (this.type == 'NTSB_LINK') {
      this.title = 'NTSB Detail';

      let id = this.hasNewTabView ? this.id : this.selectedRow['ntsb_fk'];
      this.subReportSqlQuery = `(event_id = '${id}' collate binary_ci )`;
    }
    else if (this.type == 'RWS_LINK') {
      this.title = 'RWS Detail';

      let id = this.hasNewTabView ? this.id : this.selectedRow['rws_fk'];
      this.subReportSqlQuery = `(REPORT_# = '${id}' collate binary_ci )`;

    }
    else if (this.type == 'VPDS_LINK') {
      this.title = 'VPDS Detail';
      let id = this.hasNewTabView ? this.id : this.selectedRow['vpds_fk'];
      this.subReportSqlQuery = `(REPORT_# = '${id}' collate binary_ci )`;
    }
    else if (this.type == 'PDS_LINK') {
      this.title = 'PDS Detail';
      let id = this.hasNewTabView ? this.id : this.selectedRow['pds_fk'];
      this.subReportSqlQuery = `(RPRT_NBR = '${id}' collate binary_ci )`;
    }
    else if (this.type == 'AIDS_LINK') {
      this.title = 'AIDS Detail';
      let id = this.hasNewTabView ? this.id : this.selectedRow['aid_fk'];
      this.subReportSqlQuery = `(RPRT_NBR = '${id}' collate binary_ci )`;
    }
    else if (this.type == 'ASRS_LINK') {
      this.title = 'ASRS Detail';
      let id = this.hasNewTabView ? this.id : this.selectedRow['asrs_fk'];
      this.subReportSqlQuery = `(ACN = '${id}' collate binary_ci )`;
    }
    if (this.showSideBarSubReportView || this.hasNewTabView) {
      this.showDownload = this.type == 'AIRPORT_DIAGRAM' || this.type == 'EXPORT_NARRATIVE' || this.type == 'AIRCRAFT_INFORMATION';
      this.hasOtherDataSourceType = this.type == 'NTSB_LINK' || this.type == 'RWS_LINK' || this.type == 'VPDS_LINK' || this.type == 'PDS_LINK' || this.type == 'AIDS_LINK' || this.type == 'ASRS_LINK';

      this.loadData();
    }
  }
  onGridDownloadMap(params: any) {
    //console.log(params);
    let hasAirportDiagramDefaultDir = this.airportDiagramDefaultDirectory && this.airportDiagramDefaultDirectory !== '';
   
    let earliestDateYear = hasAirportDiagramDefaultDir ? this.airportDiagramDefaultDirectory : new Date(params.data.from_date).getFullYear().toString().substr(-2);
    let fileName = hasAirportDiagramDefaultDir ? params.data.pdf_name : params.data.file_name;
    let currentTime = new Date().getTime();
    let url = `assets/maps/${earliestDateYear}/${fileName}?v=${currentTime}`;//'22/00285AD.pdf';
   // console.log(url);
    window.open(url, "Diagram");
    let fileExists = false;
    this.sharedService.fileExists(url)
      .subscribe({
        next: (result) => {
          fileExists = result;
          this.downloadMapFile(url, fileExists);
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          fileExists = (err?.status == 200);
          this.downloadMapFile(url, fileExists);
        },
        complete: () => { }
      }
      );
    
  }
  
  downloadMapFile(url, fileExists) {
    if (fileExists)
      window.open(url, "Diagram");
    else {
      this.message = 'File does not exist.';
      this.showStatusMessage(this.message, 'Download Diagram', 'error');
    }
  }
  onHide() {
  
    this.hasGridData = false;
    this.showDownload = false;
    this.hasOtherDataSourceType = false;
    this.baseZIndex = "10000";
    this.hotSpots = {};
    this.hasHotSpots = false;
    this.hasNewTabView = false;
    this.subReportSqlQuery = '';
    this.showSideBarSubReportView = false;
    this.onCloseSideBarSubReportView.emit(this.showSideBarSubReportView);
    setTimeout(() => {
      if (this.accordion) {
        this.accordion.closeAll();
      }
    
    }, 0);
  }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setGridOption("rowData", this.rowData);
   
  }
  
  
  loadData() {
    this.message = '';
    this.hasGridData = false;
    this.rowData =  null;
   
    this.result = {};
    this.spinnerService.show();
   
    this.reportService.getReportData(this.type, this.subReportSqlQuery, this.keyword)
    .subscribe({
      next: (result) => {
        this.spinnerService.hide();

        let _result: any = {};
        _result = result;
        this.result = _result;
        this.hasGridData = _result.total > 0;
        if (_result.success) {
          this.alertType = 'success';
          this.rowData = _result.result;
          this.message = _result.total > 0 ? '' : 'No data found.';
          setTimeout(() => {

            if (this.hasOtherDataSourceType)
              this.isReadOnly = true;
          }, 0);
        }
        else {

          this.alertType = 'danger';
          let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
          this.message = 'Error while loading data. ' + _errorInfo;
          this.showStatusMessage(this.message, 'Get Sub-Report Data', 'error');
        }

      },

      error: (err: any) => {
        let _err: any = {};
        _err = err;
        this.alertType = 'danger';
        let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
        this.message = 'Error while loading data. ' + _err.errormessage;
        this.showStatusMessage(this.message, 'Get Sub-Report Data', 'error');
        this.spinnerService.hide();
      },
    complete: () => { }
    }
    );
     

  }
  loadAirportDiagramByInicdentDate() {
    this.message = '';
    this.hasGridData = false;
    this.rowData = null;
  
    this.reportService.getAirportDiagramByIncidentDate(this.airportCode, this.incidentDate)
      .subscribe({
        next: (result) => {
          let _result: any = {};
          _result = result;
          this.hasGridData = _result.total > 0;
          if (_result.success) {
            this.alertType = 'success';
            this.rowData = _result.result;
            this.message = _result.total > 0 ? '' : 'No data found.';
          }
        },
        error: (err: any) => {
          let _err: any = {};
          _err = err; },
      });
     
  }
  loadHotSpotData (sqlQuery) {
    let type = 'AIRPORT_HOTSPOT';
    this.hotSpots = {};
    this.hasHotSpots = false;
    this.reportService.getReportData(type, sqlQuery)
      .subscribe({
        next: (result) => {
          let _result: any = {};
          _result = result;
          this.hotSpots = _result.result;

          this.hasHotSpots = _result.success && _result.total > 0;
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
        },
        complete: () => { }
      }
      );
    
  }
  onDownload() {
    let d = new Date();
    if (this.type == 'AIRPORT_DIAGRAM') {
      this.gridApi.exportDataAsCsv({
        fileName: this.airportCode + '_' + this.type + '_' + (d.getMonth() + 1) + d.getDate() + d.getFullYear(),
        prependContent: this.prependContent(),
      });
    }
    else {
        this.gridApi.exportDataAsCsv({
          fileName: this.type + '_' + (d.getMonth() + 1) + d.getDate() + d.getFullYear(),
         // prependContent: this.prependContent(),
        });
     
    }


  }

  getReportColumns() {
    let type = this.type;
   
    switch (this.type) {
      case 'NTSB_LINK':
        type = 'NTSB';
        break;
      case 'RWS_LINK':
        type = 'RWS';
        break;
    }
  
    this.spinnerService.show();
    this.reportService.getReportColumns(type)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            this.reportColumns = [];
            this.reportColumns = _result.result;
          }
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Error while loading data. ' + _errorInfo;

            this.showStatusMessage(this.message, 'Get Sub Report Columns', 'error');
          }

        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
        
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while loading data. ' + _err.errorMessage;
          this.showStatusMessage(this.message, 'Get Sub Report Columns', 'error');
          this.spinnerService.hide();
        },
        complete: () => { }
      }
      );
  
  }

  showValueWithData(data: any) {
  
    return data && data != '' && data.toString().length > 0;
  }
  prependContent(){
    return "Airport Diagram List for " + this.airportCode + " - " + this.airportName;
  }
  showStatusMessage(message: any, action: any = '', severity: any = '') {
    severity = severity == '' || severity == 'success' ? 'success' : 'error'
    this._snackBar.open(message, severity, {
      panelClass: 'app-notification-' + severity
    });
  }
  showAllDigram() {
    this.incidentDate = '';
    this.onInit();
  }
  getPageWidth(): Object{
    
    let width = this.baseZIndex && this.baseZIndex == '20000' ? { width: '85%'} : { width: '80%' };
    return width;
}
  getBsClass(field: string) {
    let defaultBsClass = 3;
    Object.entries(this.reportColumns).forEach(([key, val]) => {
     
      if (val['column_name_json'] == field) {

        defaultBsClass = val['bs_class'];
        
        if (defaultBsClass && defaultBsClass > 0)
          return 'col-md-' + defaultBsClass;
      }
    });
    return 'col-md-' + defaultBsClass;
  }
  fileChangeEvent(fileInput: any) {

    if (fileInput.target.files && fileInput.target.files[0]) {

      const reader = new FileReader();
      reader.onload = (e: any) => {

        let blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
        let url = window.URL.createObjectURL(blob);

        this.selectedFileBLOB = this.sanitizer.bypassSecurityTrustUrl(url);

      };
      reader.readAsDataURL(fileInput.target.files[0]);

    }
  }
}
