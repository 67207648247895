<ng-template [ngIf]="showSideBarManageQueriesView">
  <div class="faa-sidenav-container" style="width:80%" [baseZIndex]="10000">

    <mat-sidenav-container class="faa-sidenav-body-container table-responsive"
                           [style.marginTop.px]="10">
      <mat-sidenav #sidenav [mode]="'over'" [position]="'end'" fixedTopGap="0">
      </mat-sidenav>

      <mat-sidenav-content class="faa-sidenav-content">
        <div [(visible)]="showSideBarManageQueriesView">

          <ng-template [ngIf]="true">
            <div class="container">
              <div class="row row-cols-2">
                <div class="col h2">Manage Queries</div>
                <div class="col text-end">
                  <button mat-mini-fab color="primary" (click)="onHide()" matTooltip="Close window" aria-label="Close">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>

              </div>
            </div>

          </ng-template>
          <div class="card focus-in">
            <div class="card-body">

              <div class="card-body card-body-usda-table">

                <div class="table-responsive" [hidden]="!hasGridData">

                  <ag-grid-angular style="width: auto; height: 530px;"
                                   class="ag-theme-alpine faa-airport-diagram-grid"
                                   [rowData]="rowData"
                                   [columnDefs]="columnDefs"
                                   [paginationPageSize]="10"
                                   [pagination]="true">
                  </ag-grid-angular>
                </div>

                <div class="" [hidden]="hasGridData">
                  No data found.
                </div>
              </div>


            </div>
          </div>
        </div>

      </mat-sidenav-content>

    </mat-sidenav-container>
  </div>
</ng-template>
