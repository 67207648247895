<ng-template [ngIf]="showSideBarEditView">

  <div class="faa-sidenav-container" style="width:89%" [baseZIndex]="10000">
    <mat-sidenav-container class="faa-sidenav-body-container table-responsive"
                           [style.marginTop.px]="10">
      <mat-sidenav #sidenav mode="over" postion="end">
      </mat-sidenav>

      <mat-sidenav-content class="faa-sidenav-content">
        <div [(visible)]="showSideBarEditView" >
          <ng-template [ngIf]="true">
            <div class="container">
              <div class="row row-cols-3">
                <span class="col h2" *ngIf="!showSideBarDetailView">Edit </span>
                <span class="col h2" *ngIf="showSideBarDetailView"> Detail </span>
                <div class="col text-center">
                  <span class="table-responsive " [hidden]="!hasQueryGridData">
                    <mat-paginator [length]="totalRecords" (page)="onPageChange($event)"
                                   [pageSize]="1"
                                   [hidePageSize]="true"
                                   [showFirstLastButtons]="true"
                                   [pageIndex]="first"
                                   aria-label="Select page">
                    </mat-paginator>

                  </span>
                </div>
                <div class="col text-end">
                  <button mat-mini-fab color="primary" (click)="onHide()" matTooltip="Close window" aria-label="Close">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>

              </div>
            </div>

          </ng-template>
          <div class="card1">


            <div class="card">

              <div class="card-header primary-header" #scrollToTop>
                <div class="row text-ce">
                  <div class="col-sm-6">
                    <span *ngIf="selectedRow">Incident ID: <b>{{selectedRow?.id}}</b> </span><b>
                      <span class="analyst_editing_stage_display" style="padding:5px; min-width:100px;">
                        <span class="badge-fontSize badge bg-primary" *ngIf="analyst_editing_stage === 'IN PROGRESS'">  {{analyst_editing_stage}} </span>
                        <span class="badge bg-success badge-fontSize" style="min-width:100px;" *ngIf="analyst_editing_stage === 'DONE'"> {{analyst_editing_stage}} </span>
                        <span class="badge bg-warning" style="min-width:100px;" *ngIf="analyst_editing_stage === 'REVIEW'"> {{analyst_editing_stage}} </span>
                        <span class="badge bg-danger" style="min-width:100px;" *ngIf="analyst_editing_stage === 'UNREVIEWED'"> {{analyst_editing_stage}} </span>

                      </span>
                      <span *ngIf="incidentSearchQuery?.total > 0 " matBadge="{{incidentSearchQuery?.total }}" matBadgeColor="warn" matTooltip="Total filter count">&nbsp;&nbsp;</span>
                    </b>

                  </div>
                  <div class="col-sm-6  text-end" *ngIf="selectedRow">

                    <a mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px"
                       (click)="openSubReportTab('NTSB_LINK', selectedRow?.ntsb_fk)"
                       *ngIf="selectedRow && selectedRow?.ntsb_icon == 'Y'">NTSB</a>
                    <a mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px"
                       (click)="openSubReportTab('RWS_LINK', selectedRow?.rws_fk)"
                       *ngIf="selectedRow && selectedRow?.rws_icon == 'Y'">RWS</a>
                    <a mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px"
                       (click)="openSubReportTab('VPDS_LINK', selectedRow?.vpds_fk)"
                       *ngIf="selectedRow && selectedRow?.vpds_icon == 'Y'">VPDS</a>
                    <a mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px"
                       (click)="openSubReportTab('PDS_LINK', selectedRow?.pds_fk)"
                       *ngIf="selectedRow && selectedRow?.pds_icon == 'Y'">PDS</a>
                    <a mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px"
                       (click)="openSubReportTab('AIDS_LINK', selectedRow?.aid_fk)"
                       *ngIf="selectedRow && selectedRow?.aid_icon == 'Y'">AIDS</a>
                    <a mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px"
                       (click)="openSubReportTab('ASRS_LINK', selectedRow?.asrs_fk)"
                       *ngIf="selectedRow && selectedRow?.asrs_icon == 'Y'">ASRS</a>
                    <a mat-raised-button class="btn btn-outline-primary faa-btn-right-space-5px"
                       (click)="openSubReportTab('AIRPORT_DIAGRAM', selectedRow?.airport_code, selectedRow?.airport_name )"
                       *ngIf="selectedRow && selectedRow?.airport_diagram_exists	 == 'YES'">Airport Diagram</a>&nbsp;

                    <button mat-mini-fab color="primary" (click)="toggleExpandedAll()" [matTooltip]="(allExpanded ? 'Collapse All' : 'Expand All')">
                      @if (allExpanded)
                      {
                      <span>
                        <mat-icon class="mat-icon-2x">arrow_drop_down</mat-icon>
                      </span>
                      }
                      @else
                      {
                      <span>
                        <mat-icon class="mat-icon-2x">arrow_drop_up</mat-icon>
                      </span>
                      }
                    </button>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="table-responsive " [hidden]="!hasQueryGridData">

                  <div class="form detail-form" *ngIf="incidentReportForm">
                    <!--<span *ngIf="incidentReportForm">{{incidentReportForm.value | json}}</span>-->


                    <div class="card-body incident-search-form" [formGroup]="incidentReportForm">

                      <mat-accordion class="incident-report--headers-align" multi *ngIf="hasLoadedInicidentSearchLookupDataForSideBar" [hidden]="!expanded">

                        <mat-expansion-panel [expanded]="allExpanded">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Date & Time  <span *ngIf="incidentSearchQuery?.dateTime?.length > 0" matBadge="{{incidentSearchQuery?.dateTime?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp; </span>
                            </mat-panel-title>

                          </mat-expansion-panel-header>

                          <div class="form-row row">
                            <mat-form-field appearance="fill" class="col-md-4">
                              <mat-label>Incident Date</mat-label>
                              <input matInput value="" formControlName="date_of_incident" (focusout)="onFocusOutEvent($event, 'dateTime', 'date_of_incident')">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-md-4">
                              <mat-label>Event Time</mat-label>
                              <input matInput value="" formControlName="event_time" (focusout)="onFocusOutEvent($event, 'dateTime', 'event_time')">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-4">
                              <mat-label>Local Time Category</mat-label>
                              <mat-select formControlName="local_time_category">
                                <!--<mat-option>
                                  <lib-mat-select-search [list]="localTimes"
                                                         [searchProperties]="['code', 'name']"
                                                         (filtered)="filteredLocalTimes = $event">
                                  </lib-mat-select-search>

                                </mat-option>-->

                                <mat-option *ngFor="let item of localTimes" [value]="item.name" (focusout)="onFocusOutEvent($event, 'dateTime', 'local_time_category')">{{item.name}}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <div appearance="fill" class="col-md-12">
                              <label class="checkbox-radio-group-label">Time Of Day</label>
                              <mat-radio-group aria-labelledby="checkbox-radio-group-label"
                                               class="checkbox-radio-group"
                                               formControlName="ambient_light_conditions">
                                <mat-radio-button class="checkbox-radio-button" color="primary" *ngFor="let item of timeOfDay" [value]="item.code">
                                  {{item.code}}
                                </mat-radio-button>
                              </mat-radio-group>

                            </div>
                          </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="allExpanded">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Airport Information  <span *ngIf="incidentSearchQuery?.airport?.length > 0" matBadge="{{incidentSearchQuery?.airport?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="form-row row">
                            <mat-form-field appearance="fill" class="col-md-1">
                              <mat-label>Code</mat-label>
                              <input matInput formControlName="airport_code" />
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-md-1">
                              <mat-label>ICAO</mat-label>
                              <input matInput formControlName="icao_code" />
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-md-5">
                              <mat-label>Airport Name</mat-label>
                              <input matInput formControlName="airport_name" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>FAA Region</mat-label>
                              <input matInput formControlName="region_name" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-2">
                              <mat-label>GA Asset Role</mat-label>
                              <input matInput formControlName="ga_asset_role" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Airport Use</mat-label>
                              <input matInput formControlName="airport_use" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>
                                Airport Ownership
                              </mat-label>
                              <input matInput formControlName="airport_ownership" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>14 CFR Part 139</mat-label>
                              <input matInput formControlName="airport_part_139" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Towered Airport</mat-label>
                              <input matInput formControlName="towered_airport" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>NPIAS Hub Size</mat-label>
                              <input matInput formControlName="npias_hub_size" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>NPIAS Service Level</mat-label>
                              <input matInput formControlName="npias_service_level" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>CORE 30</mat-label>
                              <input matInput formControlName="core_30" />

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>OEP 35</mat-label>
                              <input matInput formControlName="oep_35" />

                            </mat-form-field>
                          </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="allExpanded">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Location, Weather & Surface Condition  <span *ngIf="incidentSearchQuery?.location?.length > 0" matBadge="{{incidentSearchQuery?.location?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="form-row row">

                            <div appearance="fill" class="col-md-12">
                              <label class="checkbox-radio-group-label">Location</label>
                              <div class="form-row row">
                                <div *ngFor="let item of locations" class="col-md-3">
                                  <div class="form-check-faa">
                                    <mat-checkbox name="locations" value="item.name" [value]="item.name" class="mat-checkbox-inline"
                                                  id="item.name" [checked]="isChecked('location', '_location_display', item.name)" 
                                                  (change)="onSelectChangeEvent($event, 'location', '_location_display', item.name)">{{item.name}}</mat-checkbox>
                                   
                                  </div>
                                </div>
                              </div>

                            </div>

                            <input [hidden]="true" matInput formControlName="runway_id" (focusout)="onFocusOutEvent($event, 'location', 'incident_runway')">
                            <input [hidden]="true" matInput formControlName="surface_type_condition_id">
                            <input [hidden]="true" matInput formControlName="incident_runway" (focusout)="onFocusOutEvent($event, 'location', 'incident_runway')">
                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Incident Runway/Taxiway</mat-label>
                              <input type="text"
                                     placeholder="Pick one"
                                     aria-label="Runway"
                                     matInput
                                     formControlName="__incident_runway" (focusout)="onFocusOutEvent($event, 'location', '__incident_runway')"
                                     [matAutocomplete]="auto">
                              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onRunwayChange($event)">
                                <mat-option *ngFor="let option of filteredRunways" [value]="option">
                                  {{option.runway}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-md-2">
                              <mat-label>Intersection</mat-label>
                              <input matInput formControlName="intersection" (focusout)="onFocusOutEvent($event, 'location', 'intersection')">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-md-2" *ngIf="hasRunwayInLocation">
                              <mat-label>Runway Length (ft)</mat-label>
                              <input matInput formControlName="runway_length" (focusout)="onFocusOutEvent($event, 'location', 'runway_length')">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-md-2" *ngIf="hasRunwayInLocation">
                              <mat-label>Runway Width (ft)</mat-label>
                              <input matInput formControlName="runway_width" (focusout)="onFocusOutEvent($event, 'location', 'runway_width')">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-md-3" *ngIf="hasRunwayInLocation">
                              <mat-label>Surface Condition</mat-label>
                              <input matInput formControlName="surface_type_condition">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>Location Summary</mat-label>
                              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="location_display"></textarea>

                            </mat-form-field>


                            <div appearance="fill" class="col-md-12">

                              <label class="checkbox-radio-group-label">Weather</label>
                              <mat-radio-group aria-labelledby="checkbox-radio-group-label"
                                               class="checkbox-radio-group"
                                               formControlName="vmc_imc">
                                <mat-radio-button class="checkbox-radio-button" color="primary" *ngFor="let item of weathers" [value]="item.code">
                                  {{item.code}}
                                </mat-radio-button>
                              </mat-radio-group>

                            </div>
                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>Metar</mat-label>
                              <input matInput formControlName="metar">
                            </mat-form-field>

                            <div appearance="fill" class="col-md-12">
                              <label class="checkbox-radio-group-label">Weather Phenomena - Precipitation</label>
                              <div class="form-row row">
                                <div *ngFor="let item of precipitations" class="col-md-3">
                                  <div class="form-check-faa">
                                    <mat-checkbox name="precipitations" value="item.name" [value]="item.name" class="mat-checkbox-inline"
                                                  id="item.name" [checked]="isChecked('location', '_weather_display', item.name)"
                                                  (change)="onSelectChangeEvent($event, 'location', '_weather_display', item.name)">{{item.name}}</mat-checkbox>
                                 
                                  </div>
                                </div>
                              </div>

                            </div>

                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>Weather Summary</mat-label>
                              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="weather_display"></textarea>
                            </mat-form-field>


                            <div appearance="fill" class="col-md-12">

                              <label class="checkbox-radio-group-label">Surface Condition</label>
                              <div class="form-row row">
                                <div *ngFor="let item of surfaceConditions" class="col-md-3">
                                  <div class="form-check-faa">
                                    <mat-checkbox name="surfaceConditions" value="item.name" [value]="item.name" class="mat-checkbox-inline"
                                                  id="item.name" [checked]="isChecked('location', '_surface_condition_display', item.name)"
                                                  (change)="onSelectChangeEvent($event, 'location', '_surface_condition_display', item.name)">{{item.name}}</mat-checkbox>
                                 
                                  </div>
                                </div>
                              </div>
                            </div>

                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>Surface Condition Summary</mat-label>
                              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="surface_condition_display"></textarea>
                            </mat-form-field>
                          </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="allExpanded">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Occurrences <span *ngIf="incidentSearchQuery?.occurrences?.length > 0" matBadge="{{incidentSearchQuery?.occurrences?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>

                          <div class="form-row row">

                            <div appearance="fill" class="col-md-6">

                              <label class="checkbox-radio-group-label">Accident or Incident</label>
                              <mat-radio-group aria-labelledby="checkbox-radio-group-label"
                                               class="checkbox-radio-group"
                                               formControlName="accident_or_incident">
                                <mat-radio-button class="checkbox-radio-button" color="primary" *ngFor="let item of accidentOrIncident" [value]="item.code">
                                  {{item.name}}
                                </mat-radio-button>
                              </mat-radio-group>

                              <span class="badge bg-danger" style="min-width:100px;" *ngIf="has_new_accident_or_incident_from_rws_fk">* Auto set Incident based on RWS reference </span>

                            </div>

                            <div appearance="fill" class="col-md-6">

                              <label class="checkbox-radio-group-label">Hot Spot</label>
                              <mat-radio-group aria-labelledby="checkbox-radio-group-label"
                                               class="checkbox-radio-group"
                                               formControlName="hot_spot">
                                <mat-radio-button class="checkbox-radio-button" color="primary" *ngFor="let item of hotSpots" [value]="item.name">
                                  {{item.name}}
                                </mat-radio-button>
                              </mat-radio-group>

                            </div>

                            <div appearance="fill" class="col-md-12">
                              <label class="checkbox-radio-group-label">Occurrence Items</label>
                              <div class="form-row row">

                                <div *ngFor="let item of occurrenceItems" class="col-md-3">
                                  <div class="form-check-faa">
                                    <mat-checkbox name="item.id" value="item.name" [value]="item.name" class="mat-checkbox-inline occurrence"
                                                [checked]="isChecked('occurrences', '_occurrence_display', item.name)"
                                                [inputId]="item.id <= 8 ? 'disabled-occurance' : 'occurance'"
                                                [disabled]="item.id <= 8 ? isReadOnly : null"
                                                [readonly]="item.id <= 8 ? isReadOnly : null"
                                                (change)="onSelectChangeEvent($event, 'occurrences', '_occurrence_display', item.name)"></mat-checkbox>
                                    <label for="item.id <= 8 ? 'disabled-occurance' : 'occurance'" class="check-box-label-faa"> {{item.name}}</label>
                                  </div>
                                </div>
                              </div>

                            </div>

                            <mat-form-field appearance="fill" class="col-md-4">
                              <mat-label>Runway Excursions - Veer Off Distance(ft)</mat-label>
                              <input matInput formControlName="runway_excursions_veeroff_distance" (focusout)="onFocusOutEvent($event, 'occurrences', 'runway_excursions_veeroff_distance')">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-4">
                              <mat-label>Runway Excursions - Overrun Distance(ft)</mat-label>
                              <input matInput formControlName="runway_excursions_overrun_distance" (focusout)="onFocusOutEvent($event, 'occurrences', 'runway_excursions_overrun_distance')">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-md-4">
                              <mat-label>Taxiway Excursions - Veer Off Distance(ft)</mat-label>
                              <input matInput formControlName="taxiway_excursions_veeroff_distance" (focusout)="onFocusOutEvent($event, 'occurrences', 'taxiway_excursions_veeroff_distance')">
                            </mat-form-field>


                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>Occurrence Summary</mat-label>
                              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="occurrence_display"></textarea>
                            </mat-form-field>
                          </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="allExpanded">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Ops & Flight Phase <span *ngIf="incidentSearchQuery?.opsFlightPhase?.length > 0" matBadge="{{incidentSearchQuery?.opsFlightPhase?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>


                          <div class="form-row row">

                            <div appearance="fill" class="col-md-12">
                              <label class="checkbox-radio-group-label">Type Of Operations</label>
                              <div class="form-row row">
                                <div *ngFor="let item of operationTypes" class="col-md-4">
                                  <div class="form-check-faa">
                                    <mat-checkbox name="operationTypes" value="item.name" [value]="item.name" class="mat-checkbox-inline"
                                                  id="item.name" [checked]="isChecked('opsFlightPhase', '_operation_type_display', item.name)"
                                                  (change)="onSelectChangeEvent($event, 'opsFlightPhase', '_operation_type_display', item.name)">{{item.name}}</mat-checkbox>
                                 
                                  </div>
                                </div>
                              </div>

                            </div>


                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>Type of Operation Summary</mat-label>
                              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="operation_type_display"></textarea>
                            </mat-form-field>


                            <div appearance="fill" class="col-md-12">
                              <label class="checkbox-radio-group-label">Flight Phase</label>
                              <div class="form-row row">
                                <div *ngFor="let item of flightPhases" class="col-md-3">
                                  <div class="form-check-faa">
                                    <mat-checkbox name="flightPhases" value="item.name" [value]="item.name" class="mat-checkbox-inline"
                                                  id="item.name" [checked]="isChecked('opsFlightPhase', '_flight_phase_display', item.name)"
                                                  (change)="onSelectChangeEvent($event, 'opsFlightPhase', '_flight_phase_display', item.name)">{{item.name}}</mat-checkbox>
                                 
                                  </div>
                                </div>
                              </div>

                            </div>


                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>Flight Phase Summary</mat-label>
                              <input matInput formControlName="flight_phase_display">
                            </mat-form-field>
                          </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="allExpanded">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Outcome<span *ngIf="incidentSearchQuery?.outcome?.length > 0" matBadge="{{incidentSearchQuery?.outcome?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>

                          <div class="form-row row">

                            <mat-form-field appearance="fill" class="col-md-2">
                              <mat-label>Fatal Injuries</mat-label>
                              <input matInput formControlName="inj_fatal_qty">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-2">
                              <mat-label>Minor Injuries</mat-label>
                              <input matInput formControlName="inj_minor_qty">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-2">
                              <mat-label>Injuries (AIDS only)</mat-label>
                              <input matInput formControlName="inj_aids_qty">
                            </mat-form-field>


                            <mat-form-field appearance="fill" class="col-md-2">
                              <mat-label>Serious Injuries</mat-label>
                              <input matInput formControlName="inj_serious_qty">
                            </mat-form-field>


                            <mat-form-field appearance="fill" class="col-md-2">
                              <mat-label>No Injuries</mat-label>
                              <input matInput formControlName="inj_none_qty">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Damage To Aircraft 1</mat-label>
                              <input matInput formControlName="aircraft_1_damage">
                            </mat-form-field>


                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Damage To Aircraft 2</mat-label>
                              <input matInput formControlName="aircraft_2_damage">
                            </mat-form-field>


                            <mat-form-field appearance="fill" class="col-md-6">
                              <mat-label>Unspecified Type(s) of Injuries (Non-NTSB/AIDS Data Sources)</mat-label>
                              <input matInput formControlName="inj_non_ntsb_qty" matTooltip="Enter -1 for non-NTSB/AIDS injuries where the quantity is not known, otherwise enter quantity">
                            </mat-form-field>

                            <div appearance="fill" class="col-md-4">
                              <label class="checkbox-radio-group-label"></label>
                              <div class="form-row row">
                                <div *ngFor="let item of yesOptionOnly">
                                  <div class="form-check-faa" *ngIf="item.id > 0">
                                    <mat-checkbox name="property_damage" value="item.code" [value]="item.code" [binary]="true" class="mat-checkbox-inline"
                                                formControlName="property_damage"
                                                (change)="onSelectChangeEvent($event, 'outcome', 'property_damage', item.name)"></mat-checkbox>
                                    <label class="check-box-label-faa"> Damage To Property</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div appearance="fill" class="col-md-8">
                              <label class="checkbox-radio-group-label"></label>
                              <div class="form-row row">
                                <div *ngFor="let item of yesOptionOnly">
                                  <div class="form-check-faa" *ngIf="item.id > 0">
                                    <mat-checkbox name="aircraft_dam_non_ntsb" value="item.code" [value]="item.code" [binary]="true" class="mat-checkbox-inline"
                                                formControlName="aircraft_dam_non_ntsb"
                                                (change)="onSelectChangeEvent($event, 'outcome', 'aircraft_dam_non_ntsb', item.name)"></mat-checkbox>
                                    <label class="check-box-label-faa"> Unspecified Type(s) of Aircraft Damage (Non-NTSB/AIDS Data Sources)</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </mat-expansion-panel>


                        <mat-expansion-panel [expanded]="allExpanded">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Misc. Information<span *ngIf="incidentSearchQuery?.miscInfomration?.length > 0" matBadge="{{incidentSearchQuery?.miscInfomration?.length}}" matTooltip="Filter count of current block/section">&nbsp;&nbsp;</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>

                          <div class="form-row row">

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Aircraft Make 1</mat-label>
                              <input matInput formControlName="aircraft_1_make">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Aircraft Make 2</mat-label>
                              <input matInput formControlName="aircraft_2_make">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Aircraft Model 1</mat-label>
                              <input matInput formControlName="aircraft_1_model">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Aircraft Model 2</mat-label>
                              <input matInput formControlName="aircraft_2_model">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Aircraft Series 1</mat-label>
                              <input matInput formControlName="aircraft_1_series">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Aircraft Series 2</mat-label>
                              <input matInput formControlName="aircraft_2_series">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Aircraft Registration No 1</mat-label>
                              <input matInput formControlName="aircraft_1_registration_no">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Aircraft Registration No 2</mat-label>
                              <input matInput formControlName="aircraft_2_registration_no">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Latitude</mat-label>
                              <input matInput formControlName="latitude_incident" type="number" matTooltip="Accepts number only">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>Longitude</mat-label>
                              <input matInput formControlName="longitude_incident" type="number" matTooltip="Accepts number only">
                            </mat-form-field>


                            <mat-form-field appearance="fill" class="col-md-6">
                              <mat-label>Filter Category Of Runway Incursion</mat-label>
                              <input matInput formControlName="rws_filter">
                            </mat-form-field>

                          </div>

                          <hr />
                          <p><b>Contributing Factors</b></p>
                          <mat-divider></mat-divider>
                          <div class="form-row row">

                            <div appearance="fill" class="col-md-12">

                              <label class="checkbox-radio-group-label"> Non-Standard Geometry</label>
                              <div class="form-row row">
                                <div *ngFor="let item of contributingFactors" class="col-md-4">
                                  <div class="form-check-faa">
                                    <mat-checkbox name="contributingFactors" value="item.name" [value]="item.name" class="mat-checkbox-inline"
                                                  id="item.name" [checked]="isChecked('miscInfomration', '_cf_display', item.name)"
                                                  (change)="onSelectChangeEvent($event, 'miscInfomration', '_cf_display', item.name)">{{item.name}}</mat-checkbox>
                                  
                                  </div>
                                </div>
                              </div>

                            </div>


                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>
                                Contributing Factors Summary
                              </mat-label>
                              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="cf_display" rows="3"></textarea>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>
                                Notes
                              </mat-label>
                              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="notes" rows="7"></textarea>
                            </mat-form-field>


                            <mat-form-field appearance="fill" class="col-md-12">
                              <mat-label>
                                Comments
                              </mat-label>
                              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="comments" rows="7"></textarea>
                            </mat-form-field>

                            <div appearance="fill" class="col-md-12">

                              <label class="checkbox-radio-group-label">Incident Edit Stage</label>
                              <mat-radio-group aria-labelledby="checkbox-radio-group-label"
                                               class="checkbox-radio-group"
                                               formControlName="analyst_editing_stage">
                                <mat-radio-button class="checkbox-radio-button" color="primary" *ngFor="let item of incidentEditStage" [value]="item.code">
                                  {{item.name}}
                                </mat-radio-button>
                              </mat-radio-group>

                            </div>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>
                                Create Date
                              </mat-label>

                              <input matInput formControlName="create_date" (focusout)="onFocusOutEvent($event, 'miscInfomration', 'create_date')">

                            </mat-form-field>

                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>
                                Modify Date
                              </mat-label>

                              <input matInput formControlName="modify_date" (focusout)="onFocusOutEvent($event, 'miscInfomration', 'modify_date')">

                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-md-3">
                              <mat-label>
                                Modify User
                              </mat-label>

                              <input matInput value="" formControlName="modify_user" (focusout)="onFocusOutEvent($event, 'miscInfomration', 'modify_user')">

                            </mat-form-field>

                          </div>

                        </mat-expansion-panel>

                      </mat-accordion>

                      <span [hidden]="expanded">Loading....</span>

                    </div>


                  </div>

                </div>

                <div class="" [hidden]="hasQueryGridData">
                  No data found.
                </div>
              </div>

              <div class="col-md-12" *ngIf="message">
                <div class="alert alert-danger" role="alert" *ngIf="message">
                  {{ message }}
                </div>
              </div>
              <div class="card-footer" *ngIf="!showSideBarDetailView" [hidden]="!expanded">
                <div class="row">
                  <div class="col-md-6">
                    <button mat-raised-button color="primary" [disabled]="(totalRecords - 1) == first" (click)="onSaveNext()">Save & Next</button>&nbsp;

                  </div>
                  <div class="col-md-6 text-end">
                    <button mat-raised-button (click)="onReset()">Reset</button>&nbsp;
                    <button mat-raised-button color="primary" (click)="onSubmit()">Save</button>&nbsp;
                  </div>

                </div>
              </div>
            </div>

          </div>
          <app-sidebar-sub-report-view [showSideBarSubReportView]="showSideBarSubReportView"
                                       [type]="subReportType"
                                       [selectedRowIndex]="selectedRowIndex"
                                       [selectedRow]="selectedRow"
                                       [sqlQuery]="sqlQuery"
                                       [baseZIndex]="20000"
                                       (onCloseSideBarSubReportView)="onCloseSideBarSubReportView($event)">

          </app-sidebar-sub-report-view>
        </div>
        </mat-sidenav-content>

      </mat-sidenav-container>
      </div>
</ng-template>
